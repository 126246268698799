import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(relativeTime)
dayjs.extend(calendar)

export const formatRelativeDate = (date: string): string => {
  const now = dayjs()
  const targetDate = dayjs(date)
  const diffHours = now.diff(targetDate, 'hour')
  const diffDays = now.diff(targetDate, 'day')
  const diffMonths = now.diff(targetDate, 'month')
  const diffYears = now.diff(targetDate, 'year')

  if (diffHours < 1) {
    return 'now'
  }

  if (diffHours < 24 && now.isSame(targetDate, 'day')) {
    return 'today'
  }

  if (diffDays === 1 || (diffDays < 2 && !now.isSame(targetDate, 'day'))) {
    return 'yesterday'
  }

  if (diffDays < 7) {
    return 'last week'
  }

  if (diffMonths < 1) {
    return 'this month'
  }

  if (diffMonths === 1) {
    return 'last month'
  }

  if (diffYears < 1) {
    return `${diffMonths} months ago`
  }

  if (diffYears === 1) {
    return 'last year'
  }

  return `${diffYears} years ago`
}
