import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import Button from "../Button/Button";
import IconComponent, { IIconNames } from "../IconComponent/IconComponent";

export interface IProfilePicProps {
  type?: "default" | "photo";
  size?: "xs" | "s" | "m";
  src?: string;
  showButton?: boolean;
  showDefaultIcon?: boolean;
  disabled?: boolean;
  buttonAction?: () => void;
  icon?: IIconNames;
  customIcon?: ReactNode;
}

const ProfilePicIconSizeMap = {
  xs: "s",
  s: "m",
  m: "l",
} as const;

const ProfilePicButtonSizeMap = {
  xs: "xs",
  s: "xs",
  m: "s",
} as const;

const sizeMap = {
  xs: 40,
  s: 56,
  m: 96,
};

const StyledProfilePicContainer = styled.div<
  Pick<IProfilePicProps, "size" | "type" | "src" | "disabled">
>`
  display: flex;
  position: relative;
  padding: 8px;
  border-radius: 80px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${({ size }) => `
        width: ${sizeMap[size as keyof typeof sizeMap]}px;
        height: ${sizeMap[size as keyof typeof sizeMap]}px;
    `}

  ${({ type, src, theme, disabled }) =>
    type === "photo"
      ? disabled
        ? css`
            background:
              linear-gradient(
                0deg,
                rgba(245, 227, 255, 0.7),
                rgba(245, 227, 255, 0.7)
              ),
              url(${src}) lightgray 50% / cover no-repeat;
            background-color: ${theme.colors.actionPrimaryLight};
            filter: grayscale(40%);
          `
        : css`
            background: url(${src}) lightgray 50% / cover no-repeat;
          `
      : type === "default"
        ? disabled
          ? css`
              background: ${theme.colors.actionDisabled};
            `
          : css`
              background: ${theme.colors.actionPrimaryLight};
            `
        : css``}

  ${({ disabled }) =>
    !disabled &&
    css`
      &:active {
        outline: 3px solid ${({ theme }) => theme.colors.actionPrimaryActive};
        outline-offset: -3px;
      }
      &:hover {
        outline: 3px solid ${({ theme }) => theme.colors.actionPrimaryHover};
        outline-offset: -3px;
      }
    `}
`;

const StyledButtonContainer = styled.div<Pick<IProfilePicProps, "size">>`
  position: absolute;
  top: -8px;
  display: flex;
  border-radius: 32px;
  background: ${({ theme }) => theme.colors.actionPrimary};
  justify-content: center;
  align-items: center;
  ${({ size }) => `
        right: ${size === "xs" ? "-16px" : "-8px"};
    `}
`;

const ProfilePic = ({
  type = "default",
  size = "m",
  src = "",
  showButton = true,
  showDefaultIcon = true,
  disabled = false,
  buttonAction = () => {},
  icon = "users",
  customIcon = null,
}: IProfilePicProps) => {
  return (
    <StyledProfilePicContainer
      size={size || "m"}
      type={type}
      src={src}
      disabled={disabled}
      onClick={buttonAction}
      role="button"
      tabIndex={0}
    >
      {type === "default" &&
        showDefaultIcon &&
        (customIcon ? (
          customIcon
        ) : (
          <IconComponent
            icon={icon}
            iconSize={ProfilePicIconSizeMap[size]}
            color={theme.colors.actionPrimary}
          />
        ))}
      {showButton && (
        <StyledButtonContainer size={size}>
          <Button
            iconOnly
            iconColor="white"
            icon="edit-2"
            variant="primary"
            size={ProfilePicButtonSizeMap[size]}
            disabled={disabled}
          />
        </StyledButtonContainer>
      )}
    </StyledProfilePicContainer>
  );
};

export default ProfilePic;