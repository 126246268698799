import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import { IDropdownItem } from '../DropDown/DropDown'
import DropdownMenu from '../DropDown/DropDown'
import RowItem from '../RowItem/RowItem'
import { createPortal } from 'react-dom'
import { Container } from '../DropDown/DropdownMenu'

interface IFilterLabelItem extends IDropdownItem {
  selected: boolean
}

interface IFilterLabelProps {
  type?: 'default'
  label: string
  placeholder: string
  isLoading?: boolean
  active?: boolean
  disabled?: boolean
  selectedItems?: IDropdownItem[]
  onClickPlus?: () => void
  onClickX?: () => void
  isDropdownOpen?: boolean
  items?: IFilterLabelItem[]
  onChange?: (values: IDropdownItem[]) => void
}
const StyledFilterLabel = styled.div<{ $disabled?: boolean }>`
  width: fit-content;
  display: flex;
  padding: 4px 8px 4px 16px;
  border-radius: 28px;
  border: 1px solid ${theme.colors.actionPrimaryLightActive};
  align-items: center;
  gap: 4px;
  background: transparent;

  ${(props) =>
    !props.$disabled &&
    `
        cursor: not-allowed;
        border: 1px solid ${theme.colors.copySubtle};
        background: transparent;

        & > p{
            color: ${theme.colors.copySubtle};
        }
    `}
  ${(props) =>
    !props.$disabled &&
    `
        cursor: pointer;
        &:hover {
            border: 1px solid ${theme.colors.actionPrimaryLightActive};
            background: ${theme.colors.actionPrimaryLightHover};
            }
            
        &:hover > button > div > svg {
            : ${theme.colors.actionPrimaryHover}!important;
            }

            &:active > button > div > svg {
                color: ${theme.colors.actionPrimary}!important;
        }
            
            &:active {
                border: 1px solid ${theme.colors.actionPrimaryLightActive};
                background: ${theme.colors.actionPrimaryLightActive};
        }
    `}
`

const StyledFilterLabelTitle = styled.p`
  display: -webkit-box;
  max-width: 160px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;
`

const StyledSelectedFiltersPill = styled.span`
  /* display: flex; */
  height: 30px;
  max-width: 132px;
  padding: 4px 16px;
  border-radius: 32px;
  background: ${theme.colors.actionPrimaryLightHover};
  align-items: flex-start;
  gap: 8px;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  color: ${theme.colors.actionPrimary};
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;
`

const generateSelectedFilterLabel = (selectedItems: IDropdownItem[] | []) => {
  if (selectedItems.length === 0) return null
  if (selectedItems.length < 3)
    return selectedItems.map((item) => item.name).join(', ')
  return `${selectedItems.length} selected`
}

const FilterLabel = (props: IFilterLabelProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)
  const { label, onClickPlus, onClickX, disabled, items = [] } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)

  const [filteredItems, setFilteredItems] = useState<IFilterLabelItem[]>(() =>
    items.map((item) => ({
      ...item,
      selected:
        props?.selectedItems?.some((selected) => selected.id === item.id) ??
        false
    }))
  )

  // Update filtered items when selectedItems prop changes
  useEffect(() => {
    setFilteredItems(
      items.map((item) => ({
        ...item,
        selected: props?.selectedItems?.some(
          (selected) => selected.id === item.id
        )
      })) as IFilterLabelItem[]
    )
  }, [items, props.selectedItems])

  // console.log({ selectedItems }, 'selectedItems')
  const { onChange } = props
  const handleItemClick = useCallback(
    (item: IFilterLabelItem, index: number) => {
      console.log({ item, index })
      setFilteredItems((prev) => {
        const newItems = prev.map((prevItem) => ({
          ...prevItem,
          selected: prevItem.id === item.id
        }))
        return newItems
      })
      onChange &&
        onChange(
          filteredItems.filter((item) => item.selected).map((item) => item)
        )
    },
    [onChange, filteredItems]
  )

  const selectedFilterLabel = useMemo(() => {
    return generateSelectedFilterLabel(props.selectedItems ?? [])
  }, [props.selectedItems])

  const handleSearch = useCallback(
    debounce((term: string) => {
      setFilteredItems((prev) => {
        if (!term) return items.map((item) => ({ ...item, selected: false }))
        return prev.filter((item) => item?.name?.toLowerCase()?.includes(term))
      })
    }, 300),
    []
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value)
    },
    []
  )

  const handleClearFilter = useCallback(() => {
    setFilteredItems((prev) =>
      prev.map((item) => ({ ...item, selected: false }))
    )
  }, [])

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       dropdownRef.current &&
  //       !dropdownRef.current.contains(event.target as Node) &&
  //       portalRef.current &&
  //       !portalRef.current.contains(event.target as Node)
  //     ) {
  //       setIsDropdownOpen(false)
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside)
  //   window.addEventListener('resize', updatePortalPosition)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //     window.removeEventListener('resize', updatePortalPosition)
  //   }
  // }, [])

  const DropdownPortal = ({ children }: { children: React.ReactNode }) => {
    return createPortal(
      <div
        ref={portalRef}
        style={{
          pointerEvents: 'auto',
          position: 'absolute',
          top: (dropdownRef.current?.getBoundingClientRect().bottom ?? 0) + 8,
          left: dropdownRef.current?.getBoundingClientRect().left
        }}
      >
        {children}
      </div>,
      document.body
    )
  }

  const updatePortalPosition = () => {
    if (portalRef.current && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect()
      portalRef.current.style.top = `${rect.bottom + 8}px`
      portalRef.current.style.left = `${rect.left}px`
    }
  }

  useEffect(() => {
    if (isDropdownOpen) {
      updatePortalPosition()
    }
  }, [isDropdownOpen])

  return (
    <Container ref={dropdownRef}>
      <StyledFilterLabel $disabled={disabled}>
        <StyledFilterLabelTitle>{label}</StyledFilterLabelTitle>
        {Number(props?.selectedItems?.length) > 0 && (
          <StyledSelectedFiltersPill onClick={() => setIsDropdownOpen(true)}>
            {selectedFilterLabel}
          </StyledSelectedFiltersPill>
        )}
        {props.selectedItems && props.selectedItems.length ? (
          <Button
            iconOnly
            icon="x"
            variant="tertiary"
            size="xs"
            onClick={() => {
              handleClearFilter()
              onClickX && onClickX()
            }}
            iconColor={theme.colors.actionPrimary}
            disabled={disabled}
          />
        ) : (
          <Button
            iconOnly
            icon="plus"
            variant="tertiary"
            size="xs"
            iconColor={theme.colors.actionPrimary}
            onClick={() => {
              setIsDropdownOpen(true)
              onClickPlus && onClickPlus()
            }}
          />
        )}
      </StyledFilterLabel>
      {isDropdownOpen && (
        <DropdownPortal>
          <DropdownMenu
            items={filteredItems}
            showBorderRadius={false}
            onSelectedItemChange={(item) => {
              onChange && onChange([...(props.selectedItems ?? []), item])
            }}
            width={''}
            type={'search'}
            size={'s'}
            menuPosition={'bottom'}
            isOpen={isDropdownOpen}
            placeholder={props.placeholder}
            filteredItems={filteredItems}
            highlightedIndex={highlightedIndex}
            loading={false}
            handleScroll={() => {}}
            handleInputChange={handleInputChange}
            handleItemClick={() => {
              console.log('handleItemClick')
            }}
            setHighlightedIndex={setHighlightedIndex}
            showAction={true}
            actionLabel={'Clear filters'}
            handleAction={handleClearFilter}
            filterListContent={filteredItems.map((item, index) => (
              <RowItem
                showBorder
                key={item.id}
                id={`dropdown-item-${item.id}`}
                title={item.name ?? ''}
                aria-selected={highlightedIndex === index}
                size="s"
                {...(item?.description
                  ? {
                      showDescription: true,
                      description: item.description
                    }
                  : { showDescription: false })}
                showCheckBox={true}
                checked={item.selected}
                onCheckedChange={() => {
                  handleItemClick(item, index)
                }}
                onClick={() => handleItemClick(item, index)}
                onMouseEnter={() => setHighlightedIndex(index)}
              />
            ))}
          />
        </DropdownPortal>
      )}
    </Container>
  )
}

export default FilterLabel
