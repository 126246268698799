import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import InputField from '../InputField/InputField'
import SearchResults, { Container } from './SearchResults'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { createPortal } from 'react-dom'
import { useSearch } from '../kanban/hooks/useSearch'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface ISearchProps {
  width?: string
  loading?: boolean
  error?: boolean | string
  query?: string
  placeholder?: string
  clearable?: boolean
  results?: unknown[]
  noResultsMessage?: string
  onSearchChange?: (value: string) => void
  onSelectResult?: (value: unknown) => void
  onClear?: () => void
  onFocus?: () => void
  onBlur?: () => void
  renderResultItem?: (item: unknown) => JSX.Element
}

const Search = (props: ISearchProps) => {
  const {
    placeholder = 'Search',
    // onSearchChange = () => {},
    onClear = () => {},
    onFocus = () => {},
    onBlur = () => {},
    width = '464px'
  } = props
  const { currentTeam } = useSelector((state: RootState) => state.team)
  const [query, setQuery] = useState('')
  const { results, fullDataset, searchTerm, handleSearch } = useSearch(
    currentTeam?.id ?? '',
    query
  )

  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)

  const isMobile = useIsMobileDevice()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        portalRef.current &&
        !portalRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('resize', updatePortalPosition)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('resize', updatePortalPosition)
    }
  }, [])

  const updatePortalPosition = () => {
    if (portalRef.current && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      portalRef.current.style.top = `${rect.bottom + 8}px`
      portalRef.current.style.left = `${rect.left}px`
    }
  }

  useEffect(() => {
    if (isOpen) {
      updatePortalPosition()
    }
  }, [isOpen])

  const resultsSuggestions = useMemo(() => {
    // if search term is empty, return full dataset
    if (!searchTerm) {
      return fullDataset
    }
    return results
  }, [results, fullDataset, searchTerm])

  const SearchResultsPortal = ({ children }: { children: React.ReactNode }) => {
    return createPortal(
      <div
        ref={portalRef}
        style={{
          width: containerRef.current?.getBoundingClientRect().width,
          zIndex: 9999,
          position: 'absolute',
          top: (containerRef.current?.getBoundingClientRect().bottom ?? 0) + 8,
          left: containerRef.current?.getBoundingClientRect().left
        }}
      >
        {children}
      </div>,
      document.body
    )
  }

  return (
    <Container ref={containerRef} $width={width}>
      <InputField
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          // onSearchChange(e.target.value)
          setQuery(e.target.value)
          handleSearch(e.target.value)
        }}
        onFocus={() => {
          setIsOpen(true)
          onFocus && onFocus()
        }}
        onBlur={() => {
          setIsOpen(false)
          onBlur && onBlur()
        }}
        label={placeholder}
        type="search"
        onClear={onClear}
        fullWidth
      />
      {isOpen && (
        <SearchResultsPortal>
          <SearchResults
            size={isMobile ? 's' : 'm'}
            menuPosition="bottom"
            isOpen={isOpen}
            highlightedIndex={null}
            selectedItem={{
              id: '1',
              name: 'test',
              description: 'test'
            }}
            loading={false}
            handleScroll={() => {}}
            handleItemClick={() => {}}
            setHighlightedIndex={() => {}}
            handleInputChange={() => {}}
            resultsSuggestions={resultsSuggestions as any}
            resultsRecentSearch={[]}
          />
        </SearchResultsPortal>
      )}
    </Container>
  )
}

export default Search
