import styled from 'styled-components'
import { theme } from '../../../styles'
import { buttonVariantColors } from '../../../styles/theme'
import Layout from '../../Layout/Layout'
import Button from '../../ui/Button/Button'
import InputField from '../../ui/InputField/InputField'
import Label from '../../ui/Label/Label'
import TopNavItem from '../../ui/TopNavItem/TopNavItem'
import TeamsAndUserBodyContainer from './TeamsAndUsersBodyContainer'

export const StyledTeamsAndUsersMainContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  max-height: 100%;
  @media screen and (max-width: 834px) {
    // min-height: 100%;
    flex-grow: 1;
  }
`

export const StyledTitle = styled.h1`
  color: ${theme.colors.copy};
  font-family: 'rooney';
  font-size: 48px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%;

  @media screen and (min-width: 430px) {
    font-size: 64px;
  }
`

export const StyledTeamsAndUsersOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

export const StyledTeamsAndUsersOptionTitle = styled.p`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: 26px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
`

export const StyledOptionInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`

export const StyledInputLabel = styled.label`
  color: ${theme.colors.copy};

  font-family: 'rooney-sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

export const StyledInputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`

export const StyledUserItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const StyledUserItem = styled.div<{ lastItem?: boolean }>`
  display: flex;
  padding: 12px 10px;
  align-items: center;
  gap: 12px;
  width: 100%;
  cursor: pointer;
  position: relative;
  // border-radius: 4px;

  border-bottom: ${({ lastItem }) =>
    lastItem ? 'none' : `1px solid ${theme.colors.borderDefault}`};

  &:hover > * {
    z-index: 1;
  }

  &:hover::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 88%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    pointer-events: none;
    background-color: ${theme.colors.actionPrimaryLightHover};
    z-index:1;
  }

  // &:hover > div:nth-child(1) {
  //   outline: 3px solid ${theme.colors.copy};
  // }

  &:hover > button:nth-last-child(1) > svg {
    stroke: ${buttonVariantColors.btnActionHover};
  }
`

export const StyledUserEmail = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;

  @media screen and (min-width: 768px) {
    flex: 1 0 0;
  }
`

const TeamsAndUsers = () => {
  return (
    <Layout>
      <StyledTeamsAndUsersMainContainer>
        <TeamsAndUserBodyContainer>
          <div>
            <StyledTitle>Teams & Users</StyledTitle>
          </div>
          <StyledTeamsAndUsersOptionsContainer>
            <StyledTeamsAndUsersOptionTitle>
              Team
            </StyledTeamsAndUsersOptionTitle>
            <StyledOptionInputsContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel>Team Name</StyledInputLabel>

                <InputField label="Team name" fullWidth type="email" />
              </StyledInputLabelContainer>
              <Button label="Update" disabled />
            </StyledOptionInputsContainer>
          </StyledTeamsAndUsersOptionsContainer>
          <StyledTeamsAndUsersOptionsContainer>
            <StyledTeamsAndUsersOptionTitle>
              Users
            </StyledTeamsAndUsersOptionTitle>
            <StyledUserItemsContainer>
              <StyledUserItem>
                <TopNavItem type="user" />
                <StyledUserEmail>john.doe@apple.com</StyledUserEmail>
                <Label text="editor" signal="disabled" />
                <Button iconOnly icon="chevron-right" variant="tertiary" />
              </StyledUserItem>
              <StyledUserItem>
                <TopNavItem type="user" />
                <StyledUserEmail>verylongemailaddres@gmail.com</StyledUserEmail>
                <Label text="editor" signal="disabled" />
                <Button iconOnly icon="chevron-right" variant="tertiary" />
              </StyledUserItem>
              <StyledUserItem lastItem>
                <TopNavItem type="user" />
                <StyledUserEmail>john.doe@apple.com</StyledUserEmail>
                <Label text="editor" signal="disabled" />
                <Button iconOnly icon="chevron-right" variant="tertiary" />
              </StyledUserItem>
            </StyledUserItemsContainer>
            <Button label="Add User" variant="secondary" />
          </StyledTeamsAndUsersOptionsContainer>
        </TeamsAndUserBodyContainer>
      </StyledTeamsAndUsersMainContainer>
    </Layout>
  )
}

export default TeamsAndUsers
