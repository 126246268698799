/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod'
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { IWorkflowActionProps } from '../components/ui/WorkflowAction/WorkflowAction'
import { UserRole } from '../types/user'
import { WorkflowType } from '../pages/TemplateEdit'
import useWorkflowUpdate from '../pages/workflows/hooks/useWorkflowUpdate'

export const WorkActionFlowSchema = z.object({
  jira: z
    .object({
      id: z.string().optional(),
      type: z.enum(['email', 'jira']).optional(),
      when: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      then: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      jiraProject: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      syncWithJira: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      details: z.array(z.string()),
      templateId: z.string(),
      teamId: z.string(),
      recipient: z.array(z.string().email())
    })
    .optional(),
  mail: z
    .object({
      id: z.string().optional(),
      type: z.enum(['email', 'jira']).optional(),
      when: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      then: z.array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      ),
      details: z.array(z.string()),
      templateId: z.string(),
      teamId: z.string(),
      recipient: z.array(z.string().email())
    })
    .optional()
})

export type WorkflowActionType = z.infer<typeof WorkActionFlowSchema>

const WorkflowContext = createContext<
  Omit<IWorkflowActionProps, 'defaultValues'>
>({
  collapse: true,
  device: 'desktop',
  option: 'email',
  isLoading: false,
  onChangeOption: (val: string) => {},
  onClickTrigger: () => {},
  showSignalIcon: false,
  isJiraAuthorized: true,
  onSubmit: async () => {},
  jiraProjectOptions: [],
  showWorkflowButton: false,
  userRole: UserRole.EDITOR,
  collapseStates: {},
  setCollapseStates: () => {},
  workflowTypes: ['jira' as WorkflowType],
  setWorkflowTypes: () => {},
  workflow: {}
})

// eslint-disable-next-line react-refresh/only-export-components
export function useWorkflow() {
  return useContext(WorkflowContext)
}

interface WorkflowActionProviderProps extends IWorkflowActionProps {
  defaultValues: Partial<WorkflowActionType>
  children?: ReactNode
}

const WorkflowActionProvider: FC<WorkflowActionProviderProps> = ({
  defaultValues,
  collapse,
  device,
  option,
  onChangeOption,
  showSignalIcon,
  isJiraAuthorized,
  jiraProjectOptions,
  onSubmit,
  onClickTrigger,
  children,
  isLoading,
  showWorkflowButton,
  userRole,
  details,
  collapseStates,
  setCollapseStates,
  setWorkflowTypes,
  workflowTypes,
  workflow
}) => {
  const mailWorkflowId = defaultValues.mail?.id
  const jiraWorkflowId = defaultValues.jira?.id
  const [workflowId, setWorkflowId] = useState<string | null>(null)

  useEffect(() => {
    if (option === 'jira') {
      setWorkflowId(jiraWorkflowId || null)
    } else {
      setWorkflowId(mailWorkflowId || null)
    }
  }, [jiraWorkflowId, mailWorkflowId, option])

  const method = useForm<Partial<WorkflowActionType>>({
    defaultValues,
    mode: 'onChange',
    values: defaultValues,
    resolver: zodResolver(WorkActionFlowSchema),
    disabled: userRole === UserRole.EDITOR
  })

  const { mutateAsync, isPending } = useWorkflowUpdate()

  const handleSubmit = async () => {
    if (workflowId) {
      const data = method.getValues()
      let props = {}
      if (option === 'email') {
        props = {
          mail: {
            details: data?.mail?.details || [],
            recipient: data?.mail?.recipient || [],
            templateId: data?.mail?.templateId || '',
            teamId: data?.mail?.teamId || '',
            when: data?.mail?.when || [],
            then: data?.mail?.then || []
          },
          teamId: data.mail?.teamId || '',
          workflowId: workflowId
        }
      } else {
        props = {
          jira: {
            details: data?.jira?.details || [],
            recipient: data?.jira?.recipient || [],
            templateId: data?.jira?.templateId || '',
            teamId: data?.jira?.teamId || '',
            jiraProject: data?.jira?.jiraProject || [],
            syncWithJira: data?.jira?.syncWithJira || [],
            when: data?.jira?.when || [],
            then: data?.jira?.then || []
          },
          teamId: data.jira?.teamId || '',
          workflowId: workflowId
        }
      }
      await mutateAsync({
        ...props,
        teamId: data.jira?.teamId || data.mail?.teamId || '',
        workflowId: workflowId
      })
    }
  }

  const checkAvailableForUpdate = useMemo(() => {
    if (!details) return false

    const { workflowExists, availableWorkflows } = details

    if (workflowExists) {
      if (option === 'jira') {
        return availableWorkflows?.hasJira
      }
      if (option === 'email') {
        return availableWorkflows?.hasEmail
      }
    }

    return false
  }, [details, option])

  return (
    <FormProvider {...method}>
      <WorkflowContext.Provider
        value={{
          collapse,
          device,
          option,
          onChangeOption,
          onClickTrigger,
          showSignalIcon,
          isJiraAuthorized,
          onSubmit: checkAvailableForUpdate ? handleSubmit : onSubmit,
          jiraProjectOptions,
          isLoading: isPending || isLoading,
          showWorkflowButton,
          userRole,
          details,
          collapseStates,
          setCollapseStates,
          setWorkflowTypes,
          workflowTypes,
          workflow
        }}
      >
        {children}
      </WorkflowContext.Provider>
    </FormProvider>
  )
}

export default WorkflowActionProvider
