import React, { ReactNode, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Sheet } from 'react-modal-sheet'
import googleIconSvg from '../../assets/images/googleIcon.svg'
import MenuHeader from '../MenuHeader/MenuHeader'
import { AccountMenuItems } from '../MenuItems/MenuItems'
import Button from '../Button/Button'
import Avatar from '../Avatar/Avatar'
import Label from '../Label/Label'
import { StyledMenuItems } from '../MenuItems/MenuItems'
import { StyledRow } from '../../../pages/Team'

interface IBottomSheetProps {
  children?: ReactNode
  open: boolean
  onClose?: () => void
  sheetProps?: Partial<React.ComponentProps<typeof Sheet>>
}

function BottomSheet({
  children,
  open,
  sheetProps,
  onClose = () => {}
}: IBottomSheetProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  // Close the bottom sheet when clicking outside the container
  // useEffect(() => {
  //     function handleClickOutside(event: MouseEvent) {
  //         if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
  //             onClose();
  //         }
  //     }

  //     if (open) {
  //         document.addEventListener('mousedown', handleClickOutside);
  //     } else {
  //         document.removeEventListener('mousedown', handleClickOutside);
  //     }

  //     return () => {
  //         document.removeEventListener('mousedown', handleClickOutside);
  //     };
  // }, [open, onClose]);

  return (
    <Sheet isOpen={open} onClose={onClose} {...sheetProps}>
      <Sheet.Container
        ref={containerRef}
        style={{
          padding: '16px'
        }}
      >
        <Sheet.Content
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}
        >
          {children}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  )
}

interface ITypeBottomSheetProps {
  open: boolean
  handleClose: () => void
}

// function MenuBottomSheet({ open, handleClose }: ITypeBottomSheetProps) {
//     return (
//         <BottomSheet open={open} sheetProps={{ snapPoints: [240] }} onClose={handleClose}>
//             {/* <MenuHeader title="Options" handleClose={handleClose} /> */}
//             <MenuItems showSlot1 showSlot2 type="default" />
//         </BottomSheet>
//     );
// }

const StyledAccountContainer = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const StyledAccountText = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #000;
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
  text-overflow: ellipsis;
`
const StyledAccountHeading = styled.h4`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #000;
  font-family: 'rooney-sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 550;
  line-height: 140%;
  text-overflow: ellipsis;
  text-transform: uppercase;
`
const BottomSheetHeader = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2ece6;
  padding: 0 8px 16px 8px;
`

function AccountBottomSheet({ open, handleClose }: ITypeBottomSheetProps) {
  return (
    <BottomSheet open={open} sheetProps={{ snapPoints: [350] }}>
      <MenuHeader title="Account" handleClose={handleClose} />
      <StyledAccountContainer>
        <Avatar src={googleIconSvg} size={40} />
        <StyledAccountText>user@compfeed.com</StyledAccountText>
        <Label title="admin" signal="disabled" />
      </StyledAccountContainer>
      <AccountMenuItems showSlot1 showSlot2 />
    </BottomSheet>
  )
}

interface ITypeMenuBottomSheetProps {
  menuItems?: ReactNode
  disabled?: boolean
  title?: string
}

function MenuBottomSheet({
  menuItems,
  title,
  disabled = false
}: ITypeMenuBottomSheetProps) {
  const [open, setOpen] = useState(false)

  const menuItemsWithProps = React.Children.map(menuItems, (child) => {
    return React.cloneElement(child as React.ReactElement, {
      closeMenu: () => setOpen(false)
    })
  })
  return (
    <>
      <Button
        iconOnly
        icon="more-horizontal"
        onClick={() => setOpen(!open)}
        variant="tertiary"
        size="small"
        disabled={disabled}
      />
      <BottomSheet
        open={open}
        sheetProps={{ detent: 'content-height' }}
        onClose={() => setOpen(false)}
      >
        <BottomSheetHeader>
          <StyledAccountHeading>{title}</StyledAccountHeading>
          <Button
            icon="x"
            iconOnly
            onClick={() => setOpen(false)}
            variant="tertiary"
            size="small"
          />
        </BottomSheetHeader>
        <StyledMenuItems>{menuItemsWithProps}</StyledMenuItems>
      </BottomSheet>
    </>
  )
}

function StateFullAccountBottomSheet() {
  const [open, setOpen] = useState(true)

  return (
    <>
      <Button onClick={() => setOpen(true)} label="Open menu" />
      <AccountBottomSheet open={open} handleClose={() => setOpen(false)} />
    </>
  )
}

export default BottomSheet

export { MenuBottomSheet, AccountBottomSheet, StateFullAccountBottomSheet }
