import React, { useState } from 'react'
import Modal from '../../components/ui/Modal/Modal'
import { toast } from 'react-toastify'
import { StyledInputLabelContainer } from '../../components/screens/MyAccount/MyAccount'
import InputField from '../../components/ui/InputField/InputField'
import Button from '../../components/ui/Button/Button'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import Label from '../../components/ui/Label/Label'
import { capitalFirstLetter, currentHost, validateEmail } from '../../utils/helpers'
import { User } from '../../store/reducers/authReducerSlice'
import {
  equalTo,
  get,
  limitToFirst,
  orderByChild,
  query,
  ref,
  update
} from 'firebase/database'
import { db } from '../../config/config'
import { useDispatch } from 'react-redux'
import { setCurrentTeam, setTeams } from '../../store/reducers/teamReducerSlice'
import axios from 'axios'
import styled from 'styled-components'
import { StyledRow } from '.'
import {
  StyledMenuItems,
  StyledSlot
} from '../../components/ui/MenuItems/MenuItems'
import { StyledHeaderLabel } from '../../components/ui'
import { theme } from '../../styles'
import MenuItem from '../../components/ui/MenuItem/MenuItem'
import Dropdown from '../../components/ui/DropDown/DropDown'

const InfoLabel = styled.div`
  background: #e9f8ff;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const InfoLabelTitle = styled.div`
  color: #36185f;
  width: 100%;
  text-transform: uppercase;
  font-weight: 420;
  font-size: 16px;
`

interface ModalProps {
  modalOpen?: boolean
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const TeamInvitationModal: React.FC<ModalProps> = ({
  modalOpen = false,
  setModalOpen = () => {}
}) => {
  const dispatch = useDispatch()
  const [sending, setSending] = useState(false)
  const [email, setEmail] = useState('')
  const [userRole, setUserRole] = useState('editor')
  const [roleMenu, setRoleMenu] = useState(false)
  const { user } = useSelector((store: RootState) => store.auth)
  const {
    teams,
    currentTeam,
    isLoading: isLoadingTeams
  } = useSelector((store: RootState) => store.team)

//   const findMember = currentTeam?.members?.find((v) => v?.id === user?.id)
  //   const isAdmin = findMember?.role === 'owner' || findMember?.role === 'admin'

  // close modal
  const closeModal = (forced?: boolean) => {
    if (sending && !forced) return
    setModalOpen(false)
    setEmail('')
    setUserRole('editor')
    setSending(false)
  }

  // check pending invites
  const checkInPending = (): boolean => {
    const avail = Object.values(currentTeam?.members || {})?.find(
      (v) =>
        v?.email?.toLocaleLowerCase()?.trim() ===
        email?.toLocaleLowerCase()?.trim()
    )
    return avail?.pendingInvite ? true : false
  }

  // check if it's own email
  const isOwnEmail = (): boolean => {
    return email?.toLocaleLowerCase()?.trim() === user?.email
  }

  // check if it's already member of the team
  const isAlreadyMember = (): boolean => {
    const avail = Object.values(currentTeam?.members || {})?.find(
      (v) =>
        v?.email?.toLocaleLowerCase()?.trim() ===
        email?.toLocaleLowerCase()?.trim()
    )
    return avail ? true : false
  }

  // send invite
  const sendInvite = async () => {
    if (!email || sending || isLoadingTeams) return
    if (checkInPending() || isOwnEmail() || isAlreadyMember()) return
    setSending(true)

    try {
      const equalToQuery = query(
        ref(db, 'users'),
        orderByChild('email'),
        equalTo(email),
        limitToFirst(1)
      )

      const result = await get(equalToQuery)
      const snapshot: User[] = Object.values(result.val() || [])
      const receiptUser: User = snapshot[0]
      const invitationLink = `${currentHost()}/invitations/${currentTeam?.id}/${receiptUser?.id || 'new'}`
      //   console.log(invitationLink)
      // setSending(false)
      // return

      // Send invitation email to the user
      axios
        .get(
          `${import.meta.env.VITE_APP_SERVER_URL}/email/team-invite-user?email=${email}&from_email=${user?.email}&team_name=${currentTeam?.title || 'Unknown'}&link=${invitationLink}`
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedList = Object.values(currentTeam?.members || [])
            updatedList.push({
              email: receiptUser?.email || email || '',
              id: receiptUser?.id || '',
              role: 'editor',
              pendingInvite: true,
              invitationDate: new Date().toISOString(),
              invitationStatus: 'pending',
              profileImg:
                receiptUser?.profileImg ||
                receiptUser?.integrations?.google?.profileImg ||
                receiptUser?.integrations?.figma?.profileImg ||
                '',
              invitedBy: user?.email || '',
              inviteSentCount: 0
            })
            // update the team members in database
            update(ref(db, `teams/${currentTeam?.id}`), {
              members: updatedList
            })
              .then(() => {
                dispatch(
                  setCurrentTeam({
                    data: {
                      ...currentTeam,
                      members: updatedList
                    }
                  })
                )
                dispatch(
                  setTeams({
                    data: Object.values(teams || [])?.map((team) => {
                      if (team?.id === currentTeam?.id) {
                        return {
                          ...team,
                          members: updatedList
                        }
                      }
                      return team
                    }),
                    isLoading: false
                  })
                )
              })
              .catch((e) => {
                console.log('error in saving user in team', e)
              })
              .finally(() => {
                toast.success('Your invitation has been sent. Crushing it!')
                setModalOpen(false)
                setSending(false)
                setEmail('')
              })
          } else {
            setSending(false)
            console.log('Invitation sent failed...', res.data)
            toast.error("Something went wrong – let's give it another try.")
          }
        })
        .catch((err) => {
          setSending(false)
          console.log('Invitation sent failed...', err)
          toast.error("Something went wrong – let's give it another try.")
        })
    } catch (err) {
      console.log('Error fetching user for invitation:', err)
      setSending(false)
      toast.error("Something went wrong – let's give it another try.")
    }
  }

  // JSX
  return (
    <Modal
      showModal={modalOpen}
      setShowModal={setModalOpen}
      title="Invite Member"
      signal="send"
      buttonVariant1="primary"
      buttonLabel1={sending ? 'SENDING' : 'SEND INVITATION'}
      buttonVariant2="tertiary"
      buttonLabel2="CANCEL"
      buttonOnClick1={sendInvite}
      buttonOnClick2={closeModal}
      buttonDisabled1={
        sending ||
        !email?.trim() ||
        checkInPending() ||
        isOwnEmail() ||
        isAlreadyMember() ||
        validateEmail(email) === false
      }
      buttonDisabled2={sending}
      onclose={closeModal}
      isLoading={sending}
      overflow="visible"
      slotContent={
        <StyledInputLabelContainer style={{ gap: '16px' }}>
          <InfoLabel>
            <InfoLabelTitle>INVITE MEMBERS VIA EMAIL</InfoLabelTitle>
            You're about to invite a new member to join the system. Once
            invited, they can simply signup to access system.
          </InfoLabel>
          <StyledRow style={{ flexWrap: 'nowrap', gap: '5px' }}>
            <InputField
              disabled={sending}
              required
              fullWidth
              label="Email address"
              placeholder="Email address"
              name="email"
              type="email"
              // icon="icon"
              value={email?.toLocaleLowerCase()}
              readOnly={isLoadingTeams}
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                isLoadingTeams
                  ? null
                  : setEmail(
                      (e.target?.value?.length < 120
                        ? e.target?.value
                        : email
                      )?.trim()
                    )
              }
            />
            <Dropdown
              isOpen={roleMenu}
              onClose={() => setRoleMenu(false)}
              isOverlay
              actionTrigger={
                <StyledSlot
                  style={{
                    padding: '8px 8px 8px 14px',
                    flexDirection: 'row',
                    gap: '5px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    userSelect: 'none'
                  }}
                  onClick={() => setRoleMenu((prev) => !prev)}
                >
                  <StyledHeaderLabel style={{ fontSize: '18px' }}>
                    {capitalFirstLetter(userRole)}
                  </StyledHeaderLabel>
                  <Button
                    iconOnly
                    color="primary"
                    icon="chevron-down"
                    variant="tertiary"
                    size="xs"
                  />
                </StyledSlot>
              }
              children={
                <StyledMenuItems
                  style={{
                    backgroundColor: theme?.colors?.white,
                    boxShadow: '0 4px 32px #00000029',
                    width: '176px',
                    gap: '0',
                    overflow: 'hidden'
                  }}
                >
                  <StyledSlot
                    $hasBorderBottom
                    style={{
                      paddingBottom: '0'
                    }}
                    onClick={() => {
                      setRoleMenu(false)
                      setUserRole('admin')
                    }}
                  >
                    <MenuItem
                      title="Admin"
                      type="primary"
                      customStyles={{
                        borderRadius: '0',
                        padding: '16px'
                      }}
                      iconPosition="right"
                      icon={userRole === 'admin' ? 'check' : ''}
                    />
                  </StyledSlot>
                  <StyledSlot
                    $hasBorderBottom={false}
                    style={{
                      paddingBottom: '0'
                    }}
                    onClick={() => {
                      setRoleMenu(false)
                      setUserRole('editor')
                    }}
                  >
                    <MenuItem
                      title="Editor"
                      type="primary"
                      customStyles={{
                        borderRadius: '0',
                        padding: '16px'
                      }}
                      icon={userRole === 'editor' ? 'check' : ''}
                      iconPosition="right"
                    />
                  </StyledSlot>
                </StyledMenuItems>
              }
            />
          </StyledRow>
          {checkInPending() ? (
            <Label
              signal="warning"
              text="This user is already invited – someone's so popular"
            />
          ) : null}
          {isOwnEmail() ? (
            <Label signal="information" text="You can't invite yourself" />
          ) : null}
          {isAlreadyMember() && !checkInPending() && !isOwnEmail() ? (
            <Label
              signal="warning"
              text="Yep, that user is already part of the team"
            />
          ) : null}
        </StyledInputLabelContainer>
      }
    />
  )
}

export default TeamInvitationModal