import { FC } from "react"
import styled from "styled-components"
import SelectRule from "./SelectRule"
import { theme } from "../../../../styles"
import CheckBoxOption from "./CheckboxOption"

interface IRuleProps {
  title: string
  name:
    | 'jira.when'
    | 'jira.then'
    | 'jira.jiraProject'
    | 'jira.syncWithJira'
    | 'mail.when'
    | 'mail.then'
  type: {
    name: string
    options?: any
    label?: string
    placeholder?: string
  }
}

const {copy} = theme.colors;


const StyledRule = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    font-size: 16px;
    font-weight: 420;
    line-height: 140%;
    text-transform: uppercase;
    color: ${copy};
  }
`

const StyledRuleOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const Rule: FC<IRuleProps> = ({ title, type, name }) => {
  return (
    <StyledRule>
      <h4>{title}</h4>

      {type.name === 'select' ? (
        <SelectRule
          options={type.options}
          name={name}
          placeholder={type.placeholder}
        />
      ) : (
        <StyledRuleOptions>
          {type.options?.map((option: { label: string; value: string }) => (
            <CheckBoxOption key={option.value} {...option} />
          ))}
        </StyledRuleOptions>
      )}
    </StyledRule>
  )
}

export default Rule