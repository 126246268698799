import React from 'react'
import { useAuth } from '../hooks/useAuth'
import Auth from '../../Auth'
import Spinner from '../../../components/ui/Spinner'

interface WithAuthOptions {
  isProtected?: boolean
}

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options: WithAuthOptions = {}
) => {
  const { isProtected = false } = options

  const ComponentWithAuth: React.FC<P> = (props) => {
    const { currentUser, loading } = useAuth()

    if (loading) {
      return <Spinner type="spinner" />
    }

    if (isProtected && !currentUser) {
      return <Auth isFromAIPage />
    }

    return <WrappedComponent {...props} />
  }

  return ComponentWithAuth
}

export default withAuth
