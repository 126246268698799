import { push, ref } from 'firebase/database'
import { CookieConsentConfig } from 'vanilla-cookieconsent'
import { db } from '../config/config'

export interface ColorObj {
  bg: string
  text: string
  id: string
  defaultColor?: boolean
  type?: 'primary' | 'secondary' | 'tertiary'
}

export const BY_DEFAULT_TEMPLATE_COLORS = {
  '1': {
    id: '1',
    bg: '#CEFFC7',
    text: '#2AB514',
    defaultColor: true,
    type: 'primary'
  },
  '2': {
    id: '2',
    bg: '#F8D5A7',
    text: '#FF9508',
    defaultColor: true,
    type: 'primary'
  },
  '3': {
    id: '3',
    bg: '#F4BCAF',
    text: '#F24822',
    defaultColor: true,
    type: 'primary'
  },
  '4': {
    id: '4',
    bg: '#AEC7F8',
    text: '#1F6BFF',
    defaultColor: true,
    type: 'primary'
  },
  '5': {
    id: '5',
    bg: '#E0C3FF',
    text: '#9747FF',
    defaultColor: true,
    type: 'primary'
  }
}

export const BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_3 = {
  '1': {
    id: '1',
    text: '#747474',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'tertiary'
  },
  '2': {
    id: '2',
    text: '#BE65B9',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'tertiary'
  },
  '3': {
    id: '3',
    text: '#32C8D9',
    bg: '#CFFFF9',
    defaultColor: true,
    type: 'tertiary'
  },
  '4': {
    id: '4',
    text: '#F2762E',
    bg: '#F9E3B8',
    defaultColor: true,
    type: 'tertiary'
  },
  '5': {
    id: '5',
    text: '#CD1A62',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'tertiary'
  }
}

export const BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_2 = {
  '1': {
    id: '1',
    text: '#EC8049',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'secondary'
  },
  '2': {
    id: '2',
    text: '#F2C12E',
    bg: '#F9E3B8',
    defaultColor: true,
    type: 'secondary'
  },
  '3': {
    id: '3',
    text: '#F24162',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'secondary'
  },
  '4': {
    id: '4',
    text: '#E07FD2',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'secondary'
  },
  '5': {
    id: '5',
    text: '#7F58A6',
    bg: '#FAD1D9',
    defaultColor: true,
    type: 'secondary'
  }
}

export const BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_4 = {
  '1': {
    id: '1',
    text: '#4146A6',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'quaternary'
  },
  '2': {
    id: '2',
    text: '#464A8C',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'quaternary'
  },
  '3': {
    id: '3',
    text: '#79C4F2',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'quaternary'
  },
  '4': {
    id: '4',
    text: '#49BF9E',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'quaternary'
  },
  '5': {
    id: '5',
    text: '#A68053',
    bg: '#F2F2F2',
    defaultColor: true,
    type: 'quaternary'
  }
}

export const SECONDARY_ROW_COLORS = {
  1: [
    { hex: '#6919D0', selected: false },
    { hex: '#409425', selected: false },
    { hex: '#9747FF', selected: false },
    { hex: '#1444A1', selected: false },
    { hex: '#CB2B42', selected: false }
  ],
  2: [
    { hex: '#F24185', selected: false },
    { hex: '#AC7FE0', selected: false },
    { hex: '#5859A6', selected: false },
    { hex: '#B4F22E', selected: false },
    { hex: '#EC4C49', selected: false }
  ],
  3: [
    { hex: '#ACACAC', selected: false },
    { hex: '#AA38A4', selected: false },
    { hex: '#0093A4', selected: false },
    { hex: '#B74300', selected: false },
    { hex: '#91003A', selected: false }
  ],
  4: [
    { hex: '#2B2E73', selected: false },
    { hex: '#262955', selected: false },
    { hex: '#497793', selected: false },
    { hex: '#317965', selected: false },
    { hex: '#73583A', selected: false }
  ]
}

export const COOKIE_CONSENT_CONFIGS: CookieConsentConfig = {
  autoShow: true,
  hideFromBots: true,
  mode: 'opt-in',
  revision: 0,
  cookie: {
    name: 'cc_checklist_cookie'
    // domain: location.hostname,
    // path: '/',
    // sameSite: "Lax",
    // expiresAfterDays: 365,
  },
  guiOptions: {
    consentModal: {
      layout: 'cloud',
      flipButtons: false,
      equalWeightButtons: false,
      position: 'bottom left'
    },
    preferencesModal: {
      flipButtons: true
    }
  },
  categories: {
    functional: {
      enabled: true,
      readOnly: true
    },
    analytics: {
      enabled: true
    }
  },
  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description: `🍪 We use cookies to enhance your browsing experience, analyze site traffic, and personalize content. By continuing to use this site, you consent to the use of cookies in accordance with our Cookie Policy. For more information, please read our Privacy Policy.`,
          acceptAllBtn: 'ACCEPT ALL',
          // acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: 'MANAGE PREFERENCES',
          footer: `
              <a href="https://docs.compfeed.com/legal/privacy_policy" target="_blank">Privacy Policy</a>
              <a href="https://docs.compfeed.com/legal/terms_and_conditions" target="_blank">Terms and conditions</a>
          `
        },
        preferencesModal: {
          title: 'Cookie preferences',
          acceptAllBtn: 'ACCEPT ALL',
          // acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'ACCEPT CURRENT SELECTION',
          closeIconLabel: 'Close modal',
          sections: [
            {
              title: 'Customize your preferences',
              description: `Welcome! We respect your privacy and want to give you control over your online experience. Please manage your cookie preferences below. You can choose which types of cookies to allow or decline, and you can always update your preferences at any time. Thank you for trusting us with your data privacy.`
            },
            {
              title: 'Functional Necessary cookies',
              description:
                'These cookies are essential for the proper functioning of the website and cannot be disabled.',
              linkedCategory: 'functional'
            },
            {
              title: 'Performance and Analytics',
              description:
                'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics'
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to my policy on cookies and your choices, please <a href="mailto:support@govern.design" target="_blank">contact us</a>'
            }
          ]
        }
      }
    }
  }
}

export const getPhaseSchema = (
  templateId: string,
  teamId: string,
  userId: string
) => {
  const phase1Id: string = push(ref(db, `phases/${templateId}`)).key ?? ''
  const taskId = push(ref(db, 'templates')).key ?? ''
  const nowDate = new Date().toISOString()

  // Create a new phase schema
  return {
    [phase1Id]: {
      id: phase1Id,
      title: 'My first phase',
      templateId,
      teamId,
      createdBy: userId,
      visible: true,
      mandatory: true,
      isActive: true,
      colorId: '1',
      order: 0,
      archived: false,
      tasks: {
        [taskId]: {
          id: taskId,
          title: 'My first task',
          phaseId: phase1Id,
          templateId,
          visible: true,
          mandatory: true,
          order: 0,
          archived: false,
          createdBy: userId,
          createdAt: nowDate,
          updatedAt: nowDate
        }
      },
      createdAt: nowDate,
      updatedAt: nowDate
    }
  }
}

interface TemplateListType {
  label: string
  value: string
}

export const TEMPLATE_TYPES: TemplateListType[] = [
  { label: 'Project', value: 'project' },
  { label: 'Component', value: 'component' }
]

export const WORKFLOW_OPTIONS: {
  title: string
  desc: string
  icon: 'mail' | 'jira'
}[] = [
  {
    title: 'email',
    desc: 'Notify by email',
    icon: 'mail'
  },
  { title: 'jira', desc: 'Sync to Jira', icon: 'jira' }
]

export const WORKFLOW_OPTION = ({
  projectOptions,
  phasesOptions
}: {
  projectOptions?: { label: string; value: string }[]
  phasesOptions?: { label: string; value: string }[]
}) => ({
  email: {
    rules: [
      {
        title: 'when',
        name: 'mail.when',
        type: {
          name: 'select',
          placeholder: 'Add a trigger',
          options: [
            {
              label: 'a new component gets added',
              value: 'new_component'
            },
            ...(phasesOptions || [])
          ]
        }
      },
      {
        title: 'then',
        name: 'mail.then',
        type: {
          name: 'select',
          placeholder: 'Add a trigger',
          options: [
            {
              label: 'email only the admins',
              value: 'email_admins'
            },
            {
              label: 'email everyone',
              value: 'email_everyone'
            }
          ]
        }
      },
      {
        title: 'Include these details',
        name: 'mail.details',
        type: {
          name: 'option',
          options: [
            { label: 'Component name', value: 'component_name' },
            { label: 'Figma file name', value: 'figma_file_name' },
            { label: 'Initiator', value: 'initiator' },
            { label: 'Phases and tasks', value: 'phases' }
          ]
        }
      }
    ]
  },
  jira: {
    rules: [
      {
        title: 'when',
        name: 'jira.when',
        type: {
          name: 'select',
          placeholder: 'Add a trigger',
          options: [
            {
              label: 'a new component gets added',
              value: 'new_component'
            }
          ]
        }
      },
      {
        title: 'then',
        name: 'jira.then',
        type: {
          name: 'select',
          placeholder: 'Add a trigger',
          options: [
            {
              label: 'create a Jira ticket',
              value: 'create_jira_ticket'
            }
          ]
        }
      },
      {
        title: 'Select Jira project',
        name: 'jira.jiraProject',
        type: {
          name: 'select',
          placeholder: 'Select a jira project',
          options: projectOptions
        }
      },
      {
        title: 'Include these details',
        name: 'jira.details',
        type: {
          name: 'option',
          options: [
            { label: 'Component name', value: 'component_name' },
            { label: 'Figma file name', value: 'figma_file_name' },
            { label: 'Initiator', value: 'initiator' },
            { label: 'Phases and tasks', value: 'phases' }
          ]
        }
      },
      {
        title: 'Sync with Jira',
        name: 'jira.syncWithJira',
        type: {
          name: 'select',
          options: [
            {
              label: 'On every change',
              value: 'on_every_change'
            }
          ]
        }
      }
    ]
  }
})
