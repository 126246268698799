import React from 'react'
import styled from 'styled-components'
import { IDropdownItem } from './DropDown'
import Button from '../Button/Button'
import { theme } from '../../../styles'

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DropdownSelectorContainer = styled.div<{
  $isOpen: boolean
  $size: 'm' | 's'
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: ${({ $size }) => ($size === 'm' ? '226px' : '177px')};
  padding: 8px 8px 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  gap: 8px;
`

export const DropdownSelectedText = styled.div<{
  $size: 'm' | 's'
  $color: string
}>`
  font-family: 'rooney-sans';
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  text-align: left;
  font-size: ${({ $size }) => ($size === 'm' ? '20px' : '16px')};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;
  color: ${({ theme, $color }) => $color ?? theme.colors.copy};
`

type ISelectProps = React.FC<{
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedItem: IDropdownItem
  menuPosition: 'top' | 'bottom'
  onKeyDown?: (e: React.KeyboardEvent) => void
  size?: 'm' | 's'
  textColor?: string
}>

const DropdownSelector: ISelectProps = (props) => {
  const {
    isOpen,
    setIsOpen,
    selectedItem,
    onKeyDown,
    size = 'm',
    textColor = theme.colors.copy
  } = props
  return (
    <DropdownSelectorContainer
      $isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={onKeyDown}
      $size={size}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {/* <IconComponent icon="file-text" iconSize="s" /> */}
        <DropdownSelectedText $size={size} $color={textColor}>
          {selectedItem?.name}
        </DropdownSelectedText>
      </div>
      <Button
        iconOnly
        icon={isOpen ? 'chevron-up' : 'chevron-down'}
        variant="primary"
        size={size === 'm' ? 's' : 'xs'}
        inverted
        onClick={() => setIsOpen(!isOpen)}
        iconColor={theme.colors.actionPrimary}
      />
    </DropdownSelectorContainer>
  )
}

export default DropdownSelector
