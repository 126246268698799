import { useState, useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import Fuse from 'fuse.js'
import { ref, onValue, off } from 'firebase/database'
import { debounce } from 'lodash'
import { db } from '../../../../config/config'
import { useKanbanContext } from '../providers'
import { ComponentObj } from '../../../../store/reducers/componentsReducerSlice'

interface SearchResult extends ComponentObj {}

export const useSearch = (teamId: string, q: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState(q)
  const [fullDataset, setFullDataset] = useState<SearchResult[]>([])
  const [results, setResults] = useState<SearchResult[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const searchListenerRef = useRef<(() => void) | null>(null)
  const fuseRef = useRef<Fuse<SearchResult> | null>(null)
  const { setComponents } = useKanbanContext()

  // Advanced search configuration
  const fuseOptions = {
    keys: ['title', 'description', 'tags', 'category'],
    threshold: 0.4,
    includeScore: true,
    shouldSort: true
  }

  // Update URL search params
  const updateSearchParam = useCallback(
    (term: string) => {
      const params = new URLSearchParams(searchParams)
      if (term) {
        params.set('q', term)
      } else {
        params.delete('q')
      }
      setSearchParams(params)
    },
    [searchParams, setSearchParams]
  )

  // Fetch full dataset for advanced search
  const fetchFullDataset = useCallback(() => {
    if (!teamId) return

    setIsLoading(true)
    const componentsRef = ref(db, `/components/${teamId}`)

    const unsubscribe = onValue(
      componentsRef,
      (snapshot) => {
        try {
          const items: SearchResult[] = []
          snapshot.forEach((child) => {
            const component = child.val()
            items.push({
              ...component,
              id: child.key // Ensure unique identifier
            })
          })

          setFullDataset(items)
          fuseRef.current = new Fuse(items, fuseOptions)

          // Perform initial search if search term exists
          if (searchTerm) {
            performAdvancedSearch(searchTerm)
          }
        } catch (err: any) {
          setError(err.message)
        } finally {
          setIsLoading(false)
        }
      },
      (error) => {
        setError(error.message)
        setIsLoading(false)
      }
    )

    searchListenerRef.current = () => {
      off(componentsRef)
      unsubscribe()
    }
  }, [teamId])

  // Perform advanced fuzzy search
  const performAdvancedSearch = useCallback(
    (term: string) => {
      if (!fuseRef.current || !term) {
        setResults(fullDataset)
        setComponents(fullDataset)
        return
      }

      const searchResults = fuseRef.current.search(term)
      const filteredResults = searchResults
        .map((result) => result.item)
        .slice(0, 20) // Limit to top 20 results

      setResults(filteredResults)
      setComponents(filteredResults)
    },
    [fullDataset, setComponents]
  )

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce((term: string) => {
      performAdvancedSearch(term)
      updateSearchParam(term)
    }, 300),
    [performAdvancedSearch, updateSearchParam]
  )

  // Handle search input
  const handleSearch = useCallback(
    (term: string) => {
      setSearchTerm(term)
      debouncedSearch(term)
    },
    [debouncedSearch]
  )

  // Fetch dataset and setup initial search
  useEffect(() => {
    fetchFullDataset()
    return () => {
      if (searchListenerRef.current) {
        searchListenerRef.current()
      }
      debouncedSearch.cancel()
    }
  }, [teamId, fetchFullDataset])

  // Perform search when term changes
  useEffect(() => {
    handleSearch(q)
  }, [q, handleSearch])

  return {
    searchTerm,
    results,
    isLoading,
    error,
    handleSearch,
    fullDataset
  }
}
