import styled from 'styled-components'
import { NewMail } from '../../../assets/icons/NewMail'
import { Jira } from '../../../assets/icons/Jira'
import { StyledTabIconTitle } from './TabNav'
import { theme } from '../../../../styles'
import { FC } from 'react'
import { useWorkflow } from '../../../../context/WorkflowActionProvider'
import { useNavigate, useLocation } from 'react-router-dom'

const { actionPrimary, borderDefault } = theme.colors

interface ITabProps {
  title: string
  icon: 'mail' | 'jira'
  desc: string
}

const StyledTabDesc = styled.p`
  font-size: 16px;
  font-weight: 380;
  line-height: 140%;
`

const StyledTab = styled.button<{
  $isActive: boolean
}>`
  background: ${({ $isActive }) =>
    $isActive ? theme.colors.actionPrimaryLight : 'none'};
  padding: 16px;
  color: ${actionPrimary};
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 5px;
  border: ${({ $isActive }) =>
    $isActive ? `1px solid ${actionPrimary};` : `1px solid ${borderDefault}`};
  transition: all 0.3s;
`

const Tab: FC<ITabProps> = ({ title, icon, desc }) => {
  const { device, option, onChangeOption } = useWorkflow()
  const navigate = useNavigate()
  const location = useLocation()

  const isMobile = device === 'mobile'

  const isActive = option === title

  const handleChange = () => {
    onChangeOption(title)
    navigate(`${location.pathname}?type=${title}`)
  }

  return (
    <StyledTab $isActive={isActive} onClick={handleChange} type="button">
      <StyledTabIconTitle $isMobile={isMobile}>
        {icon === 'mail' ? (
          <NewMail size={!isMobile ? 32 : 24} color={actionPrimary} />
        ) : (
          <Jira size={!isMobile ? 32 : 24} color={actionPrimary} />
        )}
        <p>{title === 'jira' ? 'Jira' : title}</p>
      </StyledTabIconTitle>
      {!isMobile && <StyledTabDesc>{desc}</StyledTabDesc>}
    </StyledTab>
  )
}

export default Tab
