import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import React, { FC, HTMLAttributes,  } from "react";
import Checkbox from "../CheckBox/Checkbox";

interface IOptionProps extends HTMLAttributes<HTMLDivElement>{
  label: string;
  checked: boolean;
  disabled?: boolean;
  state?: "default" | "hover" | "active";
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

const {
  actionPrimaryHover,
  actionPrimaryActive,
  borderDefault,
  actionPrimaryLightActive,
  copySubtle,
  copy,
} = theme.colors;

const variants = {
  default: {
    borderColor: borderDefault,
  },
  hover: {
    borderColor: actionPrimaryHover,
  },
  active: {
    borderColor: actionPrimaryActive,
  },
  disabled: {
    borderColor: actionPrimaryLightActive,
  },
};

const StyledOptionContainer = styled.div<{
  disabled: boolean;
  $state: "default" | "hover" | "active";
}>`
  border-radius: 4px;
  font-size: 16px;
  line-height: 140%;
  padding: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  gap: 8px;
  transition: all 0.2s;

  ${({ disabled, $state }) => {
    const {hover, active, disabled: _disabled} = variants;
    const color = disabled ? copySubtle : copy;
    const borderColor = disabled
      ? _disabled.borderColor
      : variants[$state].borderColor;


    return css`
      color: ${color};
      border: 1px solid ${borderColor};
      cursor: ${disabled ? "not-allowed" : "pointer"};

      &:hover {
        border-color: ${
          disabled ? _disabled.borderColor : hover.borderColor
        };

      &:active{
        border-color: ${
          disabled ? _disabled.borderColor : active.borderColor
         };
      }
    `;
  }}
`;

const StyledLabel = styled.label<{
  disabled: boolean;
}>`
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({ disabled }) => `${disabled ? "not-allowed" : "pointer"}`};
`;

const Option: FC<IOptionProps> = ({
  checked,
  label,
  disabled = false,
  state = "default",
  onClick,
  ...props
}) => {

  const _onClick = () => {
    if (!disabled) {
      onClick?.();
    }
  };

  return (
    <StyledOptionContainer
      disabled={disabled}
      $state={state}
      onClick={_onClick}
      {...props}
      className="option"
    >
      <Checkbox
        size="small"
        checked={checked}
        disabled={disabled}
        onChange={_onClick}
      />
      <StyledLabel disabled={disabled}>{label}</StyledLabel>
    </StyledOptionContainer>
  );
};

export default Option;