import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { auth } from '../../../config/config'
import { atlassianAuthCheckOptions } from '../../../pages/workflows/hooks/useCheckAtlassianAuth'
import { getWorkflowsOptions } from '../../../pages/workflows/hooks/useGetWorkflow'

const AtlassianCallback = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const [teamId, templateId] = state?.split('::') || []
  const data = {
    teamId,
    templateId
  }

  const queryClient = useQueryClient()

  const { isPending, mutateAsync, error } = useMutation({
    mutationFn: async () => {
      const token = await auth.currentUser?.getIdToken()
      return httpRequest({
        url: '/atlassian/callback',
        method: 'POST',
        data: { code },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })(nest)
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries(atlassianAuthCheckOptions(data)),
        queryClient.refetchQueries(getWorkflowsOptions(data))
      ])
      window.close()
    }
  })

  const fetchAtlassianCallback = useCallback(async () => {
    await mutateAsync()
  }, [mutateAsync])

  useEffect(() => {
    if (!code) {
      console.error('Authorization code is missing')
      return
    }

    fetchAtlassianCallback()
  }, [code, fetchAtlassianCallback])

  if (isPending) {
    return (
      <div>
        <h1>Authenticating with Atlassian...</h1>
      </div>
    )
  }

  if (error) {
    console.error('Error authenticating with Atlassian', error)
    return
  }

  return (
    <div>
      <h1>Authenticating with Atlassian...</h1>
    </div>
  )
}

export default AtlassianCallback
