import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { db } from '../../../config/config'
import { useSelector, useDispatch } from 'react-redux'
import { push, ref, set } from 'firebase/database'
import {
  setPhasesData,
  PhaseObj,
  TaskObj
} from '../../../store/reducers/phaseReducerSlice'
import { TemplateColorObj } from '../../../store/reducers/templateReducerSlice'
import { RootState } from '../../../store'
import { theme } from '../../../styles'
import { toast } from 'react-toastify'
import { getTimeStamp, sortedDataByOrder } from '../../../utils/helpers'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import useGetCurrentPhases from '../../../hooks/useGetCurrentPhases'
import {
  StyledAddItemContainer,
  StyledAddItemContent,
  StyledAddItemHeader,
  StyledAddItemTitle
} from './StyledAddItem'
import Button from '../Button/Button'
import InputField from '../InputField/InputField'
import ColorPicker from '../ColorPicker/ColorPicker'
import ExpandCollapse from '../ExpandCollapse/ExpandCollapse'
import { isColorInUseInTeam } from '../../../utils/isColorUseInSameTeam'
import { useUpdatePhasesJira } from '../../../hooks/api'

interface IAddItemProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'phase' | 'task' | 'link'
  collapsed: boolean
  index?: number
  disabled?: boolean
  btnDisabled?: boolean
  templateId?: string
  phaseId?: string
  saveBtnType?: 'button' | 'submit'
  saveBtnVariant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'utility'
    | undefined
  componentType?: 'form' | 'div'
  inputPlaceholder?: string
  isAnyTaskDragging?: boolean
  maxTitleLimit?: number
  onSave?: (data: any, callback?: () => void) => void
  containerRef?: React.RefObject<HTMLElement>
}

export const StyledPickColorTitle = styled.p`
  color: ${({ theme }) => theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

export const StyledPickColor = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.colors.borderDefault};

  @media screen and (min-width: 430px) {
    padding: 24px;
  }
`

const INITIAL_FORM_DATA = {
  title: '',
  visible: true,
  mandatory: true,
  isActive: true
}

const AddItem = ({
  type = 'phase',
  collapsed = false,
  index,
  disabled,
  btnDisabled,
  templateId,
  inputPlaceholder,
  phaseId,
  isAnyTaskDragging,
  containerRef,
  saveBtnType = 'submit',
  componentType = 'form',
  saveBtnVariant = 'primary',
  children,
  onSave = () => {},
  maxTitleLimit = 120,
  ...props
}: IAddItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const titles = {
    phase: 'ADD PHASE',
    task: 'ADD TASK',
    link: 'ADD LINKS'
  }
  const title = titles[type]
  const isMobile = useIsMobileDevice()

  //if the component is mapped and the index is 0, it should be expanded by default
  const [isCollapsed, setIsCollapsed] = useState(
    index === 0 ? false : collapsed
  )
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<PhaseObj | any>(INITIAL_FORM_DATA)
  // check if the add color option is open in the color picker
  const [isAddColorOptionOpen, setIsAddColorOptionOpen] = useState(false)

  const { data: allPhases } = useSelector((store: RootState) => store.phase)
  const { user } = useSelector((store: RootState) => store.auth)
  const { currentTeam } = useSelector((store: RootState) => store.team)

  const { phases } = useGetCurrentPhases(allPhases, templateId)

  const { mutateAsync, isPending } = useUpdatePhasesJira({
    currentTeam,
    templateId
  })

  // if any task component is dragging, collapse this component
  useEffect(() => {
    if (isAnyTaskDragging) setIsCollapsed(true)
  }, [isAnyTaskDragging])

  // if the component is disabled, it should be collapsed
  useEffect(() => {
    if (disabled) setIsCollapsed(true)
  }, [disabled])

  // check if the provided color is already in used by any phase
  const findUsedPhase = useCallback(
    (obj: TemplateColorObj): PhaseObj | undefined => {
      return phases?.find((v) => v?.colorId === obj?.id)
    },
    [phases]
  )

  // select a color
  const handleColorSelect = useCallback(
    async (colorObj: TemplateColorObj) => {
      const teamId =
        currentTeam?.id || localStorage.getItem('checklist_team_id')
      if (!teamId) {
        toast.info('Team ID not found.')
        return
      }

      const isDuplicate = await isColorInUseInTeam(teamId, colorObj.text, '')
      if (isDuplicate) {
        toast.info(
          `This color is already in use in another template in your team. Please choose a different color.`
        )
        return
      }

      const inUsedPhase = findUsedPhase(colorObj)
      if (inUsedPhase) {
        toast.info(
          `Sorry mate, this color is already in use in ${inUsedPhase?.title}.`
        )
        return
      }

      setFormData({
        ...formData,
        colorId: colorObj?.id,
        color: colorObj
      })
    },
    [currentTeam?.id, findUsedPhase, formData]
  )

  // find phase and tasks info
  const groupInfo = useMemo(() => {
    const phase = phases?.find((v) => v?.id === phaseId)
    const tasks: TaskObj[] = phase?.tasks || []
    return { phase, tasks }
  }, [phaseId, phases])

  // clear form data
  const clearFormData = () => {
    setFormData(INITIAL_FORM_DATA)
  }

  // save the new phase or task
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (type === 'link') {
      onSave(formData?.title, clearFormData)
      return
    }

    if (isSaving) return

    if (!formData?.title?.trim()) {
      toast.error(`Please provide ${type} title.`)
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team ID not found.')
      return
    }

    // Phase limit validation
    if (type === 'phase' && phases.length >= 50) {
      toast.info('You have reached the maximum limit of 50 phases.')
      return
    }

    setIsSaving(true)
    const timestamp = getTimeStamp()
    const newTaskId = push(ref(db, 'templates')).key ?? ''

    if (type === 'phase') {
      if (!formData?.colorId) {
        toast.info('Please select a color.')
        setIsSaving(false)
        return
      }
      const newPhaseId = push(ref(db, 'phases')).key ?? ''
      if (!newPhaseId) {
        toast.error('Something went wrong.')
        setIsSaving(false)
        return
      }
      const getOrderNo =
        phases?.length > 0
          ? sortedDataByOrder(phases || [], 'order')[0]?.order + 1 || 0
          : 0

      const obj = {
        ...formData,
        id: newPhaseId,
        teamId,
        templateId,
        createdBy: user?.id || '',
        tasks: {
          [newTaskId]: {
            id: newTaskId,
            title: 'Initial Task',
            createdBy: user?.id ?? '',
            phaseId: newPhaseId,
            templateId,
            order: 0,
            createdAt: timestamp,
            updatedAt: timestamp,
            mandatory: true,
            visible: true,
            archived: false
          }
        },
        order: getOrderNo,
        createdAt: timestamp,
        updatedAt: timestamp,
        archived: false
      }

      try {
        await set(ref(db, `phases/${templateId}/${newPhaseId}`), obj)
        dispatch(
          setPhasesData({
            data: [...allPhases, { ...obj, tasks: [obj.tasks[newTaskId]] }]
          })
        )
        if (user?.jira?.access_token) {
          await mutateAsync()
        }

        toast.success('Just added a new phase.')
        setFormData({
          title: '',
          visible: true,
          mandatory: true,
          isActive: true
        })

        containerRef?.current?.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth'
        })
      } catch (error) {
        console.log('Error creating new phase', error)
        toast.error('Something went wrong.')
      } finally {
        setIsSaving(false)
      }
    } else if (type === 'task') {
      if (!newTaskId) {
        toast.error('Something went wrong.')
        setIsSaving(false)
        return
      }
      const getOrderNo = groupInfo?.tasks
        ? sortedDataByOrder(groupInfo?.tasks || [], 'order')[0]?.order + 1 || 0
        : 0

      const formDataTemp = { ...formData }
      delete formDataTemp.isActive

      const obj = {
        ...formDataTemp,
        id: newTaskId,
        phaseId,
        templateId: templateId,
        order: getOrderNo,
        createdBy: user?.id ?? '',
        createdAt: timestamp,
        updatedAt: timestamp,
        archived: false
      }

      try {
        await set(
          ref(db, `phases/${templateId}/${phaseId}/tasks/${newTaskId}`),
          obj
        )

        dispatch(
          setPhasesData({
            data: allPhases?.map((p) => {
              if (p?.id === phaseId) {
                const prev = p?.tasks || []
                return {
                  ...p,
                  tasks: [obj, ...prev]
                }
              }
              return p
            })
          })
        )
        if (user?.jira?.access_token) {
          await mutateAsync()
        }

        toast.success('A new task is added.')
        setFormData({
          title: '',
          visible: true,
          mandatory: true,
          isActive: true
        })
      } catch (error) {
        console.log('Error creating new task', error)
        toast.error('Something went wrong.')
      } finally {
        setIsSaving(false)
      }
    }
  }

  //   const renderContent = (type: 'phase' | 'task') => {
  //     if (type === 'phase')
  //       return (
  //         <>
  //           <span style={{ backgroundColor: 'white', width: '100%' }}>
  //             <InputField
  //               label="Phase title"
  //               fullWidth
  //               name="title"
  //               required
  //               //   disabled={disabled || isSaving}
  //               placeholder="maximum of 120 characters"
  //               type="text"
  //               value={formData?.title}
  //               inputRef={inputRef}
  //               autoFocus
  //               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
  //                 if (disabled || isSaving) return
  //                 setFormData({
  //                   ...formData,
  //                   title:
  //                     e.target?.value?.length < 120
  //                       ? e.target?.value
  //                       : formData?.title
  //                 })
  //               }}
  //             />
  //           </span>
  //           <StyledPickColor>
  //             <StyledPickColorTitle>Pick a color </StyledPickColorTitle>
  //             <ColorPicker
  //               formData={formData}
  //               handleColorSelect={handleColorSelect}
  //               setIsAddColorOptionOpen={setIsAddColorOptionOpen}
  //               templateId={templateId}
  //             />
  //           </StyledPickColor>
  //         </>
  //       )
  //     else {
  //       return (
  //         <span style={{ backgroundColor: 'white', width: '100%' }}>
  //           <InputField
  //             label="Task"
  //             fullWidth
  //             // disabled={disabled || isSaving}
  //             required
  //             placeholder="maximum of 120 characters"
  //             name="title"
  //             type="text"
  //             value={formData.title}
  //             inputRef={inputRef}
  //             autoFocus
  //             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
  //               if (disabled || isSaving) return
  //               setFormData({
  //                 ...formData,
  //                 title:
  //                   e.target?.value?.length < 120
  //                     ? e.target?.value
  //                     : formData?.title
  //               })
  //             }}
  //           />
  //         </span>
  //       )
  //     }
  //   }

  return (
    <WrapperComponent type={componentType} onSubmit={handleSave}>
      <StyledAddItemContainer {...props} disabled={disabled}>
        <StyledAddItemContent>
          <StyledAddItemHeader
            onClick={() => {
              if (!disabled) {
                setIsCollapsed(!isCollapsed)
                if (type === 'phase' && inputRef) {
                  inputRef?.current?.focus()
                }
              }
            }}
          >
            <StyledAddItemTitle disabled={disabled}>{title}</StyledAddItemTitle>
            <ExpandCollapse
              collapse={isCollapsed}
              type="border"
              color={theme.colors.actionPrimary}
              disabled={disabled}
            />
          </StyledAddItemHeader>
          <StyledAddItemContent collapsed={isCollapsed}>
            {/* {renderContent(type)} */}
            {type === 'phase' ? (
              <>
                <span style={{ backgroundColor: 'white', width: '100%' }}>
                  <InputField
                    label="Phase title"
                    fullWidth
                    name="title"
                    required
                    disabled={disabled || isSaving}
                    placeholder="maximum of 120 characters"
                    type="text"
                    value={formData?.title}
                    inputRef={inputRef}
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (disabled || isSaving) return
                      setFormData({
                        ...formData,
                        title:
                          e.target?.value?.length < 120
                            ? e.target?.value
                            : formData?.title
                      })
                    }}
                  />
                </span>
                <StyledPickColor>
                  <StyledPickColorTitle>Pick a color </StyledPickColorTitle>
                  <ColorPicker
                    formData={formData}
                    handleColorSelect={handleColorSelect}
                    setIsAddColorOptionOpen={setIsAddColorOptionOpen}
                    templateId={templateId}
                  />
                </StyledPickColor>
              </>
            ) : (
              <>
                {children}
                <div style={{ backgroundColor: 'white', width: '100%' }}>
                  <InputField
                    label={inputPlaceholder || type?.toUpperCase()}
                    fullWidth
                    disabled={disabled || isSaving}
                    required={type !== 'link'}
                    // placeholder="maximum of 120 characters"
                    name="title"
                    type="text"
                    value={formData.title}
                    inputRef={inputRef}
                    //   autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (disabled || isSaving) return
                      setFormData({
                        ...formData,
                        title:
                          e.target?.value?.length < maxTitleLimit
                            ? e.target?.value
                            : formData?.title
                      })
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (e.key === 'Enter') {
                        if (
                          saveBtnType === 'button' &&
                          componentType === 'div'
                        ) {
                          if (onSave) {
                            onSave(formData?.title, clearFormData)
                            inputRef?.current?.focus()
                          }
                        }
                      }
                    }}
                  />
                </div>
                {/* {children} */}
              </>
            )}
          </StyledAddItemContent>
        </StyledAddItemContent>
        {!isCollapsed && (
          <Button
            label={`ADD ${type.toUpperCase()}`}
            icon="plus"
            variant={saveBtnVariant}
            loading={isPending}
            iconPosition="before"
            disabled={
              (type === 'phase' && phases.length >= 50) ||
              isAddColorOptionOpen ||
              btnDisabled
            }
            type={saveBtnType}
            onClick={() => {
              if (saveBtnType === 'button') {
                if (onSave) {
                  onSave(formData?.title, clearFormData)
                  inputRef?.current?.focus()
                }
              }
            }}
            size={isMobile ? 'small' : 'medium'}
          />
        )}
      </StyledAddItemContainer>
    </WrapperComponent>
  )
}

const WrapperComponent = ({
  type = 'form',
  children,
  onSubmit,
  ...props
}: {
  type: 'form' | 'div'
  onSubmit?: (e: React.FormEvent) => void
  children: React.ReactNode
}) => {
  if (type === 'form') {
    return (
      <form style={{ width: '100%' }} onSubmit={onSubmit} {...props}>
        {children}
      </form>
    )
  }
  return (
    <div style={{ width: '100%' }} {...props}>
      {children}
    </div>
  )
}
export default AddItem
