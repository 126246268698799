import { queryOptions, useQuery } from '@tanstack/react-query'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { auth } from '../../../config/config'
import { isEmpty } from 'lodash'

export const ATLASSIAN_AUTH_CHECK = 'atlassianAuthCheck'

type TAtlassianAuthCheck = {
  teamId: string
  templateId: string
}

export const atlassianFetcher = async (props: TAtlassianAuthCheck) => {
  const token = await auth.currentUser?.getIdToken()
  return httpRequest({
    url: 'atlassian/auth/check-auth',
    method: 'GET',
    params: props,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })<WorkflowResponse>(nest)
}

export const atlassianAuthCheckOptions = ({
  teamId,
  templateId
}: TAtlassianAuthCheck) =>
  queryOptions({
    queryKey: [ATLASSIAN_AUTH_CHECK, teamId, templateId],
    queryFn: async () => atlassianFetcher({ teamId, templateId }),
    enabled: !isEmpty(teamId) && !isEmpty(templateId),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
    gcTime: 1000 * 60,
    refetchIntervalInBackground: true
  })

const useCheckAtlassianAuth = (props: TAtlassianAuthCheck) => {
  return useQuery(atlassianAuthCheckOptions(props))
}

export default useCheckAtlassianAuth
