import { ReactNode } from "react";
import { styled } from "styled-components";

const StyledListCardContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  margin: 24px 0 48px 0;
  padding: 0 16px;
  align-content: space-between;
`;

const ListCardContainer = ({ children }: { children: ReactNode }) => {
  return <StyledListCardContainer>{children}</StyledListCardContainer>;
};

export default ListCardContainer;
