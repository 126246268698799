import KanbanProvider from '../../components/ui/kanban/providers'
import Views from './Views'

// ------------------- All components list page ------------
const AllComponents: React.FC = () => {
  // JSX
  return (
    <KanbanProvider>
        <Views />
    </KanbanProvider>
  )
}

export default AllComponents
