import { useMutation } from '@tanstack/react-query'
import httpRequest from '../../utils/fetcher'
import { auth } from '../../config/config'
import { nest } from '../../utils/axios'
import { toast } from 'react-toastify'
import { TeamObj } from '../../store/reducers/teamReducerSlice'

type IUpdatePhasesJira = {
  templateId?: string | null
  currentTeam?: TeamObj | null
}

export const useUpdatePhasesJira = ({
  currentTeam,
  templateId
}: IUpdatePhasesJira) => {
  return useMutation({
    mutationFn: async () => {
      const token = await auth.currentUser?.getIdToken()
      return httpRequest({
        url: `/atlassian/issues/phase/${templateId}/${currentTeam?.id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })(nest)
    },
    onError: (error) => {
      import.meta.env.DEV && console.error('Error updating phase', error)
      toast.error('Something went wrong.')
    }
  })
}
