import React, { useId, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import IconComponent from '../IconComponent/IconComponent'

interface ICheckboxProps {
  checked: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
  size?: 'small' | 'medium'
  state?: 'default' | 'active' | 'hover'
}

interface IStyledCheckbox {
  checked: boolean
  disabled?: boolean
}

const sizes = {
  small: {
    width: '24px',
    height: '24px'
  },
  medium: {
    width: '32px',
    height: '32px'
  }
}

const {
  actionPrimary,
  actionPrimaryHover,
  actionPrimaryActive,
  borderDefault,
  actionPrimaryLightActive
} = theme.colors

const variants = {
  default: {
    borderColor: borderDefault,
    checked: {
      borderColor: actionPrimary,
      backgroundColor: actionPrimary
    }
  },
  hover: {
    borderColor: actionPrimaryHover,
    checked: {
      borderColor: actionPrimaryHover,
      backgroundColor: actionPrimaryHover
    }
  },
  active: {
    borderColor: actionPrimaryActive,
    checked: {
      borderColor: actionPrimaryActive,
      backgroundColor: actionPrimaryActive
    }
  },
  disabled: {
    borderColor: actionPrimaryLightActive,
    checked: {
      borderColor: actionPrimaryLightActive,
      backgroundColor: actionPrimaryLightActive
    }
  }
}

const StyledCheckboxContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
`

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const StyledCheckbox = styled.div<
  IStyledCheckbox & {
    $size: 'small' | 'medium'
    $state: 'default' | 'active' | 'hover' | 'disabled'
  }
>`
  display: flex;
  width: ${({ $size }) => sizes[$size].width};
  height: ${({ $size }) => sizes[$size].height};
  border-radius: 4px;

  ${({ checked, disabled, $state }) => {
    const _state = variants[$state]
    const backgroundColor = checked
      ? _state.checked.backgroundColor
      : 'transparent'

    const borderColor = checked
      ? _state.checked.borderColor
      : _state.borderColor

    const hoverBgColor = checked
      ? variants.hover.checked.backgroundColor
      : 'transparent'
    const hoverBorderColor = variants.hover.borderColor

    const activeBgColor = checked
      ? variants.active.checked.backgroundColor
      : 'transparent'
    const activeBorderColor = variants.active.borderColor

    return css`
      background-color: ${backgroundColor};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      transition: all 0.2s linear;
      border: 1px solid ${borderColor};
      justify-content: center;
      align-items: center;

      ${!disabled &&
      css`
        .option:hover &,
        &:hover {
          background-color: ${hoverBgColor};
          border: 1px solid ${hoverBorderColor};
        }

         .option:active &,
        &:active {
          background-color: ${activeBgColor};
          border: 1px solid ${activeBorderColor};
        }
      `}
    `
  }}
`

const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  onChange,
  disabled = false,
  size = 'medium',
  state = 'default'
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked)
  }

  const checkboxRef = useRef(null)
  const id = useId()

  const handleCheckmarkClick = () => {
    checkboxRef.current && (checkboxRef.current as HTMLInputElement).click()
  }

  return (
    <StyledCheckboxContainer>
      <StyledHiddenCheckbox
        checked={checked}
        onChange={handleChange}
        ref={checkboxRef}
        id={id}
        disabled={disabled}
      />
      <StyledCheckbox
        $size={size}
        checked={checked}
        onClick={handleCheckmarkClick}
        disabled={disabled}
        $state={disabled ? 'disabled' : state}
      >
        <IconComponent
          icon="check"
          color="white"
          iconSize={size === 'small' ? 's' : 'm'}
        />
      </StyledCheckbox>
    </StyledCheckboxContainer>
  )
}

export default Checkbox

export const StateFullCheckBox = ({ disabled }: { disabled?: boolean }) => {
  const [checked, setChecked] = useState(false)

  return (
    <Checkbox checked={checked} onChange={setChecked} disabled={disabled} />
  )
}
