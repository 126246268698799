import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../components/ui/Card/Card'
import { RootState } from '../../store'
import { ComponentObj } from '../../store/reducers/componentsReducerSlice'
import { PhaseObj } from '../../store/reducers/phaseReducerSlice'
import { StyledListBodyContainer } from '../../components/screens/Lister/ListBodyContainer'
import ListCardContainer from '../../components/screens/Lister/ListCardContainer'
import { StyledContentContainer } from '../../components/screens/Lister/Lister'
import Spinner from '../../components/ui/Spinner'
import ProjectHeader from './ProjectsHeader.tsx'
import Banner from '../../components/ui/Banner/Banner'
import EmptyState from '../../components/ui/EmptyState/EmptyState'
import { sortDataByDate } from '../../utils/helpers.ts'
import useLocalStorage from '../../hooks/useLocalStorage.tsx'

// ------------------- All projects list page ------------
const AllProjects: React.FC = () => {
  const navigate = useNavigate()
  const { data: projects, isLoading: isLoadingProjects } = useSelector(
    (store: RootState) => store.project
  )
  const { data: templateList, isLoading: templateLoading } = useSelector(
    (store: RootState) => store.template
  )

  const { data: allPhases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )

  const getTemplate = (templateId: string) => {
    if (templateLoading || isLoadingProjects || !templateList || !projects)
      return
    return templateList.find((template) => template?.id === templateId)
  }

  // count the total number of un-archived tasks
  const totalPhasesTasks = (templateId: string) => {
    const phases =
      allPhases?.filter((phase) => phase?.templateId === templateId) || []

    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )
    const totalUnArchivedTasks = unArchivedPhases?.reduce((acc, phase) => {
      const unArchivedTasksInPhase =
        phase?.tasks?.filter((task) => !task.archived).length || 0
      return acc + unArchivedTasksInPhase
    }, 0)

    return totalUnArchivedTasks
  }

  // count the total number of completed tasks that are not archived
  const totalCompletedTasks = (comp: ComponentObj) => {
    const allCompletedTasksIds = comp?.completedTasks

    if (!allCompletedTasksIds) return 0

    const phases =
      allPhases?.filter((phase) => phase?.templateId === comp?.templateId) || []

    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )

    const allUnArchivedTasks = unArchivedPhases?.flatMap(
      (phase) => phase?.tasks?.filter((task) => !task?.archived) || []
    )

    const completedTasks = allUnArchivedTasks?.filter((task) =>
      allCompletedTasksIds.includes(task?.id)
    )
    return completedTasks?.length || 0
  }

  // get the phase of the component
  const getPhase = (
    templateId: string,
    comp: ComponentObj
  ): PhaseObj | undefined => {
    const phase = Object.values(allPhases)
      ?.filter((phase) => !phase?.archived && phase?.templateId === templateId)
      ?.find((phase) => phase?.id === comp?.activePhase)
    return phase
  }

  const [isOpen, setIsOpen] = useLocalStorage('add_more_projects_banners', true)

  // JSX
  return (
    <StyledContentContainer>
      <ProjectHeader />
      {projects?.length === 0 || isLoadingProjects ? null : (
        <Banner
          body="To add more projects, link more widgets to projects in Figma."
          buttonLabel="LEARN MORE"
          handleButtonClick={() =>
            window.open('https://docs.compfeed.com/select-component')
          }
          variant="illustration"
          illustrationType="widget"
          type="callout"
          isOpen={isOpen}
          isCloseable
          handleClose={() => setIsOpen(false)}
        />
      )}
      {projects?.length > 0 ||
      isLoadingProjects ||
      isLoadingPhases ||
      templateLoading ? (
        <StyledListBodyContainer>
          {isLoadingProjects || isLoadingPhases || templateLoading ? (
            <div
              style={{ width: '100%', padding: '25px' }}
              className="flex justify-center"
            >
              <Spinner type="spinner" />
            </div>
          ) : (
            <ListCardContainer>
              {sortDataByDate(projects, "desc", "createdAt")?.map((comp, ind) => {
                  const phase = getPhase(comp?.templateId || '', comp)
                  const currentTemplate = getTemplate(comp?.templateId || '')
                  const color = currentTemplate?.colors[phase?.colorId || '']
                  return (
                    <Link
                      to={`/dashboard/projects/${comp?.id}`}
                      key={ind}
                      style={{ display: 'block' }}
                    >
                      <Card
                        phaseType={1}
                        componentName={
                          comp?.linkedElement?.name || comp?.title || '-'
                        }
                        phaseTitle={phase?.title || ''}
                        tasksCompleted={totalCompletedTasks(comp)}
                        tasksTotal={totalPhasesTasks(comp?.templateId || '')}
                        bgColor={color?.bg || ''}
                        textColor={color?.text || '#000'}
                      />
                    </Link>
                  )
                })
              }
            </ListCardContainer>
          )}
        </StyledListBodyContainer>
      ) : (
        // Show empty component message and directions
        <EmptyState
          title="There are no projects here yet"
          body="To add projects to Compfeed, click on ADD NEW PROJECT button below."
          buttonLabel1="ADD NEW PROJECT"
          buttonLabel2="Need help?"
          buttonOnClick1={() => navigate('/dashboard/projects/create')}
          buttonOnClick2={() =>
            window.open('https://docs.compfeed.com/getting-started')
          }
        />
      )}
    </StyledContentContainer>
  )
}

export default AllProjects