import { HTMLAttributes, useRef, useState } from 'react'
import { IPhaseType } from '../PhaseType/PhaseType'
import { css, styled } from 'styled-components'
import { theme } from '../../../styles'
import PhaseStatus from '../PhaseStatus/PhaseStatus'
import IconComponent, { IIconNames } from '../IconComponent/IconComponent'
import Button from '../Button/Button'
import { StyledPopOverMenuContainer } from '../PopOverMenu/PopOverMenu'
import MenuHeader from '../MenuHeader/MenuHeader'
import MenuItems from '../MenuItems/MenuItems'
import { Popover } from 'react-tiny-popover'

export interface ICardProps extends HTMLAttributes<HTMLButtonElement> {
  tasksCompleted: number
  tasksTotal: number
  componentName: string
  phaseTitle?: string
  phaseType: IPhaseType
  fullWidth?: boolean
  disabled?: boolean
  figmaFileUrl?: string
  bgColor?: string
  textColor?: string
  type?: 'kanban' | 'default'
  date?: string
  activityIcon?: IIconNames
  isDragging?: boolean
  onMoreOptionsClick?: () => void
}

const StyledCard = styled.div<{
  $fullWidth?: boolean
  $disabled?: boolean
  $isDragging?: boolean
}>`
  position: relative;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.white};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  outline: none;
  border: none;
  margin: 2px 0;

  border: 1px solid ${theme.colors.borderDefault};

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        background-color: ${theme.colors.actionDisabled};
        cursor: not-allowed;
      `
    }
  }}

  ${({ $fullWidth }) => {
    const width = $fullWidth ? '100%' : 'fit-content'
    return css`
      width: ${width};
    `
  }}

  ${({ $isDragging }) => {
    if ($isDragging) {
      return css`
        outline: 1px solid ${theme.colors.actionPrimaryHover};
        box-shadow:
          0px 143px 57px rgba(0, 0, 0, 0.01),
          0px 80px 48px rgba(0, 0, 0, 0.05),
          0px 36px 36px rgba(0, 0, 0, 0.09),
          0px 9px 20px rgba(0, 0, 0, 0.1);

        -webkit-box-shadow:
          0px 143px 57px rgba(0, 0, 0, 0.01),
          0px 80px 48px rgba(0, 0, 0, 0.05),
          0px 36px 36px rgba(0, 0, 0, 0.09),
          0px 9px 20px rgba(0, 0, 0, 0.1);

        -moz-box-shadow:
          0px 143px 57px rgba(0, 0, 0, 0.01),
          0px 80px 48px rgba(0, 0, 0, 0.05),
          0px 36px 36px rgba(0, 0, 0, 0.09),
          0px 9px 20px rgba(0, 0, 0, 0.1);

        -ms-box-shadow:
          0px 143px 57px rgba(0, 0, 0, 0.01),
          0px 80px 48px rgba(0, 0, 0, 0.05),
          0px 36px 36px rgba(0, 0, 0, 0.09),
          0px 9px 20px rgba(0, 0, 0, 0.1);
      `
    }
  }}

  /* &:hover {
    outline: 1px solid ${theme.colors.actionPrimaryHover};
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);
    -ms-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);
  } */

  &:active,
  :focus-within {
    outline: 1px solid ${theme.colors.actionPrimaryActive};
  }

  &:disabled {
    background-color: ${theme.colors.actionDisabled};
    cursor: not-allowed;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    outline: none;
  }
`

const StyledCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 8px;
`

const StyledCountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`

const StyledCount = styled.p`
  color: ${theme.colors.copy};
  font-size: ${theme.font.sizes.preTitle};
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

const StyledComponentName = styled.h3`
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: ${theme.font.sizes.body};
  font-weight: 380;
  line-height: 140%;
  width: 100%;
  max-width: 100%;
  font-family: 'rooney-sans';
`

interface ICardActivityProps {
  date: string
  icon?: IIconNames
}

const StyledActivity = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`

const StyledActivityDate = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copySubtle};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

const Activity = ({ icon = 'activity', date }: ICardActivityProps) => {
  return (
    <StyledActivity>
      <IconComponent icon={icon} iconSize="s" color={theme.colors.copySubtle} />
      <StyledActivityDate>{date}</StyledActivityDate>
    </StyledActivity>
  )
}

const Card = ({
  tasksCompleted: completedTasks,
  tasksTotal: totalTasks,
  phaseTitle,
  phaseType,
  fullWidth = true,
  componentName,
  disabled,
  bgColor,
  textColor,
  type = 'default',
  date = '',
  activityIcon = 'activity',
  isDragging = false
}: ICardProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClickOutside = (e: MouseEvent) => {
    if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
      return
    }
    setIsPopoverOpen(false)
  }

  return (
    <>
      <StyledCard
        $fullWidth={fullWidth}
        $disabled={disabled}
        $isDragging={isDragging}
      >
        <Popover
          clickOutsideCapture
          onClickOutside={handleClickOutside}
          isOpen={isPopoverOpen}
          positions={['bottom']}
          align="end"
          padding={16}
          content={() => (
            <StyledPopOverMenuContainer>
              <MenuHeader
                title="Options"
                handleClose={() => setIsPopoverOpen(false)}
              />
              <MenuItems showSlot1={false} showSlot2 type="default" />
            </StyledPopOverMenuContainer>
          )}
        >
          <Button
            ref={buttonRef}
            icon="more-horizontal"
            disabled={disabled}
            iconColor={theme.colors.actionPrimary}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen)
            }}
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px'
            }}
            iconOnly
            size="xs"
            variant="tertiary"
          />
        </Popover>
        <StyledCardDetails>
          <StyledCountContainer>
            <StyledCount>{completedTasks}</StyledCount>
            <StyledCount>/</StyledCount>
            <StyledCount>{totalTasks}</StyledCount>
          </StyledCountContainer>
          <StyledComponentName>{componentName}</StyledComponentName>
        </StyledCardDetails>
        {type === 'default' ? (
          <PhaseStatus
            phaseTitle={phaseTitle || 'No status'}
            phaseType={phaseType}
            bgColor={bgColor}
            textColor={textColor}
            isActive
            fullWidth={false}
          />
        ) : (
          <Activity date={date} icon={activityIcon} />
        )}
      </StyledCard>
    </>
  )
}

export default Card
