import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { theme } from "../../../styles";
import RowItem from "../RowItem/RowItem";

export type ISearchResultItem = {
  id: string | number;
  name: string;
  description?: string;
};

export type ISuggestions = {
  id: string | number;
  name: string;
  description?: string;
  type: "suggestions";
};
export type IRecentSearch = {
  id: string | number;
  name: string;
  description?: string;
  type: "recentSearch";
};

export const Container = styled.div<{ $width?: string }>`
  position: relative;
  width: ${({ $width }) => $width ?? "464px"};
`;

export const StyledDropdownContainer = styled.div<{
  $position: "top" | "bottom";
  $isOpen: boolean;
}>`
  position: relative;
  width: 100%;
  max-height: ${({ $isOpen }) => ($isOpen ? "300px" : "0")};
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  /* z-index: 1; */
  box-shadow: ${({ $position }) =>
    $position === "top"
      ? "0px -8px 8px 0px rgba(141, 128, 114, 0.21)"
      : "0px 8px 8px 0px rgba(141, 128, 114, 0.21)"};
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  flex-direction: column;
  flex-direction: ${({ $position }) =>
    $position === "top" ? "column-reverse" : "column"};
  border: 1px solid rgba(242, 236, 230, 1);

  /* ${({ $position }) =>
    $position === "top"
      ? `
        top: unset;
        bottom: 120%;
      `
      : `
        top: 120%;
        bottom: unset;
      `} */
`;

export const LoadingIndicator = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #999;
`;

export const SearchBarContainer = styled.div<{
  $position: string;
  $size: "s" | "m";
}>`
  width: 100%;
  ${({ $position }) =>
    $position === "top"
      ? `
        border-top: 1px solid rgba(242, 236, 230, 1);
      `
      : `
        border-bottom: 1px solid rgba(242, 236, 230, 1);
      `}
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  ${({ $size }) =>
    $size === "m"
      ? `
        padding: 16px;
      `
      : `
        padding: 8px 16px;
      `}
`;

export const SearchResultsSearch = styled.input<{ $size: "s" | "m" }>`
  width: 100%;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.copySubtle};
  font-family: "rooney-sans";

  overflow: auto;
  text-overflow: ellipsis;

  font-size: ${({ $size }) => ($size === "m" ? "20px" : "16px")};
  font-style: normal;
  font-weight: 340;
  line-height: 140%;

  &::placeholder {
    color: #999;
  }

  &:focus,
  &:active,
  &:focus-within {
    color: ${({ theme }) => theme.colors.copy};
    border-color: ${({ theme }) => theme.colors.actionPrimary};
  }
`;

export const SearchResultsList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 8px;
  margin: 0;
  overflow-y: auto;
  max-height: 700px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 430px) {
    gap: 24px;
    padding: 16px;
  }
`;

export const SearchResultTypeTitle = styled.p`
  color: ${theme.colors.actionTertiary};

  font-family: "rooney-sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const SearchResultsItem = styled.li<{ $selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: ${({ $selected, theme }) =>
    $selected ? `${theme.colors.actionPrimaryLightHover}` : "white"};
  &:hover {
    background-color: ${({ theme }) => theme.colors.actionPrimaryLightHover};
  }
`;

export const NoResults = styled.div`
  padding: 10px;
  text-align: center;
  color: #999;
`;

export const ButtonContainer = styled.div<{
  $position: "top" | "bottom";
  $size: "m" | "s";
}>`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-top: ${({ $position }) =>
    $position === "bottom" ? "1px solid rgba(242, 236, 230, 1)" : "none"};
  border-bottom: ${({ $position }) =>
    $position === "top" ? "1px solid rgba(242, 236, 230, 1)" : "none"};
`;

type ISearchResultsProps = {
  size?: "s" | "m";
  type?: "search" | "list";
  menuPosition: "top" | "bottom";
  isOpen: boolean;
  highlightedIndex: number | null;
  selectedItem: ISearchResultItem;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleScroll: (e: React.UIEvent<HTMLUListElement>) => void;
  handleItemClick: (item: ISearchResultItem) => void;
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  resultsSuggestions?: ISuggestions[] | [];
  resultsRecentSearch?: IRecentSearch[] | [];
};

const SearchResults: React.FC<ISearchResultsProps> = ({
  size = "m",
  menuPosition,
  isOpen,
  highlightedIndex,
  selectedItem,
  loading,
  handleScroll,
  handleItemClick,
  setHighlightedIndex,
  resultsRecentSearch = [],
  resultsSuggestions = [],
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <StyledDropdownContainer
      $position={menuPosition}
      $isOpen={isOpen}
      role="combobox"
      aria-expanded={isOpen}
      aria-haspopup="listbox"
      aria-owns="dropdown-list"
      aria-controls="dropdown-list"
    >
      <SearchResultsList
        id="dropdown-list"
        ref={listRef}
        role="listbox"
        onScroll={handleScroll}
        style={{ overflowY: "auto", height: "max-content" }}
        // aria-activedescendant={
        //   highlightedIndex !== null && filteredItems.length > 0
        //     ? `dropdown-item-${filteredItems[highlightedIndex].id}`
        //     : undefined
        // }
        // data-activedescendant={
        //   highlightedIndex !== null && filteredItems.length > 0
        //     ? `dropdown-item-${filteredItems[highlightedIndex].id}`
        //     : undefined
        // }
      >
        <div>
          <SearchResultTypeTitle>SUGGESTIONS</SearchResultTypeTitle>
          {resultsSuggestions.length > 0
            ? resultsSuggestions.map((item, index) => (
                <RowItem
                  showAction
                  handleAction={() => {}}
                  icon="search"
                  showIcon
                  key={item.id}
                  id={`dropdown-item-${item.id}`}
                  title={item.name ?? ""}
                  aria-selected={highlightedIndex === index}
                  size={size}
                  {...(item?.description
                    ? {
                        showDescription: true,
                        description: item.description,
                      }
                    : { showDescription: false })}
                  showActive={
                    selectedItem?.name === item.name ||
                    highlightedIndex === index
                  }
                  onClick={() => handleItemClick(item)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                />
              ))
            : null}
        </div>
        <div>
          <SearchResultTypeTitle>RECENT SEARCH</SearchResultTypeTitle>
          {resultsRecentSearch.length > 0
            ? resultsRecentSearch.map((item, index) => (
                <RowItem
                  showAction
                  handleAction={() => {}}
                  showIcon
                  icon="clock"
                  key={item.id}
                  id={`dropdown-item-${item.id}`}
                  title={item.name ?? ""}
                  aria-selected={highlightedIndex === index}
                  size={size}
                  {...(item?.description
                    ? {
                        showDescription: true,
                        description: item.description,
                      }
                    : { showDescription: false })}
                  showActive={
                    selectedItem?.name === item.name ||
                    highlightedIndex === index
                  }
                  onClick={() => handleItemClick(item)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                />
              ))
            : null}
        </div>
        {loading && <LoadingIndicator>Loading more...</LoadingIndicator>}
      </SearchResultsList>
    </StyledDropdownContainer>
  );
};

export default SearchResults;
