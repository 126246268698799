import { useMutation, useQueryClient } from '@tanstack/react-query'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { auth } from '../../../config/config'
import { toast } from 'react-toastify'
import {
  useWorkflow,
  WorkflowActionType
} from '../../../context/WorkflowActionProvider'
import { atlassianAuthCheckOptions } from './useCheckAtlassianAuth'

export const ADD_WORKFLOW = 'addWorkflow'

const fetcher = async (
  teamId: string,
  workflowId: string,
  props: WorkflowActionType
) => {
  const token = await auth.currentUser?.getIdToken()
  return httpRequest({
    url: `/workflows/${teamId}/${workflowId}`,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: props
  })(nest)
}

const useWorkflowUpdate = () => {
  const queryClient = useQueryClient()
  const { onClickTrigger } = useWorkflow()
  return useMutation({
    mutationKey: [ADD_WORKFLOW],
    mutationFn: async (
      props: WorkflowActionType & { teamId: string; workflowId: string }
    ) => {
      const { teamId, workflowId } = props
      return fetcher(teamId, workflowId, props)
    },
    onMutate: async (props: WorkflowActionType) => {
      onClickTrigger()
      return props
    },
    onError: (error) => {
      toast.error(error.message || 'An error occurred')
    },
    onSuccess: async (data: any) => {
      toast.success('Workflow Updated successfully')
      queryClient.refetchQueries(
        atlassianAuthCheckOptions({
          teamId: data.teamId,
          templateId: data.templateId
        })
      )
    }
  })
}

export default useWorkflowUpdate
