import { ref, remove, update } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { StyledConfirmType, StyledHeaderLabel } from '../../components/ui'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import { db, storage } from '../../config/config'
import { RootState } from '../../store'
import Spinner from '../../components/ui/Spinner'
import Label from '../../components/ui/Label/Label'
import {
  StyledTeamsAndUsersMainContainer,
  StyledTeamsAndUsersOptionsContainer,
  StyledTeamsAndUsersOptionTitle,
  StyledUserEmail,
  StyledUserItem,
  StyledUserItemsContainer
} from '../../components/screens/TeamAndUsers/TeamsAndUsers'
import TeamsAndUserBodyContainer from '../../components/screens/TeamAndUsers/TeamsAndUsersBodyContainer'
import {
  StyledAccountOptionTitle,
  StyledDangerTitle,
  StyledDangerZoneContainer,
  StyledDangerZoneDescription,
  StyledDangerZoneHeader,
  StyledInputLabel,
  StyledInputLabelContainer,
  StyledOptionInputsContainer,
  StyledTitle
} from '../../components/screens/MyAccount/MyAccount'
// import TopNavItem from '../../components/ui/TopNavItem/TopNavItem'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  MemberObj,
  setCurrentTeam,
  setTeams,
  setTeamState
} from '../../store/reducers/teamReducerSlice'
import TimeAgo from 'react-timeago'
import { theme } from '../../styles'
// import ProfileAvatar from '../../components/ui/Avatar/ProfileAvatar'
import ModalUpload from '../../components/ui/FileUpload/ModalUpload'
import { FileWithPreview } from '../../hooks/useImageUpload'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable
} from 'firebase/storage'
import {
  capitalFirstLetter,
  // formateSimpleCounting,
  isInvitationExpired
} from '../../utils/helpers'
import Avatar from '../../components/ui/Avatar/Avatar'
import ProfilePic from '../../components/ui/ProfilePic/ProfilePic'
// import Dropdown from '../../components/ui/DropDown/DropDown'
import {
  StyledMenuItems,
  StyledSlot
} from '../../components/ui/MenuItems/MenuItems'
import MenuItem from '../../components/ui/MenuItem/MenuItem'
// import IconComponent from '../../components/ui/IconComponent/IconComponent'
import PopOverMenu from '../../components/ui/PopOverMenu/PopOverMenu'
// import { color } from 'framer-motion'
import TeamInvitationModal from './TeamInvitationModal'
import Modal from '../../components/ui/Modal/Modal'
import { generateFigmaLinks } from '../../utils/generateFigmaLinks'
import useIsMobileDevice from '../../hooks/useIsMobileDevice'

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  padding: 4px 0 9px 0;
`

const MAX_MEMBERS_PER_TEAM = 4;

// ---------------------- Team page ----------------------
const Team: React.FC = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate()

  const [title, setTeamTitle] = useState('')
  const [teamDescription, setTeamDescription] = useState('')
  const [saving, setSaving] = useState(false)
  // Edit team icon
  const [teamIconModal, setTeamIconModal] = useState(false)
  const [uploadingTeamIcon, setUploadingTeamIcon] = useState(false)
  const [uploadingTeamIconError, setUploadingTeamIconError] = useState('')
  const [uploadPercent, setUploadPercent] = useState(0)
  const [uploadTask, setUploadTask] = useState<any>(null)
  //
  const [invitationModal, setInvitationModal] = useState<boolean>(false)
  const [leaveTeamModal, setLeaveTeamModal] = useState<boolean>(false)
  const [removeTeamModal, setRemoveTeamModal] = useState<boolean>(false)
  const [removeUserModal, setRemoveUserModal] = useState<{
    isOpen: boolean
    user: string | null | undefined
  }>({ isOpen: false, user: null })
  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false)
  const [leavingTeam, setLeavingTeam] = useState<boolean>(false)
  const [removingTeam, setRemovingTeam] = useState<boolean>(false)

  const {
    currentTeam,
    teams,
    isLoading: isTeamLoading
  } = useSelector((store: RootState) => store.team)
  const { user: authUser } = useSelector((store: RootState) => store.auth)
  const { data: templates } = useSelector((store: RootState) => store.template)

  const findMember = currentTeam?.members?.find((v) => v?.id === authUser?.id)
  // const isAdmin = false
  const isAdmin = findMember?.role === 'owner' || findMember?.role === 'admin'

  const isMobile = useIsMobileDevice(768)

  // update team Name & description
  const updatedTeam = (e: React.FormEvent) => {
    e.preventDefault()
    if (saving || isTeamLoading || !isAdmin || !currentTeam?.id) return
    if (!title?.trim()) {
      toast.info("What's a team without a name?")
      return
    }
    setSaving(true)
    update(ref(db, `teams/${currentTeam?.id}`), {
      title,
      description: teamDescription || ''
    })
      .then(() => {
        // toast.success('This team name sounds awesome!')
        toast.success('Changes Saved!')
        setSaving(false)
        dispatch(
          setCurrentTeam({
            data: {
              ...currentTeam,
              title,
              description: teamDescription || ''
            }
          })
        )
      })
      .catch(() => {
        setSaving(false)
      })
  }

  // filter list
  const filteredList = () => {
    const users = Object?.values(currentTeam?.members || [])
    const owner = users?.find((usr) => usr?.email === authUser?.email)
    const otherMembers = users?.filter((usr) => usr?.email !== authUser?.email)
    return [owner, ...otherMembers]
    // return isAdmin ? users : users?.filter((usr) => usr?.id === authUser?.id || usr?.invitationStatus !== "accepted")
  }

  // cancel uploading/changing team icon
  const cancelUpload = () => {
    if (uploadTask) {
      uploadTask.cancel()
    }
  }

  // update team icon on firebase storage cloud
  const updateTeamIcon = (acceptedFiles: FileWithPreview[]) => {
    const file = acceptedFiles[0]
    const storageREF = storageRef(
      storage,
      `images/team/profile/${currentTeam?.id}`
    )
    setUploadingTeamIcon(true)
    const task = uploadBytesResumable(storageREF, file)
    setUploadTask(task)
    task.resume()
    task.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot?.bytesTransferred / snapshot?.totalBytes) * 100
        setUploadPercent(progress)
      },
      (error) => {
        console.log(error?.message)
        if (error?.code === 'storage/canceled') {
          setUploadingTeamIconError('')
        } else {
          setUploadingTeamIconError(error?.message || 'Something went wrong.')
        }
        setUploadingTeamIcon(false)
        setUploadTask(null)
        setUploadPercent(0)
      },
      () => {
        getDownloadURL(task?.snapshot?.ref).then((downloadURL) => {
          setUploadTask(null)
          setUploadPercent(100)
          // Update in database
          update(ref(db, `teams/${currentTeam?.id}`), {
            icon: downloadURL || currentTeam?.icon
          })
            .then(() => {
              dispatch(
                setTeamState({
                  teams: teams?.map((team) => {
                    if (team?.id === currentTeam?.id) {
                      return {
                        ...team,
                        icon: downloadURL || currentTeam?.icon
                      }
                    }
                    return team
                  }),
                  currentTeam: {
                    ...currentTeam,
                    icon: downloadURL || currentTeam?.icon
                  },
                  isLoading: false
                })
              )
              setUploadingTeamIconError('')
              setTeamIconModal(false)
              toast.success('New Profile looks awesome!')
            })
            .catch((error) => {
              console.log('Error while updating team icon', error?.message)
              setUploadingTeamIconError('Something went wrong.')
            })
            .finally(() => {
              setUploadTask(null)
              setUploadingTeamIcon(false)
              setUploadPercent(0)
            })
        })
      }
    )
  }

  // update usr role
  const updateUserRole = (userInfo: MemberObj, role: 'editor' | 'admin') => {
    if (!userInfo?.id && !userInfo?.email) return
    if (!currentTeam?.id) {
      toast.error("Sorry, we couldn't find the team you're looking for.")
      return
    }
    if (!isAdmin) {
      toast.error("You don't have permission to update team members.")
      return
    }
    const updatedList = Object.values(currentTeam?.members || [])?.map(
      (member) => {
        if (
          (member?.id?.length > 0 &&
            userInfo?.id?.length > 0 &&
            member?.id === userInfo?.id) ||
          (member?.email?.length > 0 &&
            userInfo?.email?.length > 0 &&
            member?.email === userInfo?.email)
        ) {
          return {
            ...member,
            role: role
          }
        }
        return member
      }
    )
    dispatch(
      setCurrentTeam({
        data: {
          ...currentTeam,
          members: updatedList
        }
      })
    )
    dispatch(
      setTeams({
        data: Object.values(teams || [])?.map((team) => {
          if (team?.id === currentTeam?.id) {
            return {
              ...team,
              members: updatedList
            }
          }
          return team
        }),
        isLoading: false
      })
    )
    toast.success('User role updated successfully.')
    document.body.click()

    update(ref(db, `teams/${currentTeam?.id}`), {
      members: updatedList
    })
      .then(async () => {
        if (
          updatedList?.find((v) => v?.email === userInfo?.email)?.role ===
          'admin'
        ) {
          const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/update-user-role`
          const data = {
            updatedUser: {
              email: userInfo?.email
            },
            teamName: currentTeam?.title,
            memberKey: 'email',
            user: {
              name: userInfo?.email
            }
          }
          const response = await fetch(path, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          if (response.ok) {
            console.log('Email sent to the user.')
          } else {
            console.log('Error while sending email to the user.')
          }
        }
      })
      .catch((error) => {
        console.log('error while updating user role', error)
        // toast.error('Oops, something went wrong.')
      })
  }

  // remove from the team
  const removeFromTeam = async (type: 'removeUser' | 'leaveTeam') => {
    if (isRemovingUser || leavingTeam) return
    if (!currentTeam?.id) {
      toast.error(
        'Something wrong, please try again or contact our support team.'
      )
      return
    }
    if (type === 'removeUser' && (!removeUserModal?.user || !isAdmin)) {
      toast.error('Sorry, only admin can update team members.')
      return
    }
    if (type === 'leaveTeam' && currentTeam?.id === authUser?.id) {
      toast.error('Sorry, you can not leave your own team.')
      return
    }
    const setLoadingAction =
      type === 'removeUser' ? setIsRemovingUser : setLeavingTeam

    setLoadingAction(true)
    const updatedList =
      type === 'removeUser'
        ? Object.values(currentTeam?.members || [])?.filter(
            (v) => v?.email !== removeUserModal?.user
          )
        : Object.values(currentTeam?.members || []).filter(
            (member) => member?.email !== authUser?.email
          )

    update(ref(db, `teams/${currentTeam?.id}`), {
      members: updatedList
    })
      .then(async () => {
        if (type === 'leaveTeam') {
          await remove(
            ref(db, `users/${authUser?.id}/teamsJoined/${currentTeam?.id}`)
          )
          localStorage.setItem('checklist_team_id', authUser?.id || '')
          const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/leave-team`
          const data = {
            teamMembers: currentTeam?.members,
            authUserId: authUser?.id,
            teamName: currentTeam?.title,
            leavingUserEmail: authUser?.email
          }
          await fetch(path, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          setLeaveTeamModal(false)
          setLoadingAction(false)
          toast.success('You left the team.')
          window.location.replace('/dashboard/team')
          return
        } else {
          // if there's no id it means the user was not exist, 
          // because we can invite any email so it means that 
          // email might not exist (user doesn't joined Compfeed yet)
          const idOfTheUser = currentTeam?.members?.find(
            (v) => v?.email === removeUserModal?.user
          )?.id
          if (idOfTheUser) {
            // await remove(
            //   ref(db, `users/${idOfTheUser}/teamsJoined/${currentTeam?.id}`)
            // )
            const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/remove-from-team`
            const data = {
              teamMembers: currentTeam?.members,
              memberId: idOfTheUser,
              teamName: currentTeam?.title,
              leavingUserEmail: removeUserModal?.user
            }
            await fetch(path, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            })
          }
            dispatch(
              setCurrentTeam({
                data: {
                  ...currentTeam,
                  members: updatedList
                }
              })
            )
            dispatch(
              setTeams({
                data: Object.values(teams || [])?.map((team) => {
                  if (team?.id === currentTeam?.id) {
                    return {
                      ...team,
                      members: updatedList
                    }
                  }
                  return team
                }),
                isLoading: false
              })
            )
            setLoadingAction(false)
            setRemoveUserModal({
              isOpen: false,
              user: ''
            })
            toast.success('We removed this user from the team on your command.')
          
        }
      })
      .catch((error) => {
        console.log('error while removing a user from the team', error)
        toast.error('Oops, something went wrong.')
        setLoadingAction(false)
      })
  }

  // Delete a team
  const removeTeam = async () => {
    if (removingTeam || currentTeam?.id === authUser?.id) return
    if (!currentTeam?.id) {
      toast.error(
        'Something wrong, please try again or contact our support team.'
      )
      return
    }
    setRemovingTeam(true)
    const links = await generateFigmaLinks(db, currentTeam?.id)

    remove(ref(db, `teams/${currentTeam?.id}`))
      .then(async () => {
        const templatesList = templates || []
        templatesList?.forEach(async (template) => {
          if (template?.teamId === currentTeam?.id) {
            await remove(ref(db, `phases/${template?.id}`))
          }
        })
        await remove(ref(db, `templates/${currentTeam?.id}`))
        await remove(ref(db, `components/${currentTeam?.id}`))
        await remove(ref(db, `deletedComponents/${currentTeam?.id}`))
        await remove(
          ref(db, `users/${authUser?.id}/teamsJoined/${currentTeam?.id}`)
        )
        // send email to all the team admins
        const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/team-remove`
        const data = {
          teamAdmins: currentTeam?.members?.filter(
            (v) => v?.role === 'owner' || v?.role === 'admin'
          ),
          teamName: currentTeam?.title,
          figmaLinks: links
        }
        const response = await fetch(path, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        if (response) {
          dispatch(
            setTeams({
              data: Object.values(teams || [])?.filter(
                (team) => team?.id !== currentTeam?.id
              ),
              isLoading: false
            })
          )
          const defaultTeam = teams?.find((team) => team?.id === authUser?.id)
          dispatch(
            setCurrentTeam({
              data: defaultTeam
            })
          )
          localStorage.setItem('checklist_team_id', authUser?.id || '')
          setRemovingTeam(false)
          setRemoveTeamModal(false)
          toast.success('Team removed successfully.')
          setTimeout(() => {
            window.location.replace('/dashboard/team')
          }, 700)
        }
      })
      .catch((error) => {
        console.log('error while removing a team', error)
        toast.error('Oops, something went wrong.')
        setRemovingTeam(false)
      })
  }

  // is limit reached for the team members
  const isMemberLimitReached = filteredList()?.length >= MAX_MEMBERS_PER_TEAM;

  // // navigate to user profile
  // const navigateToUserProfile = (idOrEmail?: string) => {
  //   if (!isAdmin || !idOrEmail) return
  //   // navigate(`/dashboard/user/${idOrEmail}`)
  // }

  // fetch team data initially
  useEffect(() => {
    if (isTeamLoading) return
    setTeamTitle(currentTeam?.title || '')
    setTeamDescription(currentTeam?.description || '')
  }, [currentTeam, isTeamLoading])

  // JSX
  return (
    <StyledTeamsAndUsersMainContainer>
      <TeamsAndUserBodyContainer>
        <div>
          <StyledTitle>My Team</StyledTitle>
        </div>
        <StyledTeamsAndUsersOptionsContainer>
          <StyledAccountOptionTitle>Team Information</StyledAccountOptionTitle>
          <StyledRow
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left',
              gap: '16px'
            }}
          >
            <ProfilePic
              src={currentTeam?.icon || ''}
              size={isMobile ? 's' : 'm'}
              buttonAction={() => {
                if (uploadingTeamIcon) return
                setTeamIconModal((prev) => !prev)
              }}
              disabled={uploadingTeamIcon}
              showButton={isAdmin}
              type={currentTeam?.icon ? 'photo' : 'default'}
            />
            {!isAdmin ? (
              <div>
                <StyledHeaderLabel style={{ textAlign: 'left' }}>
                  {currentTeam?.title}
                </StyledHeaderLabel>
                <StyledConfirmType style={{ textAlign: 'left' }}>
                  {currentTeam?.description}
                </StyledConfirmType>
              </div>
            ) : null}
          </StyledRow>
          {!isAdmin ? null : (
            <ModalUpload
              title="Upload Profile"
              btnLabel="Update profile"
              isLoading={uploadingTeamIcon}
              isDisabled={uploadingTeamIcon}
              modalOpen={teamIconModal}
              setModalOpen={setTeamIconModal}
              onSubmit={updateTeamIcon}
              cancelUpload={cancelUpload}
              canCancel={uploadTask && uploadPercent < 100 ? true : false}
              uploadPercent={uploadPercent}
              error={uploadingTeamIconError}
            />
          )}
          {/* ------------ Team name & desc form -------------- */}
          {!isAdmin ? null : (
            <form onSubmit={updatedTeam} style={{ width: '100%' }}>
              <StyledOptionInputsContainer>
                <StyledInputLabelContainer>
                  <StyledInputLabel htmlFor="title">Team name</StyledInputLabel>
                  <InputField
                    disabled={saving || isTeamLoading || !isAdmin}
                    required
                    fullWidth
                    label="Team name"
                    placeholder="Team name"
                    name="title"
                    type="text"
                    value={title}
                    readOnly={!isAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      !isAdmin
                        ? null
                        : setTeamTitle(
                            e.target?.value?.length < 35
                              ? e.target?.value
                              : title
                          )
                    }
                  />
                </StyledInputLabelContainer>
                <StyledInputLabelContainer>
                  <StyledInputLabel htmlFor="description">
                    Description
                  </StyledInputLabel>
                  <InputField
                    disabled={saving || isTeamLoading || !isAdmin}
                    required
                    fullWidth
                    label="Description"
                    placeholder="Description"
                    name="description"
                    type="text"
                    value={teamDescription}
                    readOnly={!isAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      !isAdmin
                        ? null
                        : setTeamDescription(
                            e.target?.value?.length < 35
                              ? e.target?.value
                              : teamDescription
                          )
                    }
                  />
                </StyledInputLabelContainer>
                {isAdmin ? (
                  <Button
                    disabled={
                      saving ||
                      isTeamLoading ||
                      (title === currentTeam?.title &&
                        teamDescription === currentTeam?.description) ||
                      !title?.trim()
                    }
                    type="submit"
                    label={saving ? 'Updating...' : 'Update'}
                    variant="primary"
                    title={isTeamLoading ? 'Fetching team...' : ''}
                  />
                ) : null}
              </StyledOptionInputsContainer>
            </form>
          )}
        </StyledTeamsAndUsersOptionsContainer>
        <StyledTeamsAndUsersOptionsContainer>
          <StyledRow>
            <StyledTeamsAndUsersOptionTitle>
              Team Members ({filteredList()?.length})
            </StyledTeamsAndUsersOptionTitle>
            {!isAdmin ? null : (
              <>
                <Button
                  onClick={() => isMemberLimitReached ? null : setInvitationModal(true)}
                  icon="send"
                  variant="secondary"
                  label="INVITE MEMBER"
                  disabled={isMemberLimitReached}
                />
                <TeamInvitationModal
                  modalOpen={invitationModal}
                  setModalOpen={setInvitationModal}
                />
              </>
            )}
          </StyledRow>
          {/* ------------ Users list in the team ----------------- */}
          <StyledUserItemsContainer
          // style={{gap: "8px"}}
          >
            {isTeamLoading ? (
              <div className="mx-auto">
                {' '}
                <Spinner type={'spinner'} />
              </div>
            ) : filteredList()?.length > 0 ? (
              filteredList()?.map((member, i) => {
                // console.log("member", member);
                const role = member?.role?.toLocaleLowerCase() || 'editor'
                const userItSelf = member?.email === authUser?.email
                if (member?.pendingInvite && !isAdmin) return null
                const isLinkExpired = isInvitationExpired(
                  member?.invitationDate || ''
                )

                return (
                  <StyledUserItem
                    style={{
                      cursor: 'default',
                      flexWrap: 'wrap'
                    }}
                    lastItem={filteredList()?.length === i + 1}
                  >
                    <Avatar src={member?.profileImg} size={40} />
                    <div>
                      <StyledUserEmail style={{ minWidth: '110px' }}>
                        {member?.email || 'no email'}
                      </StyledUserEmail>
                      {member?.pendingInvite ? (
                        <div style={{ color: theme.colors.copySubtle }}>
                          Invited <TimeAgo live date={member?.invitationDate} />
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="flex"
                      style={{
                        flexWrap: 'wrap',
                        gap: '16px',
                        alignItems: 'center',
                        marginLeft: 'auto'
                      }}
                    >
                      {member?.pendingInvite ? (
                        <>
                          {isLinkExpired ? (
                            <Label text={'Invitation Expired'} signal="error" />
                          ) : (
                            <Label text={'Pending'} signal="warning" />
                          )}
                        </>
                      ) : member?.invitationStatus === 'declined' ? (
                        <Label text={'Declined'} signal="error" />
                      ) : null}

                      {userItSelf ||
                      !isAdmin ||
                      member?.id === currentTeam?.id ||
                      member?.role === 'owner' ? (
                        <StyledHeaderLabel
                          style={{ fontSize: '18px', padding: '5px 12px' }}
                        >
                          {capitalFirstLetter(role)}
                        </StyledHeaderLabel>
                      ) : (
                        <PopOverMenu
                          customOpener={({ ref, handleOpen }) => {
                            return (
                              <StyledSlot
                                style={{
                                  padding: '8px 0 8px 12px',
                                  flexDirection: 'row',
                                  // gap: '16px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  userSelect: 'none'
                                }}
                                onClick={() => handleOpen(true)}
                                role="button"
                              >
                                <StyledHeaderLabel style={{ fontSize: '18px' }}>
                                  {capitalFirstLetter(role)}
                                </StyledHeaderLabel>
                                <Button
                                  iconOnly
                                  color="primary"
                                  icon="chevron-down"
                                  variant="tertiary"
                                  size="xs"
                                  ref={ref}
                                  noBorder
                                />
                              </StyledSlot>
                            )
                          }}
                          customContentWrapper
                          menuItems={
                            <>
                              <StyledMenuItems
                                style={{
                                  backgroundColor: theme?.colors?.white,
                                  boxShadow: '0 4px 32px #00000029',
                                  width: '176px',
                                  gap: '0',
                                  overflow: 'hidden'
                                }}
                              >
                                <StyledSlot
                                  $hasBorderBottom
                                  style={{
                                    paddingBottom: '0'
                                  }}
                                  onClick={() =>
                                    updateUserRole(member as MemberObj, 'admin')
                                  }
                                >
                                  <MenuItem
                                    title="Admin"
                                    type="primary"
                                    onClick={() => {}}
                                    closeMenu={() => {}}
                                    customStyles={{
                                      borderRadius: '0',
                                      padding: '16px'
                                    }}
                                    iconPosition="right"
                                    icon={
                                      member?.role === 'admin' ? 'check' : ''
                                    }
                                  />
                                </StyledSlot>
                                <StyledSlot
                                  $hasBorderBottom={false}
                                  style={{
                                    paddingBottom: '0'
                                  }}
                                  onClick={() =>
                                    updateUserRole(
                                      member as MemberObj,
                                      'editor'
                                    )
                                  }
                                >
                                  <MenuItem
                                    title="Editor"
                                    type="primary"
                                    onClick={() => {}}
                                    closeMenu={() => {}}
                                    customStyles={{
                                      borderRadius: '0',
                                      padding: '16px'
                                    }}
                                    icon={
                                      member?.role === 'editor' ? 'check' : ''
                                    }
                                    iconPosition="right"
                                  />
                                </StyledSlot>
                              </StyledMenuItems>
                            </>
                          }
                          zIndex="1"
                        />
                      )}
                      {!userItSelf &&
                        isAdmin &&
                        member?.id !== currentTeam?.id &&
                        member?.role !== 'owner' && (
                          <Button
                            iconOnly
                            color="danger"
                            icon="trash"
                            variant="danger"
                            size="xs"
                            noBorder
                            onClick={() =>
                              setRemoveUserModal({
                                isOpen: true,
                                user: member?.email
                              })
                            }
                          />
                        )}
                      {/* <Label text={user?.role || 'editor'} type="disabled" /> */}

                      {/* {isAdmin ? (
                        <Button
                          iconOnly
                          icon="chevron-right"
                          variant="tertiary"
                        />
                      ) : null} */}
                    </div>
                  </StyledUserItem>
                )
              })
            ) : (
              <StyledHeaderLabel>Oh, no users found.</StyledHeaderLabel>
            )}
          </StyledUserItemsContainer>
          {/* {isAdmin ? (
            <NavLink to="/dashboard/team/user/new">
              <Button label="Add user" variant="secondary" icon="plus" />
            </NavLink>
          ) : null} */}
          {findMember?.role !== 'owner' &&
          findMember?.id !== currentTeam?.id &&
          authUser?.id !== currentTeam?.id ? (
            <>
              <StyledDangerZoneContainer>
                <StyledDangerZoneHeader>
                  <StyledDangerTitle>Danger Zone</StyledDangerTitle>
                  <StyledDangerZoneDescription>
                    Are you sure, you want to leave this team? This action is
                    irreversible and will permanently erase all your data from
                    this team. If you're sure, tap ‘leave Team’ below.
                  </StyledDangerZoneDescription>
                </StyledDangerZoneHeader>
                <Button
                  disabled={leavingTeam}
                  onClick={() => setLeaveTeamModal(true)}
                  label="LEAVE TEAM"
                  variant="danger"
                />
              </StyledDangerZoneContainer>

              {/* LEAVE TEAM MODAL */}
              <DangerModal
                isOpen={leaveTeamModal}
                onClose={() => setLeaveTeamModal(false)}
                isDisabled={leavingTeam}
                onAction={() => removeFromTeam('leaveTeam')}
                title="Leave Team"
                msg="Are you sure, you want to leave this team? This action is irreversible and will permanently erase all your data from this team."
                confirmBtnText={leavingTeam ? 'LEAVING' : 'LEAVE TEAM'}
              />
            </>
          ) : authUser?.id === currentTeam?.id ||
            findMember?.role !== 'owner' ? null : (
            <>
              <StyledDangerZoneContainer>
                <StyledDangerZoneHeader>
                  <StyledDangerTitle>Danger Zone</StyledDangerTitle>
                  <StyledDangerZoneDescription>
                    Are you sure, you want to delete your team? This action is
                    irreversible and will permanently erase all your data. If
                    you're sure, tap 'Remove Team’ below.
                  </StyledDangerZoneDescription>
                </StyledDangerZoneHeader>
                <Button
                  disabled={removingTeam}
                  onClick={() => setRemoveTeamModal(true)}
                  label="REMOVE TEAM"
                  variant="danger"
                />
              </StyledDangerZoneContainer>

              {/* LEAVE TEAM MODAL */}
              <DangerModal
                isOpen={removeTeamModal}
                onClose={() => setRemoveTeamModal(false)}
                isDisabled={removingTeam}
                onAction={removeTeam}
                title="Remove Team"
                msg="Are you sure, you want to delete your team? This action is irreversible and will permanently erase all your data."
                confirmBtnText="REMOVE TEAM"
              />
            </>
          )}
          {/* REMOVE USER MODAL */}
          <DangerModal
            isOpen={removeUserModal?.isOpen}
            onClose={() =>
              setRemoveUserModal({
                isOpen: false,
                user: ''
              })
            }
            isDisabled={isRemovingUser}
            onAction={() => removeFromTeam('removeUser')}
            title="Remove member"
            msg={`Are you sure? You want to remove ${removeUserModal?.user} from your team. Remember you can’t undo this action.`}
            confirmBtnText={isRemovingUser ? 'REMOVING' : 'REMOVE'}
          />
        </StyledTeamsAndUsersOptionsContainer>
      </TeamsAndUserBodyContainer>
    </StyledTeamsAndUsersMainContainer>
  )
}

interface DangerModalProps {
  isOpen?: boolean
  onClose?: () => void
  isDisabled?: boolean
  onAction?: () => void
  title?: string
  msg?: string
  confirmBtnText?: string
}

export const DangerModal: React.FC<DangerModalProps> = ({
  isOpen = false,
  onClose = () => {},
  isDisabled = false,
  onAction = () => {},
  title = '',
  msg = '',
  confirmBtnText = 'CONFIRM'
}) => {
  if (!isOpen) return null
  return (
    <Modal
      showModal={isOpen}
      title={title}
      buttonVariant1="danger"
      buttonLabel1={confirmBtnText}
      buttonVariant2="tertiary"
      buttonLabel2="CANCEL"
      signalIconType="warning"
      buttonOnClick1={onAction}
      buttonOnClick2={() => onClose()}
      buttonDisabled1={isDisabled}
      buttonDisabled2={isDisabled}
      onclose={() => {
        if (isDisabled) return
        onClose()
      }}
      isLoading={isDisabled}
      signal="alert-triangle"
      slotContent={
        <StyledDangerZoneDescription>{msg}</StyledDangerZoneDescription>
      }
    />
  )
}

export default Team
