import { HTMLAttributes } from "react";
import { css, styled } from "styled-components";
import { theme } from "../../../styles";
import Button from "../Button/Button";

interface ILabelProps extends HTMLAttributes<HTMLDivElement> {
  type?: 'default' | 'filter'
  signal?:
    | 'callout'
    | 'information'
    | 'success'
    | 'warning'
    | 'error'
    | 'disabled'
  text?: string
  fullWidth?: boolean
  noEllipses?: boolean
  removeBtn?: boolean
  size?: 's' | 'm'
  onClickRemove?: () => void
  disabled?: boolean
}

const StyledLabelBackground = styled.div<
  Omit<
    ILabelProps & { $fullWidth?: boolean; $removeBtn?: boolean },
    "text" | "fullWidth" | "noEllipses"
  > & {
    $signal?:
      | "callout"
      | "information"
      | "success"
      | "warning"
      | "error"
      | "disabled";
  }
>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  ${({ theme, $fullWidth, $signal, type, $removeBtn, size }) => {
    const _width = $fullWidth ? "100%" : "fit-content";
    const _textAlign = $fullWidth ? "left" : "center";
    const _bg = $signal && theme.colors.label[$signal].background;
    // const _height = type === "default" ? "38px" : "30px";
    const _borderRadius = type === "default" ? "8px" : "32px";
    let _padding = "";

    if (!$removeBtn) {
      if (size === "s" && type === "default") _padding = "8px 12px";
      if (size === "m" && type === "default") _padding = "16px";
      if (size === "s" && type === "filter") _padding = "4px 16px";
      if (size === "m" && type === "filter") _padding = "16px 24px";
    } else {
      if (size === "s" && type === "default") _padding = "8px 8px 8px 12px";
      if (size === "m" && type === "default") _padding = "16px";
      if (size === "s" && type === "filter") _padding = "4px 8px 4px 16px";
      if (size === "m" && type === "filter") _padding = " 16px 16px 16px 24px";
    }

    return css`
      padding: ${_padding};
      border-radius: ${_borderRadius};
      background: ${_bg};
      width: ${_width};
      text-align: ${_textAlign};
    `;
  }}
`;

const StyledLabelText = styled.p<
  Omit<
    ILabelProps & {
      $size: "s" | "m";
      $fullWidth?: boolean;
      $noEllipses?: boolean;
      $signal:
        | "callout"
        | "information"
        | "success"
        | "warning"
        | "error"
        | "disabled";
    },
    "text" | "fullWidth" | "noEllipses"
  >
>`
  display: inline-block;
  flex: 1 0 0;
  font-size: ${({ $size }) => ($size === "s" ? "16px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ $noEllipses }) => ($noEllipses ? "nowrap" : "normal")};

  ${({ theme, $signal }) => {
    const _color = $signal && theme.colors.label[$signal].labelText;
    return css`
      color: ${_color};
    `;
  }}
`;

const Label = ({
  type = 'default',
  signal = 'callout',
  text = 'label',
  fullWidth = false,
  noEllipses = false,
  removeBtn = false,
  size = 'm',
  onClickRemove = () => {},
  disabled = false,
  ...props
}: ILabelProps) => {
  return (
    <StyledLabelBackground
      $removeBtn={removeBtn}
      $signal={signal}
      type={type}
      $fullWidth={fullWidth}
      size={size}
      {...props}
    >
      <StyledLabelText
        $size={size}
        $signal={signal}
        type={type}
        $fullWidth={fullWidth}
        $noEllipses={noEllipses}
      >
        {text}
      </StyledLabelText>
      {removeBtn && !disabled && (
        <Button
          iconOnly
          icon="x"
          size="xs"
          variant="tertiary"
          iconColor={theme.colors.label[signal].labelText}
          onClick={onClickRemove}
        />
      )}
    </StyledLabelBackground>
  )
}

export default Label;