import styled from "styled-components";
import { theme } from "../../../styles";
import Button from "../Button/Button";
import CheckMark from "../CheckMark/CheckMark";
import { IIconNames } from "../IconComponent/IconComponent";

interface IRowItemBase {
  showBorder?: boolean;
  showSelected?: boolean;
  size?: "s" | "m";
  title: string;
  width?: "full" | number;
  disabled?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  showActive?: boolean;
  id?: string;
}

type IRowItemProps = IRowItemBase &
  (
    | {
        showDescription: true;
        description: string;
      }
    | {
        showDescription?: false;
        description?: never;
      }
  ) &
  (
    | {
        showAction: true;
        handleAction: () => void;
        actionIcon?: IIconNames;
      }
    | {
        actionIcon?: IIconNames;
        showAction?: false;
        handleAction?: never;
      }
  ) &
  (
    | {
        showIcon: true;
        icon: IIconNames;
      }
    | {
        showIcon?: false;
        icon?: never;
      }
  ) &
  (
    | {
        showCheckBox: true;
        checked: boolean;
        onCheckedChange: (checked: boolean) => void;
      }
    | {
        showCheckBox?: false;
        checked?: false;
        onCheckedChange?: never;
      }
  );

const StyledRowItemContainer = styled.div<{
  width: string;
  $size: "s" | "m";
  $showBorder?: boolean;
  $disabled?: boolean;
  $showDescription: boolean;
  $showActive?: boolean;
}>`
  display: flex;
  width: ${({ width }) => width};
  cursor: pointer;
  border-radius: 4px;
  align-items: ${({ $showDescription }) =>
    $showDescription ? "flex-start" : "center"};
  gap: 8px;
  ${({ $size }) => ($size === "s" ? "padding: 8px 16px ;" : "padding: 16px;")};

  ${({ $showBorder }) =>
    $showBorder && `border-bottom: 1px solid ${theme.colors.borderDefault};`}

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor:not-allowed;
    `}

  ${({ $showActive }) =>
    $showActive &&
    `
      &{
        background-color: ${theme.colors.actionPrimaryLightActive};
      }
    `}
  ${({ $disabled }) =>
    !$disabled &&
    `
       &:hover {
        background-color: ${theme.colors.actionPrimaryLightHover};
      }
      &:active {
        background-color: ${theme.colors.actionPrimaryLightActive};
      }
    `}
`;

const StyledRowItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const StyledRowItemTitle = styled.p<{ $size: "s" | "m" }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;

  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;

  font-family: "rooney-sans";
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  ${({ $size }) => ($size === "s" ? "font-size: 16px;" : "font-size: 20px;")}
`;

const StyledRowItemDescription = styled.p`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.copy};
  text-align: left;
  font-family: "rooney-sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`;

const RowItem = ({
  showAction,
  showBorder,
  showCheckBox,
  checked = false,
  onCheckedChange = (checked: boolean) => {
    console.log(checked);
  },
  showDescription = false,
  showIcon,
  showSelected,
  size = "s",
  icon = "bell",
  width = "full",
  title,
  description,
  handleAction,
  disabled = false,
  onClick = () => {},
  onMouseEnter = () => {},
  showActive = false,
  id = "row-item",
  actionIcon = "x",
}: IRowItemProps) => {
  return (
    <StyledRowItemContainer
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      $showDescription={showDescription}
      $size={size}
      $disabled={disabled}
      width={width === 'full' ? '100%' : `${width}px`}
      $showBorder={showBorder}
      $showActive={showActive}
    >
      {showIcon && (
        <Button
          iconOnly
          icon={icon}
          size="xs"
          variant="tertiary"
          iconColor={theme.colors.copy}
        />
      )}

      {showCheckBox && (
        <CheckMark
          // size={size}
          disabled={disabled}
          onClick={() => onCheckedChange(checked)}
          checked={checked}
        />
      )}
      <StyledRowItemDetails>
        <StyledRowItemTitle $size={size}>{title}</StyledRowItemTitle>
        {showDescription && (
          <StyledRowItemDescription>{description}</StyledRowItemDescription>
        )}
      </StyledRowItemDetails>

      {showSelected && (
        <Button
          iconOnly
          icon="check"
          size="xs"
          iconColor={theme.colors.actionPrimary}
          variant="tertiary"
        />
      )}
      {showAction && (
        <Button
          size="xs"
          iconOnly
          iconPosition="after"
          iconColor={theme.colors.actionPrimary}
          icon={actionIcon}
          variant="tertiary"
          onClick={() => handleAction()}
        />
      )}
    </StyledRowItemContainer>
  )
};

export default RowItem;
