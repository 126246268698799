import { useEffect, useState } from 'react'
import type { KanbanColumn } from '..'
import useIsMobileDevice from '../../../../hooks/useIsMobileDevice'
import {
  StyledKanbanColumn,
  StyledKanbanColumnContent,
  StyledMobileKanbanCardContainer
} from '../kanban.styles'
import KanbanColumnHeader from './KanbanColumnHeader'
import Card from '../../Card/Card'
import { phaseTypeColorMap } from '../../../../styles/theme'
import { Droppable } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

const KanbanColumn = ({
  columnId,
  column
}: {
  columnId: string
  column: KanbanColumn
}) => {
  const isMobile = useIsMobileDevice()
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    setIsCollapsed(isMobile)
  }, [isMobile])

  if (isMobile) {
    return (
      <StyledKanbanColumn>
        <KanbanColumnHeader
          title={column.title}
          phaseType={column.phaseType}
          isActive={!!column.phaseType}
          count={column.items.length}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <StyledMobileKanbanCardContainer>
          {!isCollapsed &&
            column.items.map((item) => (
              <Card
                key={item.id}
                componentName={item.componentName}
                phaseType={item.phaseType as keyof typeof phaseTypeColorMap}
                tasksCompleted={item.tasksCompleted}
                tasksTotal={item.tasksTotal}
                type="kanban"
                date={item.date}
              />
            ))}
        </StyledMobileKanbanCardContainer>
      </StyledKanbanColumn>
    )
  }

  return (
    <StyledKanbanColumn>
      <Droppable droppableId={columnId} type="TASK">
        {(provided: any, snapshot: any) => (
          <StyledKanbanColumnContent
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
            ref={(el) => {
              provided.innerRef(el)
            }}
          >
            <KanbanColumnHeader
              title={column.title}
              phaseType={column.phaseType}
              isActive={!!column.phaseType}
              count={column.items.length}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />

            {!isCollapsed &&
              column.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided: any, cardSnapShot: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Card
                        isDragging={cardSnapShot.isDragging}
                        componentName={item.componentName}
                        phaseType={
                          item.phaseType as keyof typeof phaseTypeColorMap
                        }
                        tasksCompleted={item.tasksCompleted}
                        tasksTotal={item.tasksTotal}
                        type="kanban"
                        date={item.date}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </StyledKanbanColumnContent>
        )}
      </Droppable>
    </StyledKanbanColumn>
  )
}

export default KanbanColumn
