import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { auth } from '../../config/config'
import { setCurrentTeam, setTeams } from './teamReducerSlice'
import { nest } from '../../utils/axios'
import { AxiosError } from 'axios'

export interface User {
  email: string
  id: string
  displayName: string
  profileImg: string
  status: string
  teamsJoined: { [key: string]: string }
  integrations?: { [key: string]: { [key: string]: any } }
  jira: {
    access_token: string
    refresh_token: string
    expires_in: Date
    cloud: JiraCloudSite
    user: JiraUser
  }
}

interface JiraCloudSite {
  id: string
  url: string
  name: string
  avatarUrl: string
  scope?: string[]
}


interface JiraUser {
  accountId: string
  accountType: string
  active: boolean
  avatarUrls: any
  displayName: string
  key: string
  name: string
  self: string
}



interface AuthState {
  user: User | null
  isLoading: boolean
  isAuthenticated: boolean
}
export const checkAuth = createAsyncThunk(
  'userAuthSlice/getAuth',
  async (_, { dispatch, rejectWithValue }) => {
    return new Promise<User>((resolve, reject) => {
      auth.onAuthStateChanged(async (userSession) => {
        if (!userSession) {
          reject('User not authenticated')
          resetAuth()
          return
        }

        const accessToken = await userSession.getIdToken()

        const storageData: string | null =
          localStorage.getItem('checklist_team_id')
        const currentTeamId: string = storageData || userSession.uid || ''

        if (!storageData) {
          localStorage.setItem('checklist_team_id', currentTeamId)
        }

        try {
          const { data } = await nest.get(`/users/${userSession.uid}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              x_team_id: currentTeamId
            }
          })

          if (data?.statusCode === 401) {
            reject('User not authenticated')
            resetAuth()
            return
          }

          const { user: userData, teams, defaultTeam } = data

          const currentTeam =
            teams.find((team: any) => team.id === currentTeamId) ||
            teams.find((team: any) => team.id === userSession.uid)

          // Dispatch teams to Redux
          dispatch(
            setTeams({
              isLoading: false,
              data: teams.map((team: any) => ({
                ...team,
                members: Object.values(team.members || [])
              }))
            })
          )

          // no teams are available, set user personal team
          if (!currentTeam) {
            localStorage.setItem('checklist_team_id', userSession.uid)
          }

          const hisTeam = currentTeam || defaultTeam

          // Dispatch default team
          dispatch(
            setCurrentTeam({
              data: {
                ...hisTeam,
                members: Object.values(hisTeam.members || [])
              }
            })
          )

          return resolve(userData)
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 401) {
              reject('User not authenticated')
              resetAuth()
            }
          }

          rejectWithValue('Error fetching user data')
        }
      })
    })
  }
)

//
const initialState: AuthState = {
  user: null,
  isLoading: true,
  isAuthenticated: false
}

//
export const authReducerSlice = createSlice({
  name: 'userAuthSlice',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.user = action.payload.user
      state.isAuthenticated = action.payload.isAuthenticated
      state.isLoading = action.payload.isLoading
    },
    setProfileData: (state, action) => {
      state.user = action.payload.user
    },
    resetAuth: (state) => {
      state.user = null
      state.isAuthenticated = false
      state.isLoading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true
        state.isAuthenticated = false
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.user = action.payload
        state.isAuthenticated = true
        state.isLoading = false
      })
      .addCase(checkAuth.rejected, (state) => {
        state.user = null
        state.isLoading = false
        state.isAuthenticated = false
      })
  }
})

export const { setAuth, setProfileData, resetAuth } = authReducerSlice.actions

export default authReducerSlice.reducer