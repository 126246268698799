import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Outlet, useLocation } from 'react-router'
import { signOut, User as AuthUser } from 'firebase/auth'
import { equalTo, onValue, orderByChild, query, ref } from 'firebase/database'
import { auth, db } from '../../config/config'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { StyledLayout } from '../../components/Layout/BaseLayout.styled'
import {
  StyledSideNavResponsiveContainer,
  StyledTopNavResponsiveContainer
} from '../../components/Layout/Layout'
import AccountMenuMobile from '../../components/ui/AccountMenuMobile/AccountMenuMobile'
import MobileMenuDrawer from '../../components/ui/MobileMenuDrawer/MobileMenuDrawer'
import Nav from '../../components/ui/Nav/Nav'
import TopNav from '../../components/ui/TopNav/TopNav'
import { DrawerContext } from '../../context/DrawerContext'
import { AppDispatch, RootState } from '../../store'
import { resetAuth, User } from '../../store/reducers/authReducerSlice'
import {
  resetComponents,
  setComponentSlice,
  setRecentlyViewedComponentIds
} from '../../store/reducers/componentsReducerSlice'
import {
  fetchAndSetTeamsRealTime,
  resetAllTeams,
  TeamObj
} from '../../store/reducers/teamReducerSlice'
import {
  resetTemplates,
  setTemplateSlice,
  TemplateObj,
  setRecentlyViewedTemplateIds
} from '../../store/reducers/templateReducerSlice'
import { PhaseObj } from '../../store/reducers/phaseReducerSlice'
import {
  resetPhases,
  setPhasesSlice
} from '../../store/reducers/phaseReducerSlice'
import Modal from '../../components/ui/Modal/Modal'
import {
  StyledCreateAccountContainer,
  StyledInputContainer
} from '../../components/ui'
import Label from '../../components/ui/Label/Label'
import TeamListItem from '../../components/ui/TeamListItem/TeamListItem'
import { capitalFirstLetter } from '../../utils/helpers'
import CreateTeamModal from '../Team/CreateTeamModal'
import { useSearchParams } from 'react-router-dom'
import { resetProjects, setProjectSlice, setRecentlyViewedProjectIds } from '../../store/reducers/projectsReducerSlice'
import { ConditionallyRender } from '../../utils/ConditionallyRender'

// ---------------------- Dashboard layout wrapper --------------------------------
const DashboardLayout: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams, setSearchParams] = useSearchParams()
  const sectionRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isFullScreen
  } = useContext(DrawerContext)
  const {
    teams,
    currentTeam,
    isLoading: isLoadingTeams
  } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)
  const { isLoading: isLoadingTemplates, data: templateList } = useSelector(
    (store: RootState) => store.template
  )
  const [logoutConfirmModal, setLogoutConfirmModal] = useState<boolean>(false)
  const [switchTeamModal, setSwitchTeamModal] = useState<boolean>(false)
  const [createTeamModal, setCreateTeamModal] = useState<boolean>(false)
  //
  const isShowWidgetModal = searchParams.get('modal') === 'widget-success'

  // logout user
  const logout = () => {
    // localStorage.removeItem("sessionId");
    signOut(auth)
      .then(() => {
        toast.info('Logged out successfully!')
        localStorage.removeItem('widgetLoginEmail')
        localStorage.removeItem('loginEmail')
        dispatch(resetAuth())
        dispatch(resetTemplates())
        dispatch(resetAllTeams())
        dispatch(resetComponents())
        dispatch(resetPhases())
      })
      .catch((err) => {
        toast.error("Couldn't log out")
        console.log('Error: ', err)
      })
  }

  // get user role in team
  const getUserRoleInTeam = (team: TeamObj) => {
    if (!team?.members) return 'Editor'
    const member = team?.members?.find(
      (member) => member?.email === auth?.currentUser?.email
    )
    return member?.role || 'Editor'
  }

  //
  const ownTeamsCount = useMemo(() => {
    return (
      teams?.reduce((count, team) => {
        const isOwner = team?.members?.some((member) => {
          // Check for ownership by either matching IDs or, if missing, by matching emails
          return (
            ((member?.id && user?.id && member?.id === user?.id) ||
              (!member?.id &&
                member?.email &&
                user?.email &&
                member?.email === user?.email)) &&
            member?.role === 'owner'
          )
        })
        return isOwner ? count + 1 : count
      }, 0) ?? 0
    )
  }, [teams, user?.id, user?.email])

  const isLimitReached = ownTeamsCount >= 2

  // scroll top inside the tab, when a tab changes
  useLayoutEffect(() => {
    if (sectionRef?.current) {
      sectionRef.current.scrollTop = 0
    }
  }, [sectionRef, pathname])

  //
  useEffect(() => {
    dispatch(
      fetchAndSetTeamsRealTime({
        userObj: user as User,
        session: auth.currentUser as AuthUser
      })
    )
  }, [])

  // fetch templates of the current team/company
  useEffect(() => {
    const selectedTeamId = currentTeam?.id
    if (!selectedTeamId || isLoadingTeams) return
    // templates
    onValue(ref(db, `templates/${selectedTeamId}`), (snap) => {
      if (snap.exists()) {
        const data: TemplateObj[] = Object.values(snap.val())

        dispatch(
          setTemplateSlice({
            data: data.map((item) => ({
              ...item
            })),
            isLoading: false
          })
        )
      } else {
        dispatch(resetTemplates())
      }
    })
  }, [currentTeam, isLoadingTeams, dispatch])

  //fetch all phases of current team
  useEffect(() => {
    if (!currentTeam || isLoadingTeams || isLoadingTemplates || !templateList)
      return

    // Temporary object to accumulate all phases by their IDs
    const accumulatedPhases: { [key: string]: PhaseObj } = {}

    // Create a cleanup function to remove listeners
    const listeners = templateList.map((template) => {
      const templateRef = ref(db, `phases/${template?.id}`)
      const listener = onValue(templateRef, (snap) => {
        if (snap.exists()) {
          const data: PhaseObj[] = Object.values(snap.val())
          const phases = data.map((item) => ({
            ...item,
            tasks: Object.values(item?.tasks || [])
          }))

          // Update the accumulated phases object with new data
          phases.forEach((phase) => {
            accumulatedPhases[phase.id] = phase // Use phaseId as the key
          })

          // Update store with the accumulated phases
          dispatch(
            setPhasesSlice({
              data: Object.values(accumulatedPhases),
              isLoading: false
            })
          )
        }
      })

      return listener // Return the listener for cleanup
    })

    // Cleanup function to remove listeners when the component unmounts or dependencies change
    return () => {
      listeners.forEach((listener) => listener()) // Remove each listener
    }
  }, [currentTeam, isLoadingTeams, templateList, isLoadingTemplates, dispatch])

  // fetch components of the current team/company
  useEffect(() => {
    // components
    if (!currentTeam?.id) {
      dispatch(resetComponents())
      return
    }
    // Query to fetch components with type "component"
    const componentsQuery = query(
      ref(db, `components/${currentTeam?.id}`),
      orderByChild('type'),
      equalTo('component')
    )
    onValue(componentsQuery, (snap) => {
      if (snap.exists()) {
        const data = Object.values(snap.val()) as Record<
          string,
          Record<string, object>
        >[]
        const formattedData = Object.values(data || {})?.map((v) => v)
        // const combinedArray = data.reduce(
        //   (accumulator: object[], fileData: Record<string, object>) => {
        //     return accumulator.concat(Object.values(fileData))
        //   },
        //   []
        // )
        dispatch(
          setComponentSlice({
            data: formattedData
          })
        )
      } else {
        dispatch(
          setComponentSlice({
            data: [],
            isLoading: false
          })
        )
      }
    })
  }, [teams, isLoadingTeams, currentTeam, dispatch])

  // fetch projects of the current team/company
  useEffect(() => {
    if (!currentTeam?.id) {
      dispatch(resetProjects())
      return
    }
    // Query to fetch components with type "project"
    const componentsQuery = query(
      ref(db, `components/${currentTeam?.id}`),
      orderByChild('type'),
      equalTo('project')
    )
    onValue(componentsQuery, (snap) => {
      if (snap.exists()) {
        const data = Object.values(snap.val()) as Record<
          string,
          Record<string, object>
        >[]
        const formattedData = Object.values(data || {})
          ?.filter((pr) => !pr?.isArchived)
          ?.map((v) => v)
        dispatch(
          setProjectSlice({
            data: formattedData
          })
        )
      } else {
        dispatch(
          setProjectSlice({
            data: [],
            isLoading: false
          })
        )
      }
    })
  }, [teams, isLoadingTeams, currentTeam, dispatch])

  // fetch recent component ids from local storage
  useEffect(() => {
    const recentComponentIds =
      JSON.parse(localStorage.getItem('recent_component_ids') ?? '[]') || []
    dispatch(setRecentlyViewedComponentIds(recentComponentIds))
  }, [dispatch])

  // fetch recent projects ids from local storage
  useEffect(() => {
    const recentProjectIds =
      JSON.parse(localStorage.getItem('recent_projects_ids') ?? '[]') || []
    dispatch(setRecentlyViewedProjectIds(recentProjectIds))
  }, [dispatch])

  // fetch recent template ids from local storage
  useEffect(() => {
    const recentTemplateIds =
      JSON.parse(localStorage.getItem('recent_template_ids') ?? '[]') || []
    dispatch(setRecentlyViewedTemplateIds(recentTemplateIds))
  }, [dispatch])

  // JSX
  return (
    <>
      <StyledLayout className="root-layout">
        <ConditionallyRender
          condition={!isFullScreen}
          show={
            <>
              <StyledTopNavResponsiveContainer>
                <TopNav />
              </StyledTopNavResponsiveContainer>
              <StyledSideNavResponsiveContainer>
                <Nav
                  logout={logout}
                  setLogoutConfirmModal={setLogoutConfirmModal}
                  setSwitchTeamModal={setSwitchTeamModal}
                />
              </StyledSideNavResponsiveContainer>
            </>
          }
        />
        <Outlet />
        <MobileMenuDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          logout={logout}
        />
      </StyledLayout>
      {!logoutConfirmModal && !switchTeamModal && (
        <AccountMenuMobile
          isOpen={isAccountMenuOpen}
          setIsOpen={setIsAccountMenuOpen}
          logout={logout}
          setLogoutConfirmModal={setLogoutConfirmModal}
          setSwitchTeamModal={setSwitchTeamModal}
        />
      )}
      <Modal
        showModal={logoutConfirmModal}
        setShowModal={setLogoutConfirmModal}
        title="Logout"
        signal="log-out"
        slotContent={
          <div style={{ padding: '10px 35px 20px 0' }}>
            <p>Are you sure you want to logout?</p>
          </div>
        }
        buttonLabel1="Cancel"
        buttonLabel2="Confirm"
        buttonOnClick1={() => setLogoutConfirmModal(false)}
        buttonOnClick2={logout}
      />
      <Modal
        showModal={switchTeamModal}
        setShowModal={setSwitchTeamModal}
        title="Switch Team"
        signal="repeat"
        slotContent={
          <StyledCreateAccountContainer
            style={{ maxHeight: '390px', overflow: 'auto' }}
          >
            <TeamListItem
              description={currentTeam?.description}
              name={currentTeam?.title}
              userLabel={capitalFirstLetter(
                getUserRoleInTeam(currentTeam as TeamObj)
              )}
              showCheck={true}
              profilePicProps={{
                src: currentTeam?.icon,
                type: currentTeam?.icon ? 'photo' : 'default'
              }}
              // active={true}
            />
            <div>
              <Label
                text="Click the team you want to set the current active team."
                signal="information"
                fullWidth
              />
            </div>
            <StyledInputContainer>
              {[...teams]
                ?.filter((tm) => tm?.id !== currentTeam?.id)
                ?.map((team, i) => {
                  //
                  return (
                    <TeamListItem
                      key={i}
                      description={team?.description}
                      name={team?.title}
                      userLabel={capitalFirstLetter(
                        getUserRoleInTeam(team as TeamObj)
                      )}
                      showCheck={false}
                      profilePicProps={{
                        src: team?.icon,
                        type: team?.icon ? 'photo' : 'default'
                      }}
                      clickAction={() => {
                        const id = team?.id
                        if (!id) return
                        localStorage.setItem('checklist_team_id', id)
                        toast.success('Team selected.')
                        window.location.replace('/dashboard/team')
                      }}
                    />
                  )
                })}
            </StyledInputContainer>
          </StyledCreateAccountContainer>
        }
        buttonLabel1={
          isLimitReached
            ? 'You have reached the limit of 2'
            : 'CREATE NEW TEAM ' + ownTeamsCount + '/2'
        }
        buttonOnClick1={() => {
          if (isLimitReached) return
          setSwitchTeamModal(false)
          setCreateTeamModal(true)
        }}
        buttonDisabled1={isLimitReached}
        buttonVariant1="primary"
      />
      <CreateTeamModal
        isOpen={createTeamModal}
        onClose={() => setCreateTeamModal(false)}
      />
      <Modal
        showModal={isShowWidgetModal}
        title="Figma is now connected"
        signal="check-circle"
        onclose={() => setSearchParams('')}
        buttonLabel1="OPEN FIGMA"
        buttonLabel2="VIEW DASHBOARD"
        buttonVariant1="primary"
        buttonVariant2="tertiary"
        buttonOnClick1={() => {
          window.location.href = 'figma://open'
          setSearchParams('')
        }}
        signalIconType="success"
        buttonOnClick2={() => setSearchParams('')}
        slotContent={
          <div style={{ padding: '10px 35px 20px 0' }}>
            <p>
              You have successfully connected Figma to Compfeed. You can
              continue using the widget in Figma.
            </p>
          </div>
        }
      />
    </>
  )
}

export default DashboardLayout
