import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

interface IDrawerContextInitialState {
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  isAccountMenuOpen: boolean
  setIsAccountMenuOpen: Dispatch<SetStateAction<boolean>>
  isFullScreen: boolean
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
}

export const DrawerContext = createContext({} as IDrawerContextInitialState)

const DrawerContextProvider = ({ children }: { children: ReactNode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false)

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        setIsDrawerOpen,
        isAccountMenuOpen,
        setIsAccountMenuOpen,
        isFullScreen,
        setIsFullScreen
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerContextProvider;
