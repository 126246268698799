import { useFormContext } from 'react-hook-form'
import Label from '../../Label/Label'
import Select from '../../Select/Select'
import {
  useWorkflow,
  WorkflowActionType
} from '../../../../context/WorkflowActionProvider'
import { UserRole } from '../../../../types/user'
import { ConditionallyRender } from '../../../../utils/ConditionallyRender'

const SelectRule = ({
  options,
  name,
  placeholder
}: {
  options: { label: string; value: string }[]
  name:
    | 'jira.when'
    | 'jira.then'
    | 'jira.jiraProject'
    | 'jira.syncWithJira'
    | 'mail.when'
    | 'mail.then'
  placeholder?: string
}) => {
  const { device, userRole, details } = useWorkflow()
  const { watch, setValue } = useFormContext<WorkflowActionType>()
  const isMobile = device === 'mobile'

  const values = watch(name) || []

  const onChange = (e: any) => {
    const selectedValue = e.target.value

    const isDataExist = values?.find(
      (data: { value: string; label: string }) => data.value === selectedValue
    )
    if (!isDataExist) {
      const option = options.find((option) => option.value === selectedValue)
      setValue(name, [
        { label: option?.label || '', value: selectedValue },
        ...values
      ])
    }
  }

  const onRemove = (value: string) => {
    setValue(
      name,
      values.filter(
        (val: { value: string; label: string }) => val.value !== value
      )
    )
  }

  return (
    <>
      {values.map((val: { label: string; value: string }) => {
        return (
          <Label
            key={val.label}
            type="default"
            text={val.label}
            size={isMobile ? 's' : 'm'}
            removeBtn
            fullWidth
            onClickRemove={() => onRemove(val.value)}
            disabled={userRole === UserRole.EDITOR}
          />
        )
      })}

      <ConditionallyRender
        condition={userRole !== UserRole.EDITOR}
        show={
          <Select
            fullWidth
            onChange={onChange}
            value={values[0]?.value || ''}
            placeholder={placeholder}
            options={options}
            disabled={userRole === UserRole.EDITOR}
          />
        }
      />
    </>
  )
}

export default SelectRule
