import styled from "styled-components"

import Tab from "./Tab"
import { WORKFLOW_OPTIONS } from "../../../../constant"
import { useWorkflow } from '../../../../context/WorkflowActionProvider'

const StyledTabNav = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledTabIconTitle = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '26px')};
  font-weight: 380;
  line-height: 36.4px;
  text-transform: capitalize;
`

const TabNav = () => {
  const { showWorkflowButton } = useWorkflow()

  const filteredOptions = WORKFLOW_OPTIONS.filter(() =>
    showWorkflowButton ? true : false
  )

  return (
    <StyledTabNav>
      {filteredOptions.map(({ title, desc, icon }) => (
        <Tab key={title} title={title} desc={desc} icon={icon} />
      ))}
    </StyledTabNav>
  )
}

export default TabNav