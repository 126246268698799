import axios from 'axios'
import axiosRetry, {
  exponentialDelay,
  isNetworkError,
  isRetryableError
} from 'axios-retry'

const nestBaseUrl = `${import.meta.env.VITE_APP_NEST_APP_BASE_URL}`

export const nest = axios.create({
  baseURL: nestBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosRetry(nest, {
  retries: 1,
  retryCondition: (error) => {
    return isNetworkError(error) || isRetryableError(error)
  },
  retryDelay: exponentialDelay,
  shouldResetTimeout: true,
  onRetry: (retryCount) => {
    console.log(`Retry attempt #${retryCount} for ${nestBaseUrl}`)
  }
})

export const standardAxiosRequest = axios.create()

// add retry functionality to the axios instance
axiosRetry(standardAxiosRequest, {
  retries: 3,
  retryDelay: exponentialDelay, // exponential back-off delay between retries
  retryCondition: (error) => {
    // only retry if the error is a network error or a 5xx server error
    return isNetworkError(error) || isRetryableError(error)
  }
})
