import { useEffect, useRef, useState } from 'react'
import { db } from '../../../config/config'
import { useSelector, useDispatch } from 'react-redux'
import { ref, update } from 'firebase/database'
import { setPhasesData } from '../../../store/reducers/phaseReducerSlice'
import { RootState } from '../../../store'
import { theme } from '../../../styles'
import { toast } from 'react-toastify'
import { getTimeStamp } from '../../../utils/helpers'
import {
  StyledEditTaskContainer,
  StyledEditTaskContent,
  StyledEditTaskHeader,
  StyledEditTaskTitle
} from './StyledEditTask'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import Button from '../Button/Button'
import InputField from '../InputField/InputField'
import ExpandCollapse from '../ExpandCollapse/ExpandCollapse'
import { StyledRow } from '../../../pages/Team'
import { useUpdatePhasesJira } from '../../../hooks/api'

interface IEditTaskProps extends React.HTMLAttributes<HTMLDivElement> {
  collapsed: boolean
  disabled?: boolean
  templateId?: string
  phaseId?: string
  taskId?: string
  isAnyTaskDragging?: boolean
  taskTitle?: string
  setIsEditModeOn?: React.Dispatch<React.SetStateAction<boolean>>
}

const EditTask = ({
  collapsed = false,
  disabled,
  templateId,
  phaseId,
  isAnyTaskDragging,
  taskTitle,
  taskId,
  setIsEditModeOn,
  ...props
}: IEditTaskProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const isMobile = useIsMobileDevice()
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [isUpdating, setIsUpdating] = useState(false)

  const [formData, setFormData] = useState({
    title: taskTitle
  })

  const { user } = useSelector((store: RootState) => store.auth)

  // if any task component is dragging, collapse this component
  useEffect(() => {
    if (isAnyTaskDragging) setIsCollapsed(true)
  }, [isAnyTaskDragging])

  // if the component is disabled, it should be collapsed
  useEffect(() => {
    if (disabled) setIsCollapsed(true)
  }, [disabled])

  // focus input if it's collapsed
  useEffect(() => {
    if (!disabled && !isCollapsed && inputRef) inputRef?.current?.focus()
  }, [disabled, isCollapsed, inputRef])

  const { data: allPhases } = useSelector((store: RootState) => store.phase)
  const { currentTeam } = useSelector((store: RootState) => store.team)

  const { mutateAsync, isPending } = useUpdatePhasesJira({
    currentTeam,
    templateId
  })

  // update the task
  const handleUpdateTask = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isUpdating) return

    if (!formData?.title?.trim()) {
      toast.error(`Please provide task title.`)
      return
    }
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team id not found.')
      return
    }
    setIsUpdating(true)
    const timestamp = getTimeStamp()

    try {
      await update(ref(db, `phases/${templateId}/${phaseId}/tasks/${taskId}`), {
        ...formData,
        updatedAt: timestamp
      })

      if (user?.jira?.access_token) {
        await mutateAsync()
      }

      dispatch(
        setPhasesData({
          data: allPhases?.map((phase) => {
            if (phase?.id === phaseId) {
              const prevTask: any = phase?.tasks || []
              return {
                ...phase,
                tasks: prevTask?.map((task: any) => {
                  if (task?.id === taskId) {
                    return {
                      ...task,
                      ...formData,
                      updatedAt: timestamp
                    }
                  }
                  return task
                })
              }
            }
            return phase
          })
        })
      )
      toast.success('Task updated successfully.')
      setIsEditModeOn && setIsEditModeOn(false)
    } catch (error) {
      console.log('Error editing task', error)
      toast.error('Something went wrong.')
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleUpdateTask}>
      <StyledEditTaskContainer {...props} disabled={disabled}>
        <StyledEditTaskContent>
          <StyledEditTaskHeader
            onClick={() => {
              !disabled && setIsCollapsed(!isCollapsed)
            }}
          >
            <StyledEditTaskTitle disabled={disabled}>
              Edit Task
            </StyledEditTaskTitle>
            <ExpandCollapse
              collapse={isCollapsed}
              type="border"
              color={theme.colors.actionPrimary}
              disabled={disabled}
            />
          </StyledEditTaskHeader>
          <StyledEditTaskContent collapsed={isCollapsed}>
            <span style={{ backgroundColor: 'white', width: '100%' }}>
              <InputField
                label="Task"
                fullWidth
                disabled={disabled || isUpdating}
                required
                placeholder="maximum of 120 characters"
                name="title"
                type="text"
                value={formData.title}
                autoFocus
                inputRef={inputRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    title:
                      e.target?.value?.length < 120
                        ? e.target?.value
                        : formData?.title
                  })
                }
              />
            </span>
          </StyledEditTaskContent>
        </StyledEditTaskContent>
        {!isCollapsed && (
          <StyledRow style={{ justifyContent: 'flex-start' }}>
            <Button
              label="CANCEL"
              variant="secondary"
              type="button"
              disabled={isUpdating}
              size={isMobile ? 'small' : 'medium'}
              onClick={() => {
                if (setIsEditModeOn) {
                  setIsEditModeOn(false)
                }
                setIsCollapsed(false)
                setIsUpdating(false)
              }}
            />
            <Button
              label={isPending ? 'UPDATING...' : 'UPDATE CHANGE'}
              variant="primary"
              type="submit"
              disabled={isUpdating}
              size={isMobile ? 'small' : 'medium'}
            />
          </StyledRow>
        )}
      </StyledEditTaskContainer>
    </form>
  )
}

export default EditTask
