import React, { useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Button from '../Button/Button'
import { theme } from '../../../styles'
import RowItem from '../RowItem/RowItem'
import { IDropdownItem } from './DropDown'

export const Container = styled.div<{ $width?: string }>`
  position: relative;
  width: ${({ $width }) => $width ?? 'fit-content'};
`

export const StyledDropdownContainer = styled.div<{
  $position: 'top' | 'bottom'
  $isOpen: boolean
  $width?: string
}>`
  position: absolute;
  min-width: 120px;
  width: ${({ $width }) => $width ?? '100%'};
  max-height: ${({ $isOpen }) => ($isOpen ? '300px' : '0')};
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  z-index: 1;
  box-shadow: ${({ $position }) =>
    $position === 'top'
      ? '0px -8px 8px 0px rgba(141, 128, 114, 0.21)'
      : '0px 8px 8px 0px rgba(141, 128, 114, 0.21)'};
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  flex-direction: ${({ $position }) =>
    $position === 'top' ? 'column-reverse' : 'column'};
  border: 1px solid rgba(242, 236, 230, 1);

  ${({ $position }) =>
    $position === 'top'
      ? `
        top: unset;
        bottom: 120%;
      `
      : `
        top: 120%;
        bottom: unset;
      `}
`

export const LoadingIndicator = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #999;
`

export const SearchBarContainer = styled.div<{
  $position: string
  $size: 's' | 'm'
}>`
  width: 100%;
  ${({ $position }) =>
    $position === 'top'
      ? `
        border-top: 1px solid rgba(242, 236, 230, 1);
      `
      : `
        border-bottom: 1px solid rgba(242, 236, 230, 1);
      `}
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  ${({ $size }) =>
    $size === 'm'
      ? `
        padding: 16px;
      `
      : `
        padding: 8px 16px;
      `}
`

export const DropdownMenuSearch = styled.input<{ $size: 's' | 'm' }>`
  width: 100%;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.copySubtle};
  font-family: 'rooney-sans';

  overflow: auto;
  text-overflow: ellipsis;

  font-size: ${({ $size }) => ($size === 'm' ? '20px' : '16px')};
  font-style: normal;
  font-weight: 340;
  line-height: 140%;

  &::placeholder {
    color: #999;
  }

  &:focus,
  &:active,
  &:focus-within {
    color: ${({ theme }) => theme.colors.copy};
    border-color: ${({ theme }) => theme.colors.actionPrimary};
  }
`

export const DropdownList = styled.ul<{ $showBorderRadius?: boolean }>`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 999;
  overflow-y: auto;
  max-height: 700px;
  border-radius: ${({ $showBorderRadius }) =>
    $showBorderRadius ? '8px' : '0'};
`

export const DropdownMenuItem = styled.li<{ $selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: ${({ $selected, theme }) =>
    $selected ? `${theme.colors.actionPrimaryLightHover}` : 'white'};
  &:hover {
    background-color: ${({ theme }) => theme.colors.actionPrimaryLightHover};
  }
`

export const NoResults = styled.div`
  padding: 10px;
  text-align: center;
  color: #999;
`

export const ButtonContainer = styled.div<{
  $position: 'top' | 'bottom'
  $size: 'm' | 's'
}>`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-top: ${({ $position }) =>
    $position === 'bottom' ? '1px solid rgba(242, 236, 230, 1)' : 'none'};
  border-bottom: ${({ $position }) =>
    $position === 'top' ? '1px solid rgba(242, 236, 230, 1)' : 'none'};
`

type IDropdownMenuProps = {
  size?: 's' | 'm'
  type?: 'search' | 'list'
  menuPosition: 'top' | 'bottom'
  isOpen: boolean
  placeholder: string
  filteredItems: IDropdownItem[]
  highlightedIndex: number | null
  selectedItem?: IDropdownItem
  loading: boolean
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleScroll: (e: React.UIEvent<HTMLUListElement>) => void
  handleItemClick: (item: IDropdownItem) => void
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number | null>>
  searchDisabledPlaceholder?: string
  searchDisabled?: boolean
  showAction?: boolean
  actionLabel?: string
  handleAction?: () => void
  filterListContent?: React.ReactNode
  width?: string
  showBorderRadius?: boolean
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  size = 'm',
  type = 'search',
  menuPosition,
  isOpen,
  placeholder,
  filteredItems,
  highlightedIndex,
  selectedItem,
  loading,
  handleInputChange,
  handleScroll,
  handleItemClick,
  setHighlightedIndex,
  searchDisabled = false,
  searchDisabledPlaceholder = 'No projects available',
  showAction = true,
  actionLabel,
  handleAction = () => {},
  filterListContent,
  width = 'fit-content',
  showBorderRadius = true
}) => {
  const listRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (typeof window === 'undefined') return

      if (!isOpen) return

      const upIndex =
        highlightedIndex === null || highlightedIndex === 0
          ? filteredItems.length - 1
          : highlightedIndex - 1

      const downIndex =
        highlightedIndex === null ||
        highlightedIndex === filteredItems.length - 1
          ? 0
          : highlightedIndex + 1

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          setHighlightedIndex(downIndex)
          listRef.current
            ?.querySelector(`#dropdown-item-${downIndex}`)
            ?.scrollIntoView({ behavior: 'smooth' })
          break
        case 'ArrowUp':
          e.preventDefault()
          setHighlightedIndex(upIndex)
          listRef.current
            ?.querySelector(`#dropdown-item-${upIndex}`)
            ?.scrollIntoView({ behavior: 'smooth' })
          break
        case 'Enter':
          if (highlightedIndex !== null) {
            e.preventDefault()
            handleItemClick(filteredItems[highlightedIndex])
          }
          break
        case 'Escape':
          e.preventDefault()
          setHighlightedIndex(null)
          break
        default:
          break
      }
    },
    [
      isOpen,
      filteredItems,
      highlightedIndex,
      handleItemClick,
      setHighlightedIndex
    ]
  )

  return (
    <StyledDropdownContainer
      $position={menuPosition}
      $isOpen={isOpen}
      role="combobox"
      aria-expanded={isOpen}
      aria-haspopup="listbox"
      aria-owns="dropdown-list"
      aria-controls="dropdown-list"
      $width={width}
    >
      {type === 'search' && (
        <SearchBarContainer $position={menuPosition} $size={size}>
          <DropdownMenuSearch
            ref={inputRef}
            placeholder={
              searchDisabled ? searchDisabledPlaceholder : placeholder
            }
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            role="searchbox"
            aria-autocomplete="list"
            aria-controls="dropdown-list"
            $size={size}
            disabled={searchDisabled}
          />
          <Button
            icon="search"
            size="xs"
            iconOnly
            label="Search"
            variant="tertiary"
            iconColor={
              searchDisabled
                ? theme.colors.actionPrimaryLight
                : theme.colors.actionPrimary
            }
            onClick={() => {}}
            disabled={searchDisabled}
          />
        </SearchBarContainer>
      )}
      <DropdownList
        $showBorderRadius={showBorderRadius}
        id="dropdown-list"
        ref={listRef}
        role="listbox"
        onScroll={handleScroll}
        style={{ overflowY: 'auto', height: 'max-content' }}
        aria-activedescendant={
          highlightedIndex !== null && filteredItems.length > 0
            ? `dropdown-item-${filteredItems[highlightedIndex].id}`
            : undefined
        }
        data-activedescendant={
          highlightedIndex !== null && filteredItems.length > 0
            ? `dropdown-item-${filteredItems[highlightedIndex].id}`
            : undefined
        }
      >
        {filterListContent ? (
          filterListContent
        ) : filteredItems.length > 0 ? (
          filteredItems.map((item, index) => (
            <RowItem
              key={item.id}
              id={`dropdown-item-${item.id}`}
              title={item.name ?? ''}
              aria-selected={highlightedIndex === index}
              size={size}
              {...(item?.description
                ? {
                    showDescription: true,
                    description: item.description
                  }
                : { showDescription: false })}
              showActive={
                selectedItem?.name === item.name || highlightedIndex === index
              }
              onClick={() => handleItemClick(item)}
              onMouseEnter={() => setHighlightedIndex(index)}
            />
          ))
        ) : (
          <NoResults>No results found</NoResults>
        )}
        {loading && <LoadingIndicator>Loading more...</LoadingIndicator>}
      </DropdownList>
      {showAction && (
        <ButtonContainer $position={menuPosition} $size={size}>
          <Button
            variant="tertiary"
            size={size === 'm' ? 'm' : 'xs'}
            label={actionLabel ?? 'ADD NEW PROJECT'}
            onClick={() => handleAction()}
          />
        </ButtonContainer>
      )}
    </StyledDropdownContainer>
  )
}

export default DropdownMenu
