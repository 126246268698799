import FeatherIcon from "feather-icons-react";
import { FeatherIconNames } from "feather-icons";
import { css, styled } from "styled-components";
import { theme } from "../../../styles";
import { customIcons, ICustomIconNames } from "./customIcons";

export type IIconNames = FeatherIconNames | ICustomIconNames;

export interface IIconComponentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IIconNames;
  iconSize: "s" | "m" | "l";
  onClick?: () => void;
  disabled?: boolean;
  cursorType?: string;
}

const sizeMap = {
  s: 16,
  m: 24,
  l: 38.4,
};

const containerSizeMap = {
  s: 24,
  m: 32,
  l: 48,
};

const StyledIconComponent = styled(FeatherIcon)`
  ${({ color, disabled, $cursorType }) => {
    return css`
      color: ${!disabled ? color : theme.colors.actionDisabled}!important;
      flex-shrink: 0;
      cursor: ${!disabled ? $cursorType || "pointer" : "not-allowed"};
    `;
  }}
`;

const StyledIconComponentContainer = styled.div<
  Pick<
    IIconComponentProps & { $iconSize: "s" | "m" | "l" },
    "disabled" | "$iconSize"
  >
>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ $iconSize }) => {
    const w = containerSizeMap[$iconSize];
    const h = containerSizeMap[$iconSize];
    const padding = $iconSize === "l" ? 4.8 : 4;

    return css`
      width: ${w}px;
      height: ${h}px;
      padding: ${padding}px;
    `;
  }}
`;

const IconComponent = ({
  icon,
  iconSize,
  color = theme.colors.copy,
  disabled = false,
  onClick,
  cursorType,
  type
}: IIconComponentProps) => {
  const CustomIcon = customIcons[icon as ICustomIconNames]
  return (
    <StyledIconComponentContainer $iconSize={iconSize} disabled={disabled}>
      {CustomIcon ? (
        <div
          onClick={!disabled ? onClick : undefined}
          style={{
            cursor: !disabled ? cursorType || 'pointer' : 'not-allowed',
            flex: 1,
            height: '100%'
          }}
        >
          <CustomIcon
            size={sizeMap[iconSize]}
            color={!disabled ? color : theme.colors.actionDisabled}
          />
        </div>
      ) : (
        <StyledIconComponent
          type={type || 'button'}
          $cursorType={cursorType}
          icon={icon as FeatherIconNames}
          size={sizeMap[iconSize]}
          color={color}
          disabled={disabled}
          onClick={onClick}
        />
      )}
    </StyledIconComponentContainer>
  )
}

export default IconComponent;
