

export const NewMail = ({
  size = 32,
  color = "black",
}: {
  size?: number;
  color: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33341 5.33334H25.6667C27.6867 5.33334 29.3334 6.98001 29.3334 9.00001V18H27.3334V12.6133L16.0001 19.16L4.66675 12.6133V23C4.66675 23.92 5.41341 24.6667 6.33341 24.6667H19.3334V26.6667H6.33341C4.31341 26.6667 2.66675 25.02 2.66675 23V9.00001C2.66675 6.98001 4.31341 5.33334 6.33341 5.33334ZM4.66675 10.3L16.0001 16.8467V16.84L27.3334 10.3V9.00001C27.3334 8.08001 26.5868 7.33334 25.6667 7.33334H6.33341C5.41341 7.33334 4.66675 8.08001 4.66675 9.00001V10.3ZM29.3334 24C29.3334 26.2091 27.5426 28 25.3334 28C23.1243 28 21.3334 26.2091 21.3334 24C21.3334 21.7909 23.1243 20 25.3334 20C27.5426 20 29.3334 21.7909 29.3334 24Z"
        fill={color}
      />
    </svg>
  );
};