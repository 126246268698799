import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import IconComponent, { IIconNames } from '../IconComponent/IconComponent'
import Counter from '../Counter/Counter'
export interface INavItemProps {
  isActive?: boolean
  disabled?: boolean
  navItemTitle: string
  showCounter?: boolean
  count?: number
  icon?: IIconNames | ''
  onClick?: () => void
}

const StyledNavItem = styled.div<{
  disabled?: boolean
  $isActive?: boolean
}>`
  display: flex;
  width: 100%;
  min-height: 46px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: ${theme.colors.copy};
  cursor: pointer;
  color: ${theme.colors.copySubtle};
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-within {
        background-color: ${theme.colors.actionPrimaryLightHover};

        .counter {
          background-color: ${theme.colors.actionPrimaryHover};
          color: ${theme.colors.white};
        }
      }
    `}

  ${({ $isActive, disabled }) =>
    $isActive &&
    !disabled &&
    css`
      background: ${theme.colors.actionPrimaryLightActive};
      color: ${theme.colors.actionPrimaryActive};
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;
      color: ${theme.colors.actionDisabled};
    `}
`

const StyledNavTitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

const StyledNavTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: ${theme.font.regular};
  line-height: 140%;
`

const NavItem = ({
  isActive = false,
  disabled = false,
  navItemTitle,
  showCounter = true,
  count,
  icon = '',
  onClick = () => {}
}: INavItemProps) => {
  return (
    <StyledNavItem $isActive={isActive} disabled={disabled} onClick={onClick}>
      <StyledNavTitleContent>
        {icon && (
          <IconComponent
            icon={icon}
            iconSize="m"
            color={
              disabled
                ? theme.colors.actionDisabled
                : isActive
                  ? theme.colors.actionPrimaryActive
                  : theme.colors.copySubtle
            }
            disabled={disabled}
          />
        )}
        <StyledNavTitle>{navItemTitle}</StyledNavTitle>
      </StyledNavTitleContent>
      {showCounter && (
        <Counter count={count} isActive={isActive} disabled={disabled} />
      )}
    </StyledNavItem>
  )
}

export default NavItem
