import styled from "styled-components";

export const StyledListBodyContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 32px 0px;

    @media screen and (min-width: 430px) {
    padding: 32px 0;
  }

  @media screen and (min-width: 834px) {
    padding-bottom: 40px;
  }
    
`