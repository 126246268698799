import { useState, useMemo, useCallback } from 'react'
import { useKanbanContext } from '../providers'
import { ComponentObj } from '../../../../store/reducers/componentsReducerSlice'

type SortField = 'title' | 'updatedAt' | 'completedTasks' | 'priority'
type SortOrder = 'asc' | 'desc'

interface SortConfig {
  field: SortField
  order: SortOrder
}

export const useSort = () => {
  const { components, setComponents } = useKanbanContext()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'updatedAt',
    order: 'asc'
  })

  // Memoized comparator function
  const comparator = useCallback(
    (a: ComponentObj, b: ComponentObj, field: SortField): number => {
      const multiplier = sortConfig.order === 'asc' ? 1 : -1

      switch (field) {
        case 'title':
          return multiplier * String(a.title).localeCompare(String(b.title))

        case 'updatedAt':
          return (
            multiplier *
            (new Date(String(a.updatedAt)).getTime() -
              new Date(String(b.updatedAt)).getTime())
          )

        case 'completedTasks':
          return (
            multiplier *
            ((a.completedTasks?.length || 0) - (b.completedTasks?.length || 0))
          )

        default:
          return 0
      }
    },
    [sortConfig.order]
  )

  // Memoized sorted data
  const sortedData = useMemo(() => {
    if (!components.length) return []

    const sorted = [...components].sort((a, b) =>
      comparator(a, b, sortConfig.field)
    )

    return sorted
  }, [components, sortConfig, comparator])

  // Debounced sort handler
  const handleSort = useCallback((field: SortField) => {
    setSortConfig((prev: SortConfig) => ({
      field,
      order:
        prev.field === field ? (prev.order === 'asc' ? 'desc' : 'asc') : 'asc'
    }))
  }, [])

  const updateSortedComponents = useCallback(() => {
    setComponents(sortedData)
  }, [sortedData, setComponents])

  return {
    sortedData,
    sortConfig,
    handleSort,
    updateSortedComponents
  }
}

// Usage example:
/*
const YourComponent = () => {
  const { sortedData, sortConfig, handleSort } = useSort()

  return (
    <div>
      <button onClick={() => handleSort('title')}>
        Sort by Title {sortConfig.field === 'title' ? (sortConfig.order === 'asc' ? '↑' : '↓') : ''}
      </button>
      {sortedData.map(item => (
        <div key={item.id}>{item.title}</div>
      ))}
    </div>
  )
}
*/
