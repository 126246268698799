import styled from 'styled-components'
import MenuItem from '../MenuItem/MenuItem'
import { useNavigate } from 'react-router-dom'

export interface IMenuItemsProps {
  type?: 'default' | 'account'
  menuType?: 'phase' | 'task'
  showSlot1?: boolean
  showSlot2?: boolean
  actionHandlers?: {
    handleSetInitialPhase?: () => void
    handleEdit?: () => void
    handleArchive?: () => void
    handleRemove?: () => void
    handleUnArchive?: () => void
  }
  closeMenu?: () => void
  path?: string
}

export const StyledMenuItems = styled.div<Pick<IMenuItemsProps, 'type'>>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  width: 100%;
  background: transparent;
`

export const StyledSlot = styled.div<{ $hasBorderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ${({ $hasBorderBottom, theme }) =>
    $hasBorderBottom &&
    `
    border-bottom: 1px solid ${theme.colors.borderDefault} ; padding-bottom: 16px;`};
`

const MenuItems = ({
  showSlot1 = true,
  showSlot2 = true,
  type = 'default'
}: IMenuItemsProps) => {
  return (
    <StyledMenuItems type={type}>
      {showSlot1 && (
        <StyledSlot $hasBorderBottom={showSlot1 && showSlot2}>
          <MenuItem
            title="Set as initial phase"
            icon="star"
            type="primary"
            disabled
          />
          <MenuItem title="Edit" icon="edit-3" type="primary" />
        </StyledSlot>
      )}
      {showSlot2 && (
        <StyledSlot $hasBorderBottom={false}>
          <MenuItem title="Archive" icon="folder" type="primary" />
          <MenuItem title="Remove" icon="trash" type="danger" />
        </StyledSlot>
      )}
    </StyledMenuItems>
  )
}

const AccountMenuItems = ({
  showSlot1 = true,
  showSlot2 = true,
  type = 'default'
}: IMenuItemsProps) => {
  return (
    <StyledMenuItems type={type}>
      {showSlot1 && (
        <StyledSlot $hasBorderBottom={showSlot1 && showSlot2}>
          <MenuItem title="My Account" icon="user" type="primary" disabled />
          <MenuItem title="Teams and users" icon="users" type="primary" />
        </StyledSlot>
      )}
      {showSlot2 && (
        <StyledSlot $hasBorderBottom={false}>
          <MenuItem title="Log out" icon="log-out" type="danger" />
        </StyledSlot>
      )}
    </StyledMenuItems>
  )
}

//Menu Items for Task Option in Template Details
export const TaskMenuItems = ({
  closeMenu,
  actionHandlers,
  path
}: IMenuItemsProps) => {
  const navigate = useNavigate()
  return (
    <>
      <StyledSlot $hasBorderBottom>
        <MenuItem
          title="Edit Title"
          icon="edit-3"
          type="primary"
          onClick={actionHandlers?.handleEdit}
          closeMenu={closeMenu}
        />
        <MenuItem
          title="Edit Details"
          icon="edit-3"
          type="primary"
          closeMenu={closeMenu}
          onClick={() => path && navigate(path)}
        />
      </StyledSlot>
      <StyledSlot $hasBorderBottom={false}>
        <MenuItem
          title="Archive"
          icon="folder"
          type="primary"
          onClick={actionHandlers?.handleArchive}
          closeMenu={closeMenu}
          // disabled
        />
        <MenuItem
          title="Remove"
          icon="trash"
          type="danger"
          onClick={actionHandlers?.handleRemove}
          closeMenu={closeMenu}
        />
      </StyledSlot>
    </>
  )
}

//Menu Items for Archived Phase Option in Template Details
export const ArchivedItemMenuItems = ({
  closeMenu,
  actionHandlers
}: IMenuItemsProps) => {
  return (
    <StyledSlot>
      <MenuItem
        title="Unarchive"
        icon="folder"
        type="primary"
        onClick={actionHandlers?.handleUnArchive}
        closeMenu={closeMenu}
      />
      <MenuItem
        title="Remove"
        icon="trash"
        type="danger"
        onClick={actionHandlers?.handleRemove}
        closeMenu={closeMenu}
      />
    </StyledSlot>
  )
}

//Menu Items for Phase Option in Template Details
export const PhaseMenuItems = ({
  closeMenu,
  actionHandlers,
  path
}: IMenuItemsProps) => {
  const navigate = useNavigate()
  return (
    <>
      <StyledSlot $hasBorderBottom>
        <MenuItem
          title="Edit Title"
          icon="edit-3"
          type="primary"
          onClick={actionHandlers?.handleEdit}
          closeMenu={closeMenu}
        />
        <MenuItem
          title="Edit Details"
          icon="edit-3"
          type="primary"
          closeMenu={closeMenu}
          onClick={() => path && navigate(path)}
        />
      </StyledSlot>
      <StyledSlot $hasBorderBottom={false}>
        <MenuItem
          title="Archive"
          icon="folder"
          type="primary"
          onClick={actionHandlers?.handleArchive}
          closeMenu={closeMenu}
          // disabled
        />
        <MenuItem
          title="Remove"
          icon="trash"
          type="danger"
          onClick={actionHandlers?.handleRemove}
          closeMenu={closeMenu}
        />
      </StyledSlot>
    </>
  )
}

export default MenuItems

export { AccountMenuItems }
