/* eslint-disable @typescript-eslint/no-unused-vars */
import {  FC } from 'react'
import WorkflowActionExpandCollapse from './components/WorkFlowExpandCollapse'
import WorkflowActionProvider, {
  WorkflowActionType
} from '../../../context/WorkflowActionProvider'
import { UserRole } from '../../../types/user'
import { ConditionallyRender } from '../../../utils/ConditionallyRender'
import { WorkflowType } from '../../../pages/TemplateEdit'

export interface IWorkflowActionProps {
  collapse: boolean
  device: 'desktop' | 'mobile'
  option: 'email' | 'jira'
  onSubmit: (data: WorkflowActionType) => void
  onChangeOption: (val: string) => void
  onClickTrigger: () => void
  showSignalIcon?: boolean
  isJiraAuthorized?: boolean
  jiraProjectOptions?: { label: string; value: string }[]
  defaultValues: Partial<WorkflowActionType>
  collapseStates: Record<number, boolean>
  setCollapseStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >
  isLoading?: boolean
  showWorkflowButton?: boolean
  userRole?: UserRole
  details?: WorkflowResponse
  setWorkflowTypes: React.Dispatch<React.SetStateAction<WorkflowType[]>>
  workflowTypes: WorkflowType[]
  workflow: any
}

const WorkflowAction: FC<IWorkflowActionProps> = ({
  collapse = true,
  device,
  option = 'email',
  onChangeOption,
  onClickTrigger,
  showSignalIcon = false,
  isJiraAuthorized = false,
  jiraProjectOptions,
  onSubmit,
  defaultValues,
  isLoading,
  showWorkflowButton,
  userRole,
  details,
  collapseStates,
  setCollapseStates,
  setWorkflowTypes,
  workflowTypes,
  workflow
}) => {
  return (
    <WorkflowActionProvider
      collapse={collapse}
      defaultValues={defaultValues}
      device={device}
      isJiraAuthorized={isJiraAuthorized}
      onChangeOption={onChangeOption}
      onClickTrigger={onClickTrigger}
      onSubmit={onSubmit}
      option={option}
      jiraProjectOptions={jiraProjectOptions}
      showSignalIcon={showSignalIcon}
      isLoading={isLoading}
      showWorkflowButton={showWorkflowButton}
      userRole={userRole}
      details={details}
      collapseStates={collapseStates}
      setCollapseStates={setCollapseStates}
      setWorkflowTypes={setWorkflowTypes}
      workflowTypes={workflowTypes}
      workflow={workflow}
    >
      <WorkflowActionExpandCollapse />
    </WorkflowActionProvider>
  )
}

export default WorkflowAction
