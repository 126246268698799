import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import httpRequest from '../../utils/fetcher'
import { nest } from '../../utils/axios'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { Thing, WithContext } from 'schema-dts'
import { WebsitePublication } from './types'

// Styled Components
const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
  background: ${(props) => props.theme.bg || '#f4f9f4'};
  border: 1px solid ${(props) => props.theme.border || '#ccc'};
  border-radius: 8px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`
const PhaseCard = styled.div`
  border: 1px solid ${(props) => props.theme.border || '#ddd'};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background: ${(props) => props.theme.bg || '#fff'};
`

const PhaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PhaseTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  color: ${(props) => props.theme.primary || '#0077ff'};
`

const PhaseTasks = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 1rem;
`

const TaskItem = styled.li`
  padding: 0.5rem;
  background: ${(props) => props.theme.taskBg || '#f9f9f9'};
  border: 1px solid ${(props) => props.theme.border || '#ddd'};
  border-radius: 4px;
  margin-bottom: 0.5rem;
`

const Loading = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text || '#555'};
`

const ShareButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.primary || '#0077ff'};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ProfileImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.border || '#ccc'};
`

const Content = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
`

// Component
const WebsitePublicationPage: React.FC = () => {
  const param = useParams()
  const userIdOrSlug = param?.userIdOrSlug

  const { data, isLoading, error } = useQuery({
    queryKey: ['websitePublication', userIdOrSlug],
    queryFn: async () => {
      return httpRequest({
        url: `/webpage-publication/publication/${userIdOrSlug}`,
        method: 'GET'
      })<WebsitePublication>(nest)
    }
  })
  if (isLoading) return <Loading>Loading...</Loading>
  if (error || !data) return <Loading>Error loading publication</Loading>

  const { author, template, phases } = data

  const schemaData: WithContext<Thing> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: template?.title || 'Default Title',
    description: template?.description || 'Default description of the webpage.',
    datePublished: new Date(
      template?.createdAt || new Date().toISOString()
    ).toISOString(),
    dateModified: new Date(
      template?.updatedAt || new Date().toISOString()
    ).toISOString(),
    author: {
      '@type': 'Person',
      name: author?.displayName || 'Anonymous',
      email: author?.email || '',
      image: author?.profileImg || ''
    },
    publisher: {
      '@type': 'Organization',
      name: 'Compfeed',
      logo: {
        '@type': 'ImageObject',
        url: '/assets/favicon.svg'
      }
    },
    mainEntity: {
      '@type': 'ItemList',
      itemListElement: Object.values(phases || {}).map((phase, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: phase.title,
        item: {
          '@type': 'CreativeWork',
          headline: phase.title,
          about: phase.description
        }
      }))
    }
  }

  // const canonicalUrl = `https://$
  //       {import.meta.env.VITE_APP_PUBLIC_URL || window.location.hostname}
  //       /published/${userIdOrSlug}`

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>{template?.title || 'Website Publication'}</title>
        <meta
          name="description"
          content={template?.description || 'Default description'}
        />
        <meta name="author" content={author?.displayName || 'Anonymous'} />
        <link
          rel="canonical"
          href={`${window.location.origin}/published/${userIdOrSlug}`}
        />
        {/* Open Graph Metadata */}
        <meta
          property="og:title"
          content={template?.title || 'Website Publication'}
        />
        <meta
          property="og:description"
          content={template?.description || 'Default description'}
        />
        <meta
          property="og:image"
          content={author?.profileImg || '/default-image.png'}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${window.location.origin}/published/${userIdOrSlug}`}
        />
        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={template?.title || 'Website Publication'}
        />
        <meta
          name="twitter:description"
          content={template?.description || 'Default description'}
        />
        <meta
          name="twitter:image"
          content={author?.profileImg || '/default-image.png'}
        />
        {/* JSON-LD */}
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <Container>
        {/* Header Section */}
        <Header>
          <Title>{template?.title || 'Title of the Page'}</Title>
          <ShareButton>Share Page</ShareButton>
        </Header>

        {/* User Details */}
        <UserDetails>
          <ProfileImg src={author.profileImg} alt={`${author.displayName}`} />
          <div>
            <div>{author.displayName}</div>
            <div>{author.email}</div>
          </div>
        </UserDetails>

        {/* Content Section */}
        <Content>
          {phases &&
            Object.values(phases).map((phase) => (
              <PhaseCard key={phase.id}>
                {/* Phase Header */}
                <PhaseHeader>
                  <PhaseTitle>{phase.title}</PhaseTitle>
                  <span>{phase.isActive ? 'Active' : 'Inactive'}</span>
                </PhaseHeader>

                {/* Phase Tasks */}
                <PhaseTasks>
                  {phase.tasks &&
                    Object.values(phase.tasks).map((task) => (
                      <TaskItem key={task.id}>
                        {task.title}{' '}
                        {task.mandatory && <strong>(Mandatory)</strong>}
                      </TaskItem>
                    ))}
                </PhaseTasks>
              </PhaseCard>
            ))}
        </Content>
      </Container>
    </>
  )
}

export default WebsitePublicationPage
