import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { debounce } from 'lodash'
import DropdownSelector from './DropdownSelector'
import DropdownMenu, { Container } from './DropdownMenu'
import { theme } from '../../../styles'
import { createPortal } from 'react-dom'

export type IDropdownItem = {
  id: string | number
  name: string
  description?: string
}

export type IDropdownProps = {
  items?: IDropdownItem[]
  placeholder?: string
  size?: 'm' | 's'
  type?: 'search' | 'list'
  showAction?: boolean
  textColor?: string
  showBorderRadius?: boolean
  width?: string
  externalSelectedItem?: IDropdownItem
  onSelectedItemChange?: (item: IDropdownItem) => void
  [key: string]: any
}

const Dropdown: React.FC<IDropdownProps> = ({
  items,
  placeholder = 'Find a project',
  size = 'm',
  type = 'search',
  showAction = true,
  textColor = theme.colors.copy,
  externalSelectedItem,
  onSelectedItemChange
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IDropdownItem>(
    externalSelectedItem || {
      id: -1,
      name: placeholder ?? 'Select the option'
    }
  )
  const [menuPosition, setMenuPosition] = useState<'bottom' | 'top'>('bottom')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)

  const itemsPerPage = 5
  const [loadedCount, setLoadedCount] = useState<number>(itemsPerPage)
  const [loading, setLoading] = useState(false)

  // Simulate API fetching with delay
  const fetchMoreItems = useCallback(() => {
    setLoading(true)
    setTimeout(() => {
      setLoadedCount((prevCount) =>
        Math.min(prevCount + itemsPerPage, Number(items?.length))
      )
      setLoading(false)
    }, 1500)
  }, [Number(items?.length)])

  // Calculate menu position based on available space
  const calculateMenuPosition = useCallback((): 'top' | 'bottom' => {
    if (dropdownRef.current) {
      const windowHeight = window.innerHeight
      const selectRect = dropdownRef.current.getBoundingClientRect()
      const selectBottom = selectRect.bottom
      const selectTop = selectRect.top
      const spaceBelow = windowHeight - selectBottom
      const spaceAbove = selectTop

      return spaceBelow > spaceAbove || spaceBelow > 300 ? 'bottom' : 'top'
    }
    return 'bottom'
  }, [])

  useEffect(() => {
    if (isOpen) {
      const position = calculateMenuPosition()
      setMenuPosition(position)
    }
  }, [isOpen, calculateMenuPosition])

  // Infinite scroll handler
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLUListElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
      if (scrollHeight - scrollTop === clientHeight && !loading) {
        fetchMoreItems()
      }
    },
    [loading, fetchMoreItems]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        portalRef.current &&
        !portalRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('resize', updatePortalPosition)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('resize', updatePortalPosition)
    }
  }, [])

  const handleSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term)
      setLoadedCount(itemsPerPage)
    }, 300),
    [itemsPerPage]
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value)
    },
    [handleSearch]
  )

  const filteredItems = useMemo(() => {
    return items
      ?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )
      .slice(0, loadedCount)
  }, [searchTerm, items, loadedCount])

  useEffect(() => {
    if (externalSelectedItem) {
      setSelectedItem(externalSelectedItem)
      setSearchTerm('')
      setHighlightedIndex(null)
    }
  }, [externalSelectedItem])

  const handleItemClick = useCallback((item: IDropdownItem) => {
    setSelectedItem(item)
    onSelectedItemChange && onSelectedItemChange(item)
    setIsOpen(false)
  }, [])

  // const handleClear = useCallback(() => {
  //   const defaultItem = {
  //     id: -1,
  //     name: placeholder ?? 'Select the option'
  //   }
  //   setSelectedItem(defaultItem)
  //   onSelectedItemChange?.(defaultItem)
  //   setSearchTerm('')
  //   setHighlightedIndex(null)
  // }, [placeholder, onSelectedItemChange])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!isOpen) return

      switch (e.key) {
        case 'ArrowDown':
          setHighlightedIndex((prev) =>
            prev === null || prev === Number(filteredItems?.length) - 1
              ? 0
              : prev + 1
          )
          break
        case 'ArrowUp':
          setHighlightedIndex((prev) =>
            prev === null || prev === 0
              ? Number(filteredItems?.length) - 1
              : prev - 1
          )
          break
        case 'Enter':
          if (highlightedIndex !== null) {
            handleItemClick(filteredItems?.[highlightedIndex] as IDropdownItem)
          }
          break
        case 'Escape':
          setIsOpen(false)
          setHighlightedIndex(null)
          break
        default:
          break
      }
    },
    [isOpen, filteredItems, highlightedIndex, handleItemClick]
  )

  const DropdownPortal = ({ children }: { children: React.ReactNode }) => {
    return createPortal(
      <div
        ref={portalRef}
        style={{
          position: 'absolute',
          top: (dropdownRef.current?.getBoundingClientRect().bottom ?? 0) + 8,
          left: dropdownRef.current?.getBoundingClientRect().left
        }}
      >
        {children}
      </div>,
      document.body
    )
  }

  const updatePortalPosition = () => {
    if (portalRef.current && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect()
      portalRef.current.style.top = `${rect.bottom + 8}px`
      portalRef.current.style.left = `${rect.left}px`
    }
  }

  useEffect(() => {
    if (isOpen) {
      updatePortalPosition()
    }
  }, [isOpen])

  return (
    <Container ref={dropdownRef}>
      <DropdownSelector
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedItem={selectedItem}
        menuPosition={menuPosition}
        onKeyDown={handleKeyDown}
        size={size}
        textColor={textColor}
      />

      {isOpen && (
        <DropdownPortal>
          <DropdownMenu
            showBorderRadius={false}
            type={type}
            size={size}
            menuPosition={menuPosition}
            isOpen={isOpen}
            placeholder={placeholder}
            filteredItems={filteredItems as IDropdownItem[]}
            highlightedIndex={highlightedIndex}
            selectedItem={selectedItem}
            loading={loading}
            handleInputChange={handleInputChange}
            handleScroll={handleScroll}
            handleItemClick={handleItemClick}
            setHighlightedIndex={setHighlightedIndex}
            showAction={showAction}
          />
        </DropdownPortal>
      )}
    </Container>
  )
}

export default Dropdown
