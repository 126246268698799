import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { child, ref, update, remove } from 'firebase/database'
import { auth, db } from '../../config/config'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setTemplatesData } from '../../store/reducers/templateReducerSlice'
import { setPhasesData } from '../../store/reducers/phaseReducerSlice'
import {
  StyledTemplateEditMainContainer,
  StyledTemplateEditContentContainer,
  StyledTemplateEditTitleContainer,
  StyledTemplateEditBody,
  StyledDangerZoneContainer,
  StyledDangerZoneHeader,
  StyledDangerZoneTitle,
  StyledDangerZoneDescription
} from './StyledTemplateEdit'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import Spinner from '../../components/ui/Spinner'
import SubNav from '../../components/ui/SubNav/SubNav'
import TextArea from '../../components/ui/TextArea/TextArea'
import Modal from '../../components/ui/Modal/Modal'
import { TemplateDeleteContent } from '../../components/ui/Modal/ModalSlotContents'
import { ComponentTypes } from '../../store/reducers/componentsReducerSlice'
import { getTimeStamp } from '../../utils/helpers'
import styled from 'styled-components'
import { theme } from '../../styles'
import WorkflowAction from '../../components/ui/WorkflowAction/WorkflowAction'
import { ConditionallyRender } from '../../utils/ConditionallyRender'
import { nest } from '../../utils/axios'
import httpRequest from '../../utils/fetcher'
import { useQuery } from '@tanstack/react-query'
import { WorkflowActionType } from '../../context/WorkflowActionProvider'
import useIsMobileDevice from '../../hooks/useIsMobileDevice'
import WorkflowActionButton from '../workflows/components/WorkflowButton'
import useCheckAtlassianAuth from '../workflows/hooks/useCheckAtlassianAuth'
import useWorkflowAdd from '../workflows/hooks/useWorkflowAdd'
import { UserRole } from '../../types/user'
import useGetWorkflows from '../workflows/hooks/useGetWorkflow'

const StyledButton = styled.button`
  color: ${theme.colors.signalErrorNormalActive};
  background: ${theme.colors.phaseBackground3};
  border: none;
  background: none;
  width: 20%;
`

// export type WorkflowType = 'jira' | 'email'
export enum WorkflowType {
  JIRA = 'jira',
  EMAIL = 'email'
}

const TemplateEdit: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState<{
    title: string
    description: string
    type: ComponentTypes
    visible: boolean
    color?: string
  }>({
    title: '',
    description: '',
    type: 'component',
    visible: true,
    color: ''
  })
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [connectedComponents, setConnectedComponents] = useState<any>([])
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowType[]>([])
  const [collapseStates, setCollapseStates] = useState<Record<number, boolean>>(
    {
      0: true
    }
  )

  const isMobile = useIsMobileDevice()

  const templateRef = ref(db, 'templates')
  const { data: allTemplates, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const templatesList = allTemplates?.filter(
    (tmp) => tmp?.type === formData?.type
  )

  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)
  const { data: componentList } = useSelector(
    (store: RootState) => store.component
  )
  const { data: phases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )

  const canDelete = templatesList?.length > 1
  const userRoleInCurrentTeam = currentTeam?.members?.find(
    (member) => member.id === user?.id
  )?.role

  // Fetch connected components when the template ID or component list changes
  useEffect(() => {
    if (!id || isLoading || !componentList) return

    const connected = componentList.filter(
      (component) => component.templateId === id
    )
    setConnectedComponents(connected)
  }, [id, componentList, isLoading])

  // const handleProjectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = e.target
  //   setFormData(({
  //     ...formData,
  //     type: "component",
  //     color: '#77F2E6',
  //     visible: true
  //   }))
  // }
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (saving || !id || isLoading) return

    if (!formData?.title?.trim()) {
      toast.info('Please name this template.')
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team ID not found.')
      return
    }

    setSaving(true)

    try {
      // Save the updated template data
      const tm = getTimeStamp()
      await update(child(templateRef, `${teamId}/${id}`), {
        ...formData,
        updatedAt: tm
      })

      // Update Redux state
      const updatedData = [...allTemplates]
      const index = allTemplates.findIndex((v) => v?.id === id)
      if (index !== -1) {
        updatedData[index] = {
          ...updatedData[index],
          ...formData,
          updatedAt: tm
        }
      }
      dispatch(setTemplatesData({ data: updatedData }))
      toast.success('Your changes are saved.')
      navigate(`/dashboard/templates/${id}`)
    } catch (err: any) {
      toast.error(err?.message || 'Something went wrong.')
    } finally {
      setSaving(false)
    }
  }

  const deleteTemplate = async (forceDelete = false) => {
    if (!id || saving || isLoading || deleting || isLoadingPhases) return

    if (connectedComponents.length > 0 && !forceDelete) {
      showToastWithStyles(connectedComponents.length)
      setShowModal(false)
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Template id not found')
      return
    }

    setDeleting(true)
    try {
      await remove(child(templateRef, `${teamId}/${id}`))
      await remove(ref(db, `phases/${id}`))

      const updatedList = allTemplates.filter((v) => v?.id !== id)
      dispatch(setTemplatesData({ data: updatedList }))
      dispatch(
        setPhasesData({
          data: phases?.filter((phase) => phase?.templateId !== id)
        })
      )

      toast.success('The template has been removed.')
      navigate('/dashboard/templates')
    } catch (error) {
      toast.error('Something went wrong.')
    } finally {
      setDeleting(false)
    }
  }

  const showToastWithStyles = (connectedComponentsLength: number) => {
    const toastId = toast.warning(
      `This template is connected to ${connectedComponentsLength} component(s). Please connect these components to another template.`,
      {
        autoClose: 4000,
        closeOnClick: false,
        pauseOnHover: true,
        style: {
          width: 'max-content',
          maxWidth: '800px'
        }
      }
    )

    toast.update(toastId, {
      closeButton: ({ closeToast }) => (
        <StyledButton
          onClick={(e) => {
            deleteTemplate(true)
            closeToast(e)
          }}
        >
          Delete Anyway
        </StyledButton>
      )
    })
  }

  useEffect(() => {
    if (!id || isLoading) return
    const templateInfo = allTemplates?.find((tmp) => tmp?.id === id)
    if (!templateInfo) return
    setFormData({
      title: templateInfo?.title,
      description: templateInfo?.description,
      type: (templateInfo?.type as ComponentTypes) || 'component',
      visible: templateInfo?.visible
    })
  }, [id, allTemplates, isLoading])

  const { data: projects } = useQuery({
    queryKey: ['getPhase', id],
    queryFn: async () => {
      const token = await auth.currentUser?.getIdToken()
      return httpRequest({
        url: `atlassian/projects`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          startAt: 1,
          maxResults: 50
        }
      })<JiraProjectSearchResponse>(nest)
    }
  })

  const projectOptions = useMemo(() => {
    return projects?.values.map((project) => ({
      label: project.name,
      value: project.id
    }))
  }, [projects])

  const { mutateAsync, isPending } = useWorkflowAdd()

  const validateWorkflowData = (
    data: any,
    type: 'jira' | 'email'
  ): { isValid: boolean; message: string } => {
    const workflow = type === 'jira' ? data.jira : data.mail

    if (!workflow) {
      return { isValid: false, message: 'Invalid workflow data' }
    }

    if (type === 'jira') {
      if (!workflow.when?.length) {
        return {
          isValid: false,
          message: 'Please select when to trigger the workflow'
        }
      }
      if (!workflow.then?.length) {
        return {
          isValid: false,
          message: 'Please select what happens when triggered'
        }
      }
      if (!workflow.jiraProject?.length) {
        return { isValid: false, message: 'Please select a Jira project' }
      }
      if (!workflow.syncWithJira?.length) {
        return { isValid: false, message: 'Please select sync settings' }
      }
    } else {
      if (!workflow.when?.length) {
        return {
          isValid: false,
          message: 'Please select when to trigger the workflow'
        }
      }
      if (!workflow.then?.length) {
        return {
          isValid: false,
          message: 'Please select what happens when triggered'
        }
      }
      if (!workflow.recipient?.length) {
        return {
          isValid: false,
          message: 'Please select at least one recipient'
        }
      }
    }

    if (!workflow.details?.includes('component_name')) {
      return {
        isValid: false,
        message: 'Component name is required in details'
      }
    }

    return { isValid: true, message: '' }
  }

  const onSubmit = async (data: WorkflowActionType) => {
    try {
      const type = data.jira ? 'jira' : 'email'
      const validation = validateWorkflowData(data, type)

      if (!validation.isValid) {
        toast.error(validation.message)
        return
      }

      await mutateAsync(data)
      toast.success('Workflow saved successfully')
    } catch (error) {
      console.error('Error submitting workflow:', error)
      toast.error('Failed to save workflow')
    }
  }

  const {
    data: workflow,
    isLoading: workflowLoading,
    isError
  } = useGetWorkflows({
    teamId: currentTeam?.id ?? '',
    templateId: id ?? ''
  })

  const [defaultValues, setDefaultValues] = useState<
    Partial<WorkflowActionType>
  >({
    jira: {
      id: workflow?.jira?.id,
      when: workflow?.jira?.when || [],
      then: workflow?.jira?.then || [],
      jiraProject: workflow?.jira?.jiraProject || [],
      syncWithJira: workflow?.jira?.syncWithJira || [],
      details: workflow?.jira?.details || ['component_name'],
      templateId: id || '',
      teamId: currentTeam?.id || '',
      recipient: currentTeam?.members?.map((member) => member.email) || []
    },
    mail: {
      id: workflow?.email?.id,
      when: workflow?.email?.when || [],
      then: workflow?.email?.then || [],
      details: workflow?.email?.details || ['component_name'],
      templateId: id || '',
      teamId: currentTeam?.id || '',
      recipient: currentTeam?.members?.map((member) => member.email) || []
    }
  })

  useEffect(() => {
    if (workflowLoading) {
      return
    }

    if (isError) {
      return
    }

    setDefaultValues({
      jira: {
        id: workflow?.jira?.id,
        when: workflow?.jira?.when || [],
        then: workflow?.jira?.then || [],
        jiraProject: workflow?.jira?.jiraProject || [],
        syncWithJira: workflow?.jira?.syncWithJira || [],
        details: workflow?.jira?.details || ['component_name'],
        templateId: id || '',
        teamId: currentTeam?.id || '',
        recipient: currentTeam?.members?.map((member) => member.email) || []
      },
      mail: {
        id: workflow?.email?.id,
        when: workflow?.email?.when || [],
        then: workflow?.email?.then || [],
        details: workflow?.email?.details || ['component_name'],
        templateId: id || '',
        teamId: currentTeam?.id || '',
        recipient: currentTeam?.members?.map((member) => member.email) || []
      }
    })
  }, [workflowLoading, isError, workflow, id, currentTeam])

  const { data, isLoading: authLoading } = useCheckAtlassianAuth({
    teamId: currentTeam?.id ?? '',
    templateId: id ?? ''
  })

  useEffect(() => {
    console.log('data', data)
    if (data) {
      const initialWorkflows: WorkflowType[] = []

      if (data.workflowExists && data.availableWorkflows) {
        if (data.availableWorkflows.hasJira) {
          initialWorkflows.push(WorkflowType.JIRA)
        }
        if (data.availableWorkflows.hasEmail) {
          initialWorkflows.push(WorkflowType.EMAIL)
        }
      }

      setWorkflowTypes(initialWorkflows)
      setCollapseStates(
        initialWorkflows.reduce(
          (acc, _, index) => ({
            ...acc,
            [index]: true
          }),
          {}
        )
      )
    }
  }, [data, id, currentTeam, workflow])

  const handleCollapse = (index: number) => {
    setCollapseStates((prev) => ({
      ...prev,
      [index]: !prev[index]
    }))
  }

  const handleAddNewWorkflow = () => {
    if (!currentTeam?.id || !id) {
      return
    }

    // Determine new workflow type
    const newWorkflowType = (() => {
      // If no workflows exist, start with JIRA
      if (workflowTypes.length === 0) {
        return WorkflowType.JIRA
      }

      // Check which workflow can be added based on current state
      const hasJira = workflowTypes.includes(WorkflowType.JIRA)
      const hasEmail = workflowTypes.includes(WorkflowType.EMAIL)

      if (hasJira && !hasEmail && !data?.availableWorkflows?.hasEmail) {
        return WorkflowType.EMAIL
      }

      if (hasEmail && !hasJira && !data?.availableWorkflows?.hasJira) {
        return WorkflowType.JIRA
      }

      console.log('No additional workflows available:', {
        hasJira,
        hasEmail,
        availableWorkflows: data?.availableWorkflows
      })
      return null
    })()

    if (!newWorkflowType) {
      return
    }

    const newWorkflowIndex = workflowTypes.length

    // Update workflow types
    setWorkflowTypes((prev) => {
      return [...prev, newWorkflowType]
    })

    // Set collapse states - expand new workflow, collapse others
    setCollapseStates((prev) => {
      const newState = Object.keys(prev).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      )
      return { ...newState, [newWorkflowIndex]: false }
    })

    const baseValues = {
      when: [],
      then: [],
      details: ['component_name'],
      templateId: id,
      teamId: currentTeam.id,
      recipient: currentTeam.members?.map((member) => member.email) || []
    }

    const typeSpecificValues =
      newWorkflowType === WorkflowType.JIRA
        ? { jiraProject: [], syncWithJira: [] }
        : {}

    // Update default values
    setDefaultValues((prev) => {
      const newValues = {
        ...prev,
        [newWorkflowType]: {
          ...baseValues,
          ...typeSpecificValues
        }
      }
      return newValues
    })
  }

  const showWorkflowButton = useMemo(() => {
    if (!data) return true

    const usedWorkflows = new Set(workflowTypes.map((type) => type))

    if (!data.availableWorkflows) {
      return !usedWorkflows.has(WorkflowType.JIRA)
    }

    if (usedWorkflows.size === 0) {
      return true
    }
    if (usedWorkflows.size === 1) {
      return true
    }
    return false
  }, [data, workflowTypes])

  return (
    <StyledTemplateEditMainContainer id="edit-template-container">
      <SubNav
        title="Edit Template"
        onBtnClick={() => navigate(`/dashboard/templates/${id}`)}
      />
      <StyledTemplateEditBody>
        <form onSubmit={handleSave} style={{ width: '100%' }}>
          <StyledTemplateEditContentContainer>
            {isLoading ? (
              <div
                style={{ width: '100%', padding: '25px' }}
                className="flex justify-center"
              >
                <Spinner type="spinner" />
              </div>
            ) : null}
            <StyledTemplateEditTitleContainer>
              {/* <SelectField
                options={TEMPLATE_TYPES}
                fullWidth
                value={formData?.type}
                disabled
                onChange={handleProjectTypeChange}
              /> */}
              <InputField
                disabled={isLoading || saving}
                autoFocus
                required
                fullWidth
                label="Template Name"
                placeholder="maximum of 120 characters"
                name="title"
                type="text"
                value={formData.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    title:
                      e.target?.value?.length < 120
                        ? e.target?.value
                        : formData?.title
                  })
                }
              />
              <TextArea
                disabled={isLoading || saving}
                required
                width="100%"
                textLabel="Template Description"
                placeholder="maximum of 120 characters"
                name="description"
                value={formData.description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setFormData({
                    ...formData,
                    description:
                      e.target?.value?.length < 160
                        ? e.target?.value
                        : formData?.description
                  })
                }
              />
            </StyledTemplateEditTitleContainer>

            <Button
              disabled={isLoading || saving}
              type="submit"
              label={isLoading ? 'Saving...' : 'UPDATE CHANGES'}
              variant="primary"
            />
          </StyledTemplateEditContentContainer>
        </form>

        <ConditionallyRender
          condition={userRoleInCurrentTeam !== UserRole.EDITOR}
          show={
            <ConditionallyRender
              condition={workflowLoading || authLoading}
              show={
                <div
                  style={{ width: '100%', padding: '25px' }}
                  className="flex justify-center"
                >
                  <Spinner type="spinner" />
                </div>
              }
              elseShow={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                  }}
                >
                  {workflowTypes.map((workflow, index) => (
                    <WorkflowAction
                      key={`${workflow}-${index}`}
                      collapse={collapseStates[index]}
                      onClickTrigger={() => handleCollapse(index)}
                      device={isMobile ? 'mobile' : 'desktop'}
                      option={workflow}
                      onChangeOption={(value) => {
                        showWorkflowButton &&
                          setWorkflowTypes([value as WorkflowType])
                      }}
                      jiraProjectOptions={projectOptions}
                      defaultValues={defaultValues}
                      isJiraAuthorized={
                        Boolean(data?.isAtlassianAuthenticated) || false
                      }
                      showSignalIcon={false}
                      onSubmit={onSubmit}
                      isLoading={isPending}
                      showWorkflowButton={showWorkflowButton}
                      userRole={userRoleInCurrentTeam as UserRole}
                      details={data}
                      workflow={workflow}
                      collapseStates={collapseStates}
                      setCollapseStates={setCollapseStates}
                      setWorkflowTypes={setWorkflowTypes}
                      workflowTypes={workflowTypes}
                    />
                  ))}
                  {showWorkflowButton && (
                    <WorkflowActionButton onClick={handleAddNewWorkflow} />
                  )}
                </div>
              }
            />
          }
        />
        <StyledDangerZoneContainer>
          <StyledDangerZoneHeader>
            <StyledDangerZoneTitle>Danger zone</StyledDangerZoneTitle>
            <StyledDangerZoneDescription>
              {!canDelete
                ? "You can't delete the last template. Please create a new template before deleting this one."
                : `Are you sure you want to delete this template? This action is irreversible and will permanently erase all your data. If you're sure, tap 'Remove Template' below.`}
            </StyledDangerZoneDescription>
          </StyledDangerZoneHeader>
          <Button
            type="button"
            size="small"
            disabled={!canDelete || saving || isLoading || deleting}
            label={deleting ? 'Removing...' : 'REMOVE TEMPLATE'}
            variant="danger"
            onClick={() => (canDelete ? setShowModal(true) : null)}
            fullWidth={false}
          />
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              signal="alert-triangle"
              signalIconType="warning"
              title="Remove Template"
              slotContent={
                <TemplateDeleteContent
                  componentNumber={connectedComponents.length}
                  type={formData?.type as ComponentTypes}
                  templateId={id as string}
                />
              }
              buttonLabel1="REMOVE TEMPLATE"
              buttonLabel2="CANCEL"
              buttonVariant1="danger"
              buttonVariant2="tertiary"
              buttonOnClick1={deleteTemplate}
            />
          )}
        </StyledDangerZoneContainer>
      </StyledTemplateEditBody>
    </StyledTemplateEditMainContainer>
  )
}

export default TemplateEdit
