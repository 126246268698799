import styled, { css } from 'styled-components'
import { FC, HTMLAttributes } from 'react'
import { theme } from '../../../styles'
import IconComponent from '../../../components/ui/IconComponent/IconComponent'

const {
  actionPrimaryLightActive,
  actionPrimaryLight,
  actionPrimary,
  actionPrimaryLightHover,
  actionPrimaryHover,
  actionPrimaryActive
} = theme.colors

const StyledWorkflowButton = styled.button<{ $isMobile: boolean }>`
  display: flex;
  gap: 16px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: ${actionPrimaryLight};
  color: ${actionPrimary};
  font-weight: 420;
  border-radius: 4px;
  border: 1px dashed ${actionPrimaryLightActive};
  transition: all 0.3s;
  width: 100%;

  ${({ $isMobile }) => {
    const fontSize = $isMobile ? '14px' : '16px'
    const padding = $isMobile ? '24px 16px' : '16px'
    const gap = $isMobile ? '8px' : '16px'

    return css`
      font-size: ${fontSize};
      padding: ${padding};
      gap: ${gap};
    `
  }}

  &:hover {
    background-color: ${actionPrimaryLightHover};
    color: ${actionPrimaryHover};
  }

  &:active {
    background-color: ${actionPrimaryLightActive};
    color: ${actionPrimaryActive};
  }

  &:disabled {
    color: ${actionPrimaryLightActive};
  }
`

interface IWorkflowActionProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  device?: 'mobile' | 'desktop'
}

const WorkflowActionButton: FC<IWorkflowActionProps> = ({
  disabled,
  device = 'desktop',
  ...props
}) => {
  const isMobile = device === 'mobile'

  return (
    <StyledWorkflowButton {...props} disabled={disabled} $isMobile={isMobile}>
      <IconComponent icon="plus-circle" iconSize="m" color="currentColor" />
      <span>Add workflow</span>
    </StyledWorkflowButton>
  )
}

export default WorkflowActionButton
