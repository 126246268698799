import styled from "styled-components";
import Layout from "../../Layout/Layout";
import Card, { ICardProps } from "../../ui/Card/Card";
import { ISelectorStepperProps } from "../../ui/SelectorStepper/SelectorStepper";
import { StyledListBodyContainer } from "./ListBodyContainer";
import ListCardContainer from "./ListCardContainer";
import ListHeader from "./ListHeader";


export interface IListerProps {
  listCardData: ICardProps[],
  count: number;
  componentName: string;
  stepperData: ISelectorStepperProps
}

export const StyledContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 832px;
  margin-inline: auto;
  padding: 24px 16px 40px 16px;
  overflow: auto;
  max-height: 100%;
  @media screen and (min-width:430px)  {
    gap: 24px;
    padding:40px 16px 21px 16px;
  }
  
`


const Lister = ({ listCardData, stepperData, componentName }: any) => {
  return (
    <Layout>
      <StyledContentContainer>
        <ListHeader componentName={componentName} stepperData={stepperData} />

        <StyledListBodyContainer>
          <ListCardContainer>
            {listCardData.map((card: ICardProps, idx: number) => (
              <Card
                phaseType={1}
                key={idx}
                tasksCompleted={card.tasksCompleted}
                tasksTotal={card.tasksTotal}
                componentName={card.componentName}
                phaseTitle={card.phaseTitle}
              />
            ))}
          </ListCardContainer>
        </StyledListBodyContainer>
      </StyledContentContainer>
    </Layout>
  )
}

export default Lister