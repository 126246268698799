import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ref, remove, update } from 'firebase/database'
import { db } from '../../config/config'
import { setPhasesData, TaskObj } from '../../store/reducers/phaseReducerSlice'
import { getTimeStamp } from '../../utils/helpers'
import useIsMobileDevice from '../../hooks/useIsMobileDevice'
import useGetCurrentPhases from '../../hooks/useGetCurrentPhases'
import {
  StyledPhaseDetailsMainContainer,
  StyledPhaseDetailsBody,
  StyledPhaseDetailsFormContainer,
  StyledPhaseDetailsFormDataContainer,
  StyledPhaseDetailsActionButtonContainer,
  StyledDangerZoneContainer,
  StyledDangerZoneHeader,
  StyledDangerZoneTitle,
  StyledDangerZoneDescription
} from '../PhaseDetails/StyledPhaseDetails'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import SubNav from '../../components/ui/SubNav/SubNav'
import Modal from '../../components/ui/Modal/Modal'
import { PhaseDeleteContent } from '../../components/ui/Modal/ModalSlotContents'
import TextArea from '../../components/ui/TextArea/TextArea'
import SelectField from '../../components/ui/Select/Select'
import useGetCurrentTasks from '../../hooks/useGetCurrentTasks'
import { StyledInputLabel, StyledInputLabelContainer } from '../../components/screens/MyAccount/MyAccount'
import { useUpdatePhasesJira } from '../../hooks/api'

interface ComponentProps {
  newPage?: string | undefined
}

const TaskDetails: React.FC<ComponentProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useIsMobileDevice()
  const { templateId, phaseId, taskId } = useParams()
  const { data: allPhases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )
  const currentTeam = useSelector((store: RootState) => store.team.currentTeam)
  const { user } = useSelector((store: RootState) => store.auth)

  const [formData, setFormData] = useState<TaskObj | any>({
    title: '',
    description: '',
    applicationType: 'figma',
    visible: true,
    mandatory: true
  })
  const [initialData, setInitialData] = useState<Partial<TaskObj>>()
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { phases } = useGetCurrentPhases(allPhases, templateId)
  const { tasks } = useGetCurrentTasks(allPhases, templateId)

  const taskRef = ref(db, `phases/${templateId}/${phaseId}/tasks/${taskId}`)

  // fetch phase data and set form data
  useEffect(() => {
    if (!taskId || isLoadingPhases) return

    const currentTask = tasks?.find((task) => task.id === taskId)
    if (currentTask) {
      setFormData({ ...currentTask })
      setInitialData({ ...currentTask })
    }
  }, [isLoadingPhases, taskId, tasks])

  const { mutateAsync, isPending } = useUpdatePhasesJira({
    currentTeam,
    templateId
  })

  // save changes
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (saving || !templateId) return

    if (!formData?.title?.trim()) {
      toast.info('Please provide task title.')
      return
    }

    setSaving(true)
    const timestamp = getTimeStamp()

    try {
      await update(taskRef, {
        ...formData,
        updatedAt: timestamp
      })
      if (user?.jira.access_token) {
        await mutateAsync()
      }
      dispatch(
        setPhasesData({
          data: allPhases?.map((phase) => {
            if (phase.id === phaseId) {
              return {
                ...phase,
                tasks: phase.tasks?.map((task) => {
                  if (task.id === taskId) {
                    return {
                      ...formData,

                      updatedAt: timestamp
                    }
                  }
                  return task
                })
              }
            }

            return phase
          })
        })
      )
      toast.success('All changes are saved.')
      navigate(`/dashboard/templates/${templateId}`)
    } catch (error) {
      toast.error('Something went wrong.')
    } finally {
      setSaving(false)
    }
  }

  // Delete phase
  const deleteTask = async () => {
    if (!templateId || !phaseId || saving || deleting || isLoadingPhases) return

    const unArchivedTasks = phases?.filter((phase) => {
      const tasks = phase?.tasks || []
      return tasks.some((task) => !task?.archived)
    })
    //check if the there are more than 1 phases
    if (unArchivedTasks?.length === 1) {
      toast.error('Sorry, you can not delete the last task in the phase.')
      return
    }

    setDeleting(true)
    try {
      await remove(taskRef)

      dispatch(
        setPhasesData({
          data: allPhases?.filter((phase) => phase.id !== phaseId)
        })
      )

      toast.success('The task has been removed.')
      navigate(`/dashboard/templates/${templateId}`)
    } catch (error) {
      toast.error('Something went wrong.')
    } finally {
      setDeleting(false)
    }
  }

  return (
    <StyledPhaseDetailsMainContainer>
      <SubNav
        title={`Task Details`}
        onBtnClick={() => navigate(`/dashboard/templates/${templateId}`)}
      />
      <StyledPhaseDetailsBody>
        <form onSubmit={handleSave} style={{ width: '100%' }}>
          <StyledPhaseDetailsFormContainer>
            <StyledPhaseDetailsFormDataContainer>
              <InputField
                disabled={saving || isLoadingPhases}
                autoFocus
                required
                fullWidth
                label="Task title"
                placeholder="maximum of 120 characters"
                name="title"
                type="text"
                value={formData.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    title:
                      e.target?.value?.length < 120
                        ? e.target?.value
                        : formData?.title
                  })
                }
              />
              <StyledInputLabelContainer style={{ width: '100%' }}>
                <StyledInputLabel>Task description</StyledInputLabel>
                <TextArea
                  textLabel="Task description"
                  width="100%"
                  value={formData.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData({
                      ...formData,
                      description: e.target.value
                    })
                  }
                />
              </StyledInputLabelContainer>
              <StyledInputLabelContainer style={{ width: '100%' }}>
                <StyledInputLabel>Application</StyledInputLabel>
                <SelectField
                  fullWidth
                  options={[
                    { label: 'Figma', value: 'figma' },
                    { value: 'other', label: 'Other' }
                  ]}
                  value={formData.applicationType}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFormData({
                      ...formData,
                      applicationType: e.target.value
                    })
                  }
                />
              </StyledInputLabelContainer>
            </StyledPhaseDetailsFormDataContainer>

            <StyledPhaseDetailsActionButtonContainer>
              <Button
                type="submit"
                disabled={saving || isLoadingPhases}
                label={saving || isPending ? 'Saving...' : 'SAVE CHANGES'}
                variant="primary"
                size={isMobile ? 'small' : 'medium'}
              />
              <Button
                type="button"
                disabled={saving || isLoadingPhases}
                label="DISCARD CHANGES"
                variant="secondary"
                size={isMobile ? 'small' : 'medium'}
                onClick={() => setFormData({ ...initialData })}
              />
            </StyledPhaseDetailsActionButtonContainer>
          </StyledPhaseDetailsFormContainer>
        </form>
        <StyledDangerZoneContainer>
          <StyledDangerZoneHeader>
            <StyledDangerZoneTitle>Danger zone</StyledDangerZoneTitle>
            <StyledDangerZoneDescription>
              Are you sure you want to delete this task? This action is
              irreversible and will permanently erase this task. If you're sure,
              tap 'Remove Task’ below.
            </StyledDangerZoneDescription>
          </StyledDangerZoneHeader>
          <Button
            type="button"
            size="small"
            disabled={saving || isLoadingPhases || deleting}
            label={deleting ? 'Removing...' : 'REMOVE TASK'}
            variant="danger"
            onClick={() => setShowModal(true)}
            fullWidth={false}
          />
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              signal="alert-triangle"
              signalIconType="warning"
              title="Remove Task"
              slotContent={<PhaseDeleteContent />}
              buttonLabel1="REMOVE TASK"
              buttonLabel2="CANCEL"
              buttonVariant1="danger"
              buttonVariant2="tertiary"
              buttonOnClick1={deleteTask}
            />
          )}
        </StyledDangerZoneContainer>
      </StyledPhaseDetailsBody>
    </StyledPhaseDetailsMainContainer>
  )
}

export default TaskDetails
