export const Jira = ({
  size = 32,
  color = "black",
}: {
  size?: number;
  color: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3718 2.50012H15.3701C15.3701 4.05673 15.9885 5.54957 17.0892 6.65026C18.1898 7.75094 19.6827 8.3693 21.2393 8.3693H23.6343V10.6816C23.6364 13.9202 26.2612 16.545 29.4998 16.547V3.62809C29.4998 3.00513 28.9948 2.50012 28.3718 2.50012Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M21.9382 8.97839H8.93652C8.9386 12.2169 11.5634 14.8417 14.8019 14.8438H17.197V17.1637C17.2011 20.4022 19.8276 23.0253 23.0662 23.0253V10.1064C23.0662 9.4834 22.5612 8.97839 21.9382 8.97839Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M15.5017 15.453H2.5C2.5 18.6945 5.12772 21.3222 8.36918 21.3222H10.7717V23.6345C10.7738 26.8701 13.3941 29.4937 16.6296 29.4999V16.581C16.6296 15.958 16.1246 15.453 15.5017 15.453Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};