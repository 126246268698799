import { HTMLAttributes, RefObject } from 'react'
import { styled } from 'styled-components'
import { theme } from '../../../styles'

interface ISelectField extends HTMLAttributes<HTMLSelectElement> {
  name?: string
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  options: Array<{ label: string; value: string | number }>
  value?: string | number
  defaultValue?: string | number
  selectRef?: RefObject<HTMLSelectElement>
  placeholder?: string;
}

type ISelectFieldProps = ISelectField

const StyledSelectFieldContainer = styled.div<
  Pick<ISelectField, 'fullWidth' | 'disabled'>
>`
  gap: 8px;
  border: 1px solid ${theme.colors.borderDefault};
  padding: 16px;
  border-radius: 4px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: ${({ disabled }) =>
    disabled ? `${theme.colors.actionDisabled}` : 'transparent'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:focus-within {
    border: 1px solid ${theme.colors.actionPrimary};
  }
`

const StyledSelectField = styled.select`
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  color: ${theme.colors.copy};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  overflow: hidden;
  max-height: 100%;

  &:disabled {
    background-color: ${theme.colors.actionDisabled};
  }
`

const SelectField = ({
  fullWidth = false,
  required = true,
  disabled = false,
  name,
  options,
  value,
  defaultValue,
  selectRef,
  onChange,
  placeholder
}: ISelectFieldProps) => {
  return (
    <StyledSelectFieldContainer fullWidth={fullWidth} disabled={disabled}>
      <StyledSelectField
        required={required}
        disabled={disabled}
        name={name}
        value={value}
        defaultValue={defaultValue}
        ref={selectRef}
        onChange={onChange}
      >
        <option value="" disabled>
         {placeholder || "Select a option"}
        </option>

        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </StyledSelectField>
    </StyledSelectFieldContainer>
  )
}

export default SelectField
