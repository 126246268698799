import { useState, useEffect } from 'react'
import { ref, onValue, off, query, orderByChild } from 'firebase/database'
import { db } from '../../../../config/config'
import { useKanbanContext } from '../providers'
import { KanbanColumn } from '..'
import { PhaseObj } from '../../../../store/reducers/phaseReducerSlice'
import { formatRelativeDate } from '../../../../utils/dayjs'

const transformColumns = (
  phases: Record<string, PhaseObj>,
  componentsByPhase: Record<string, any[]>,
  noStatusComponents: any[]
) => {
  // Start with regular phase columns
  const regularColumns = Object.entries(phases).reduce(
    (acc, [phaseId, phase]) => {
      if (phase.archived || !phase.visible) return acc

      const phaseComponents = componentsByPhase[phaseId] || []

      acc[phaseId] = {
        id: phase.id,
        title: phase.title,
        phaseType: parseInt(phase.colorId),
        items: phaseComponents.map((component) => ({
          id: component.id,
          componentName: component.title,
          phaseType: parseInt(phase.colorId),
          tasksCompleted: component.completedTasks?.length || 0,
          tasksTotal: Object.keys(phase.tasks || {}).length,
          date: formatRelativeDate(component.updatedAt)
        }))
      }
      return acc
    },
    {} as Record<string, KanbanColumn>
  )

  // Append no_status column if there are unassigned components
  if (noStatusComponents.length > 0) {
    return {
      ...regularColumns,
      no_status: {
        id: 'no_status',
        title: 'No Status',
        phaseType: 0,
        items: noStatusComponents.map((component) => ({
          id: component.id,
          componentName: component.title,
          phaseType: 0,
          tasksCompleted: component.completedTasks?.length || 0,
          tasksTotal: Object.keys(phases[Object.keys(phases)[0]]?.tasks || {})
            .length,
          date: formatRelativeDate(component.updatedAt as string)
        }))
      }
    }
  }

  return regularColumns
}

export const useKanbanColumns = (templateId: string) => {
  const { components, teamId } = useKanbanContext()
  const [columns, setColumns] = useState<Record<string, KanbanColumn>>({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  console.log({ components })

  useEffect(() => {
    if (!teamId || !templateId) {
      setError('Missing required parameters')
      setLoading(false)
      console.log('Missing required parameters')
      return
    }

    setLoading(true)
    setError(null)

    const phasesRef = query(
      ref(db, `/phases/${templateId}`),
      orderByChild('order')
    )

    const unsubscribe = onValue(
      phasesRef,
      (snapshot) => {
        try {
          if (!snapshot.exists()) {
            setColumns({})
            console.log('No snapshot', snapshot.val())
            setLoading(false)
            return
          }

          const phases: Record<string, PhaseObj> = snapshot.val()
          console.log({ phases })

          // Filter components by templateId
          const templateComponents = components.filter(
            (component) => component?.templateId === templateId
          )

          // Separate components with no active phase
          const noStatusComponents = templateComponents.filter(
            (component) => !component.activePhase
          )

          // Group components by their active phase
          const componentsByPhase = templateComponents.reduce(
            (acc, component) => {
              const phaseId = component.activePhase
              if (phaseId) {
                if (!acc[phaseId]) {
                  acc[phaseId] = []
                }
                acc[phaseId].push(component)
              }
              return acc
            },
            {} as Record<string, any[]>
          )

          // Start with "No Status" column if there are unassigned components
          const transformedColumns = transformColumns(
            phases,
            componentsByPhase,
            noStatusComponents
          )

          // Add regular phase columns
          Object.entries(phases).reduce(
            (acc, [phaseId, phase]) => {
              if (phase.archived || !phase.visible) return acc

              const phaseComponents = componentsByPhase[phaseId] || []

              acc[phaseId] = {
                id: phase.id,
                title: phase.title,
                phaseType: parseInt(phase.colorId),
                items: phaseComponents.map((component) => ({
                  id: component.id,
                  componentName: component.title,
                  phaseType: parseInt(phase.colorId),
                  tasksCompleted: component.completedTasks?.length || 0,
                  tasksTotal: Object.keys(phase.tasks || {}).length,
                  date: formatRelativeDate(component.updatedAt)
                }))
              }
              return acc
            },
            transformedColumns as Record<string, KanbanColumn>
          )

          setColumns(transformedColumns as Record<string, KanbanColumn>)
          setLoading(false)
          setError(null)
        } catch (err: any) {
          setError(err.message)
          setLoading(false)
        }
      },
      (error) => {
        setError(`Failed to fetch phases: ${error.message}`)
        setLoading(false)
      }
    )

    return () => {
      off(phasesRef)
      unsubscribe()
    }
  }, [teamId, templateId, components])

  return { columns, loading, error, setColumns }
}
