import { styled } from "styled-components";
import { theme } from "../../../styles";
import { ICardProps } from "../../ui/Card/Card";
import Button from "../../ui/Button/Button";
// import PhaseLabel from "../../ui/PhaseLabel/PhaseLabel";
import { NavLink } from "react-router-dom";
import PhaseStatus from "../../ui/PhaseStatus/PhaseStatus";
import UpdateComponent from './UpdateComponent'

const StyledDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const StyledComponentNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const StyledCountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  order: 1;

  @media screen and (min-width: 430px) {
    order: -1;
  }
`;

const StyledCount = styled.p`
  color: ${theme.colors.copy};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`;
const StyledHeaderComponentName = styled.h3`
  color: ${theme.colors.copy};
  font-size: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "rooney";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media screen and (min-width: 430px) {
    font-size: 48px;
  }
`;

const StyledHeaderNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 430px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
  }
`;

type IDetailHeaderProps = Omit<ICardProps, "fullWidth" | "disabled">;

const DetailHeader = ({
  tasksCompleted: completedTasks,
  tasksTotal: totalTasks,
  componentName,
  phaseTitle,
  figmaFileUrl,
  bgColor,
  textColor
  // componentId,
  // componentType = "component"
}: IDetailHeaderProps) => {
  return (
    <StyledDetailHeader>
      <StyledComponentNameContainer>
        <StyledHeaderNameContainer>
          <StyledCountContainer>
            <StyledCount>{completedTasks}</StyledCount>
            <StyledCount>/</StyledCount>
            <StyledCount>{totalTasks}</StyledCount>
          </StyledCountContainer>
          <StyledHeaderComponentName>{componentName}</StyledHeaderComponentName>
        </StyledHeaderNameContainer>
        {/* <PhaseLabel
          phaseTitle={phaseTitle || ""}
          bgColor={bgColor}
          textColor={textColor}
        /> */}
        <PhaseStatus
          phaseTitle={phaseTitle || 'NO STATUS'}
          bgColor={bgColor}
          textColor={textColor}
          isClickable={false}
          isActive={true}
          fullWidth={false}
          noStatus={!phaseTitle}
        />
      </StyledComponentNameContainer>
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        {figmaFileUrl ? (
          <NavLink to={figmaFileUrl || '/'} target="_blank">
            <Button label="GO TO FIGMA PAGE" variant="secondary" />
          </NavLink>
        ) : (
          <Button label="GO TO FIGMA PAGE" variant="secondary" disabled />
        )}
        <UpdateComponent
        // componentId={componentId}
        // componentType={componentType}
        />
      </div>
    </StyledDetailHeader>
  )
}

export default DetailHeader;