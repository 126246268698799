import { styled } from 'styled-components'
import { theme } from '../../styles'
import GoogleSVG from '../assets/images/googleIcon.svg'

export const StyledMainContainer = styled.main<{ padding?: string  }>`
  background-color: ${theme.colors.white};
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: ${({ padding }) => padding || "15px 0" };
  
  @media screen and (min-width: 834px) {
    background-color: ${theme.colors.white};
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    min-height: -webkit-fill-available;
  }
`

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  flex-shrink: 0;
  width: 100%;
  max-width: 568px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${theme.colors.white};
  margin: 2rem auto;

  @media screen and (min-width: 834px) {
    padding: 40px 80px;
    border: 1px solid ${theme.colors.black};
  }
`

export const StyledHeaderAction = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 80px;
  background: ${theme.colors.backgroundLight};
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  & > a {
    color: ${theme.colors.actionPrimary};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const StyledHeaderLabel = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding-bottom: 32px;
  // border-bottom: 4px solid ${theme.colors.borderDefault};
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

export const StyledHeaderText = styled.h3`
  color: ${theme.colors.copy};
  font-size: 48px;
  font-weight: 500;
  line-height: 140%;
  font-family: rooney;

  @media screen and (min-width: 430px) {
    font-size: 64px;
  }
`

export const StyledCreateAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  align-self: stretch;
`

export const StyledSecondaryText = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
`

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
export const StyledConfirmType = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > a {
    color: ${theme.colors.actionPrimary};
  }
`

export const StyledCreateFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`

export const StyledLink = styled.a`
  color: ${theme.colors.actionPrimary};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

// onboarding
export const StyledOnboardingFormWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 16px 2px;
  width: 100%;
  background-color: ${theme.colors.white};
  // padding: 2rem;
  
  @media screen and (min-width: 834px) {
    // padding: 40px 80px;
    flex-wrap: nowrap;
  }
`

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  flex-shrink: 0;
  width: 100%;
  max-width: 470px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${theme.colors.white};
  margin: 2rem auto;
  border-radius: 16px;

  @media screen and (min-width: 834px) {
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.1), 0px 36px 36px 0px rgba(0, 0, 0, 0.09), 0px 80px 48px 0px rgba(0, 0, 0, 0.05), 0px 143px 57px 0px rgba(0, 0, 0, 0.01), 0px 223px 62px 0px rgba(0, 0, 0, 0);
    margin: 2rem 0;
    padding: 40px;
    border: 1px solid ${theme.colors.borderDefault};
  }
`

export const StyledParagraphText = styled.h3`
  color: ${theme.colors.copy};
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  align-self: flex-start;
  margin-top: 16px;
  
  @media screen and (min-width: 834px) {
    font-size: 24px;
  }
`
export const StyledOnboardingImageWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  display: none;

  @media screen and (min-width: 834px) {
    display: block;
    // width: 50%;
    // max-width: 50%;
  }
`

export const StyledOnboardingImage = styled.img`
 max-width: 100%;
  object-fit: contain;
  width: 100%;
  
  @media screen and (min-width: 834px) {
    max-width: 670px;
    width: 100%;
  }
`

export const GoogleIcon = () => {
  return <img src={GoogleSVG} alt="google" />
}
