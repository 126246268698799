import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// wrapper
import App from '../App'
import ProtectedRouter from './ProtectedRouter'
// Dashboard Layout
import DashboardLayout from '../pages/Dashboard'
// pages
import NotFoundPage from '../pages/NotFound'
// Dashboard pages
// template
import Templates from '../pages/Templates'
import SingleTemplate from '../pages/SingleTemplate'
import TemplateEdit from '../pages/TemplateEdit'
import CreateTemplate from '../pages/CreateTemplate'
// phase
import PhaseDetails from '../pages/PhaseDetails'
// component
import AllComponents from '../pages/ComponentsList'
import ComponentDetail from '../pages/ComponentDetail'
// project
import CreateProject from '../pages/CreateProject'
import AllProjects from '../pages/ProjectList'
import ProjectDetail from '../pages/ProjectDetail'
import ProjectEdit from '../pages/ProjectEdit'
// Profile & team
import Profile from '../pages/Profile'
import Teams from '../pages/Teams'
import Team from '../pages/Team'
// import AddNewUser from '../pages/AddNewUser'
// import EditUser from '../pages/EditUser'
import Invitations from '../pages/Invitations'
// public routes
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy'
import TermsOfServicePage from '../pages/TermsOfService/TermsOfService'
import CookiePolicy from '../pages/CookiePolicy/CookiePolicy'
// Auth routes
import CheckModePage from '../pages/Auth/CheckMode'
import VerifyLogin from '../pages/Auth/VerifyLogin'
import GetAuthPage from '../pages/Auth/GetAuthPage'
import WidgetAuthSuccess from '../pages/Auth/WidgetAuthSuccess'
import WidgetAuthFailed from '../pages/Auth/WidgetAuthFailed'
// ******  Others **********
import DrawerContextProvider from '../context/DrawerContext'
import TaskDetails from '../pages/TaskDetails'
import TemplateBuilderPage, { TemplateBuilderLayout } from '../pages/AiTemlate'
import WebsitePublicationPage from '../pages/WebsitePublication'
import AtlassianCallback from '../components/screens/Atlassian/AtlassianCallback'

// ========= This is a app router contains all the routes of the websites.
//  with authentication and user roles base routes
const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          // <PassCodeWrapper>
          <App />
          // </PassCodeWrapper>
        }
      >
        <Route
          index
          element={
            <ProtectedRouter redirectPath="/">
              <Navigate to="/dashboard/templates" />
            </ProtectedRouter>
          }
        />
        {/* -------- Dashboard routes, secured ------- */}
        <Route
          path="/dashboard"
          element={
            <DrawerContextProvider>
              <ProtectedRouter redirectPath="/">
                <DashboardLayout />
              </ProtectedRouter>
            </DrawerContextProvider>
          }
        >
          <Route path="*" element={<> COMING SOON </>} />
          <Route index element={<Templates />} />
          <Route path="templates" element={<Templates />} />
          <Route path="templates/:id" element={<SingleTemplate />} />
          <Route path="templates/edit/:id" element={<TemplateEdit />} />
          <Route
            path="templates/:id/phase/edit/:phaseId"
            element={<PhaseDetails />}
          />
          <Route
            path="templates/:templateId/:phaseId/edit/:taskId"
            element={<TaskDetails />}
          />
          <Route path="templates/create" element={<CreateTemplate />} />
          {/* ===== Components ========== */}
          <Route path="components" element={<AllComponents />} />
          <Route path="components/:id/:pageId" element={<ComponentDetail />} />
          {/* ===== Projects ========== */}
          <Route path="projects" element={<AllProjects />} />
          <Route path="projects/create" element={<CreateProject />} />
          <Route path="projects/:projectId" element={<ProjectDetail />} />
          <Route path="projects/edit/:projectId" element={<ProjectEdit />} />
          {/* ===== Profile, team & user ========== */}
          <Route path="profile" element={<Profile />} />
          <Route path="teams" element={<Teams />} />
          <Route path="team" element={<Team />} />
          {/* <Route path="user/:id" element={<EditUser />} /> */}
          {/* <Route path="team/user/new" element={<AddNewUser />} /> */}
        </Route>

        <Route
          path="/atlassian/callback"
          element={
            <ProtectedRouter redirectPath="/">
              <AtlassianCallback />
            </ProtectedRouter>
          }
        />

        <Route path="/ai-template" element={<TemplateBuilderLayout />}>
          <Route index element={<TemplateBuilderPage />} />
        </Route>

        {/* -------- other public routes -------- */}
        <Route
          path="/published/:userIdOrSlug"
          element={<WebsitePublicationPage />}
        />
        <Route path="/checkmode" element={<CheckModePage />} />
        <Route path="/verify-login" element={<VerifyLogin />} />
        <Route path="/get-auth" element={<GetAuthPage />} />
        <Route path="/widget-auth-success" element={<WidgetAuthSuccess />} />
        <Route path="/widget-auth-failed" element={<WidgetAuthFailed />} />
        {/* ------ Accept invitation ------- */}
        <Route
          path="invitations/:teamId/:receiptId"
          element={
            <ProtectedRouter redirectPath="/">
              <Invitations />
            </ProtectedRouter>
          }
        />
        {/* ----- Others ---- */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-services" element={<TermsOfServicePage />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
