/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import ViewOptions from './ViewOptions'
import Button from '../Button/Button'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { theme } from '../../../styles'
import Search from '../Search/Search'
import FadeInFadeOut from '../Animation/FadeInFadeOut/FadeInFadeOut'
import FilterLabel from '../FilterLabel/FilterLabel'
import BottomSheet from '../BottomSheet/BottomSheet'
import MenuHeader from '../MenuHeader/MenuHeader'
import RowItem from '../RowItem/RowItem'
import type { IIconNames } from '../IconComponent/IconComponent'
import HorizontalFadeInFadeOut from '../Animation/HorizontalFadeInFadeOut/HorizontalFadeInFadeOut'
import { useSort } from '../kanban/hooks/useSort'
import { DrawerContext } from '../../../context/DrawerContext'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import TemplateSelectField from './TemplateSelectField'
import { useKanbanContext } from '../kanban/providers'
import { useSearchParams } from 'react-router-dom'
import { IDropdownItem } from '../DropDown/DropDown'

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px;
`

const StyledFiltersMobileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 16px;
`

const StyledFilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  max-height: 56px;
  width: 100%;
`

const StyledFilterBarItemsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledFilterMobileButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const StyledFilterLabelsContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`

const StyledFilterRowItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 300px;
  max-height: 300px;
  overflow-y: scroll;
`

const AnimatedSearchButton = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isSearchHidden, setIsSearchHidden] = useState(true)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsSearchHidden(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div>
      {isSearchHidden ? (
        <Button
          onClick={() => setIsSearchHidden(false)}
          label="Search"
          size="s"
          variant="tertiary"
          iconOnly
          icon="search"
          iconColor={theme.colors.actionPrimary}
        />
      ) : (
        <div ref={containerRef}>
          <FadeInFadeOut show={!isSearchHidden}>
            <Search onClear={() => setIsSearchHidden(true)} />
          </FadeInFadeOut>
        </div>
      )}
    </div>
  )
}

interface IFilter {
  id: string
  title: string
  icon: IIconNames
  items:
    | {
        id: string
        name: string
        selected: boolean
      }[]
    | []
}

const generateSelectedFilterLabel = (
  selectedItems: IFilter['items'][number][]
) => {
  if (selectedItems.length === 0) return null
  if (selectedItems.length < 3)
    return selectedItems.map((item) => item.name).join(', ')
  return `${selectedItems.length} selected`
}

const Filters = () => {
  const isMobile = useIsMobileDevice()
  const [isFilterHidden, setIsFilterHidden] = useState(false)
  const { setIsFullScreen, isFullScreen } = useContext(DrawerContext)

  const { sortConfig, handleSort, updateSortedComponents } = useSort()
  const {
    selectedUser,
    setSelectedUser,
    selectedPhase,
    setSelectedPhase,
    components,
    setComponents,
    isLoading: isComponentLoading
  } = useKanbanContext()

  const { data: phases, isLoading: isPhaseLoading } = useSelector(
    (state: RootState) => state.phase
  )
  const { currentTeam, isLoading: isTeamLoading } = useSelector(
    (state: RootState) => state.team
  )

  // Transform phases to filter options
  const phaseFilterOptions = useMemo(() => {
    if (!phases) return []

    return (
      Object.values(phases)
        // .filter((phase) => !phase.archived && phase.visible)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((phase) => ({
          id: phase.id,
          name: phase.title,
          description: phase.description,
          selected: false,
          type: 'phase' as const,
          order: phase.order || 0,
          colorId: phase.colorId
        }))
    )
  }, [phases])

  const usersFilterOptions = useMemo(() => {
    if (!currentTeam?.members) return []

    return Object.entries(currentTeam.members)
      .filter(([_, member]) => member.pendingInvite === false)
      .map(([userId, member]) => ({
        id: member.id,
        name: member.email,
        avatar: member.profileImg,
        role: member.role,
        selected: false,
        type: 'user' as const,
        email: member.email
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [currentTeam?.members])

  const updateSort = () => {
    handleSort('updatedAt')
    updateSortedComponents()
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const isInitializedRef = useRef(false)
  const originalComponentsRef = useRef<typeof components>([])

  // Initialize original components once
  useEffect(() => {
    if (components.length && !isInitializedRef.current) {
      originalComponentsRef.current = [...components]
      isInitializedRef.current = true
    }
  }, [components])

  // Filter logic
  const applyFilters = useCallback((userIds: string[], phaseIds: string[]) => {
    let filtered = [...originalComponentsRef.current]

    if (userIds.length > 0) {
      filtered = filtered.filter((component) =>
        userIds.includes(String(component.createdBy))
      )
    }

    if (phaseIds.length > 0) {
      filtered = filtered.filter((component) =>
        phaseIds.includes(String(component.activePhase))
      )
    }

    return filtered
  }, [])

  // Handle filter changes
  useEffect(() => {
    if (!isInitializedRef.current) return

    const userIds = searchParams.get('userId')?.split(',').filter(Boolean) || []
    const phaseIds =
      searchParams.get('phaseId')?.split(',').filter(Boolean) || []

    // Update selected states
    if (userIds.length > 0) {
      const selectedUsers = usersFilterOptions.filter((user) =>
        userIds.includes(String(user.id))
      )
      setSelectedUser(selectedUsers)
    } else {
      setSelectedUser([])
    }

    if (phaseIds.length > 0) {
      const selectedPhases = phaseFilterOptions.filter((phase) =>
        phaseIds.includes(String(phase.id))
      )
      setSelectedPhase(selectedPhases)
    } else {
      setSelectedPhase([])
    }

    // Apply filters or reset to original
    const filteredComponents =
      userIds.length || phaseIds.length
        ? applyFilters(userIds, phaseIds)
        : originalComponentsRef.current

    setComponents(filteredComponents)
  }, [
    searchParams,
    usersFilterOptions,
    phaseFilterOptions,
    applyFilters,
    setComponents
  ])

  // Handle filter selection
  const handleFilterChange = useCallback(
    (type: 'user' | 'phase', selected: IDropdownItem[]) => {
      const params = new URLSearchParams(searchParams)
      const paramKey = type === 'user' ? 'userId' : 'phaseId'

      if (selected.length > 0) {
        params.set(paramKey, selected.map((item) => item.id).join(','))
      } else {
        params.delete(paramKey)
      }

      setSearchParams(params)
    },
    [searchParams, setSearchParams]
  )

  const handleResetFilters = useCallback(() => {
    setSelectedUser([])
    setSelectedPhase([])
    const currentParams = new URLSearchParams(searchParams)
    currentParams.delete('userId')
    currentParams.delete('phaseId')
    setSearchParams(currentParams)

    // Reset to original components
    if (originalComponentsRef.current) {
      setComponents(originalComponentsRef.current)
    }
  }, [searchParams, setSearchParams, setComponents])

  const [selectedMobileFilter, setSelectedMobileFilter] =
    useState<IFilter | null>(null)

  if (isMobile) {
    return (
      <>
        <StyledFiltersMobileContainer>
          <ViewOptions isMobile={true} />
          <Search width="100%" />
          <StyledFilterMobileButtonContainer>
            <Button
              label={
                sortConfig.order === 'asc' ? 'sort results' : 'sort results'
              }
              onClick={updateSort}
              size="xs"
              variant="secondary"
              iconOnly={false}
              iconPosition="after"
              iconColor={theme.colors.actionPrimary}
              icon={sortConfig.order === 'asc' ? 'arrow-up' : 'arrow-down'}
            />

            <Button
              label={isFilterHidden ? 'Show filters' : 'Hide filters'}
              onClick={() => setIsFilterHidden(!isFilterHidden)}
              size="xs"
              variant="secondary"
              iconOnly={false}
              iconPosition="after"
              iconColor={theme.colors.actionPrimary}
              icon={isFilterHidden ? 'plus' : 'minus'}
            />
          </StyledFilterMobileButtonContainer>
        </StyledFiltersMobileContainer>
        <BottomSheet
          sheetProps={{
            snapPoints: [390],
            title: 'Filters',
            disableDrag: true
          }}
          open={!isFilterHidden}
          onClose={() => setIsFilterHidden(true)}
        >
          <MenuHeader
            state={selectedMobileFilter ? 'nestedItem' : 'default'}
            title={selectedMobileFilter?.title ?? 'Filters'}
            handleClose={() => setIsFilterHidden(true)}
            handleBack={() => setSelectedMobileFilter(null)}
          />

          {/* <StyledFilterRowItemContainer>
            <HorizontalFadeInFadeOut show={selectedMobileFilter === null}>
              {!selectedMobileFilter &&
                filters.map((filter) => (
                  <RowItem
                    title={filter.title}
                    id={filter.id}
                    aria-selected={false}
                    size="m"
                    icon={filter.icon}
                    showIcon
                    showAction
                    actionIcon="chevron-right"
                    onClick={() => {
                      setSelectedMobileFilter(filter)
                    }}
                    handleAction={() => {
                      setSelectedMobileFilter(filter)
                    }}
                    showDescription={true}
                    description={
                      generateSelectedFilterLabel(
                        filter.items?.filter((item) => item.selected)
                      ) || ''
                    }
                  />
                ))}
            </HorizontalFadeInFadeOut>

            <HorizontalFadeInFadeOut show={!!selectedMobileFilter}>
              {selectedMobileFilter &&
                selectedMobileFilter.items.map((item) => (
                  <RowItem
                    title={item.name}
                    id={item.id}
                    aria-selected={item.selected}
                    size="m"
                    showCheckBox
                    checked={item.selected}
                    onCheckedChange={() => {}}
                  />
                ))}
              {selectedMobileFilter?.items &&
                selectedMobileFilter?.items?.length > 0 && (
                  <Button
                    style={{
                      marginTop: '30px',
                      position: 'sticky',
                      bottom: '0',
                      backgroundColor: 'white',
                      borderRadius: '0'
                    }}
                    fullWidth
                    variant="tertiary"
                    size={'m'}
                    label={'Clear filters'}
                    onClick={() => {}}
                  />
                )}
            </HorizontalFadeInFadeOut>
          </StyledFilterRowItemContainer> */}
        </BottomSheet>
      </>
    )
  }

  return (
    <StyledFiltersContainer>
      <StyledFilterBar>
        <StyledFilterBarItemsContainer>
          <ViewOptions isMobile={false} />
          <TemplateSelectField />
          <Button
            label={isFilterHidden ? 'Show filters' : 'Hide filters'}
            onClick={() => setIsFilterHidden(!isFilterHidden)}
            size="xs"
            variant="secondary"
            iconOnly={false}
            iconPosition="after"
            iconColor={theme.colors.actionPrimary}
            icon={isFilterHidden ? 'plus' : 'minus'}
          />
        </StyledFilterBarItemsContainer>
        <StyledFilterBarItemsContainer>
          <AnimatedSearchButton />
          <Button
            iconOnly
            title="Sort"
            onClick={updateSort}
            size="s"
            variant="tertiary"
            icon={sortConfig.order === 'asc' ? 'arrow-up' : 'arrow-down'}
            iconColor={theme.colors.actionPrimary}
          />
          <Button
            iconOnly
            title="Expand"
            onClick={() => {
              setIsFullScreen((prev) => !prev)
            }}
            size="s"
            variant="tertiary"
            icon={isFullScreen ? 'minimize-2' : 'maximize-2'}
            iconColor={theme.colors.actionPrimary}
          />
        </StyledFilterBarItemsContainer>
      </StyledFilterBar>

      <FadeInFadeOut show={!isFilterHidden}>
        <StyledFilterLabelsContainer>
          {/* <FilterLabel label="Activity" items={[]} /> */}
          {/* <FilterLabel label="Progress" items={[]} /> */}
          <FilterLabel
            placeholder="Filter by user"
            label="User"
            items={usersFilterOptions}
            isLoading={isTeamLoading}
            selectedItems={selectedUser}
            onChange={(item) => {
              console.log({ item })
              setSelectedUser(item)

              // Get current URL parameters
              const currentParams = new URLSearchParams(window.location.search)
              const newParams = new URLSearchParams()

              // Preserve all existing parameters
              currentParams.forEach((value, key) => {
                if (key !== 'userId') {
                  newParams.set(key, value)
                }
              })

              // Add new user IDs if they exist
              if (item && item.length > 0) {
                newParams.set('userId', item.map((i) => i.id).join(','))
              }

              setSearchParams(newParams)
            }}
          />
          {/* <FilterLabel label="Device" items={[]} /> */}
          <FilterLabel
            placeholder="Filter by phase"
            label="Phase"
            items={phaseFilterOptions}
            isLoading={isPhaseLoading}
            selectedItems={selectedPhase}
            onChange={(item) => {
              setSelectedPhase(item)

              // Get current URL parameters
              const currentParams = new URLSearchParams(window.location.search)
              const newParams = new URLSearchParams()

              // Preserve all existing parameters
              currentParams.forEach((value, key) => {
                if (key !== 'phaseId') {
                  newParams.set(key, value)
                }
              })

              // Add new phase IDs if they exist
              if (item && item.length > 0) {
                newParams.set('phaseId', item.map((i) => i.id).join(','))
              }

              setSearchParams(newParams)
            }}
          />
          <Button
            label="RESET"
            variant="tertiary"
            size="s"
            icon="x"
            onClick={handleResetFilters}
          />
        </StyledFilterLabelsContainer>
      </FadeInFadeOut>
    </StyledFiltersContainer>
  )
}

export default Filters
