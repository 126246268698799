import useIsMobileDevice from '../../../../hooks/useIsMobileDevice'
import {
  phaseTypeBackgroundMap,
  phaseTypeColorMap,
  theme
} from '../../../../styles/theme'
import Counter from '../../Counter/Counter'
import ExpandCollapse from '../../ExpandCollapse/ExpandCollapse'
import PhaseStatus from '../../PhaseStatus/PhaseStatus'
import { StyledKanbanColumnHeader } from '../kanban.styles'

const KanbanColumnHeader = ({
  title,
  phaseType,
  isActive,
  count,
  isCollapsed,
  setIsCollapsed
}: {
  title: string
  phaseType?: number
  isActive: boolean
  count: number
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
}) => {
  const isMobile = useIsMobileDevice()
  return (
    <StyledKanbanColumnHeader $isCollapsed={isCollapsed}>
      <PhaseStatus
        fullWidth={true}
        isActive={isActive}
        phaseTitle={title}
        phaseType={(phaseType as keyof typeof phaseTypeColorMap) ?? undefined}
        bgColor={
          phaseType
            ? phaseTypeBackgroundMap[
                phaseType as keyof typeof phaseTypeBackgroundMap
              ]
            : undefined
        }
        textColor={
          phaseType
            ? phaseTypeColorMap[
                phaseType as keyof typeof phaseTypeBackgroundMap
              ]
            : undefined
        }
        noStatus={!phaseType}
      />
      <Counter count={count} noHover />
      {isMobile && (
        <ExpandCollapse
          collapse={isCollapsed}
          type="border"
          onClick={() => setIsCollapsed(!isCollapsed)}
          color={theme.colors.actionPrimary}
        />
      )}
    </StyledKanbanColumnHeader>
  )
}

export default KanbanColumnHeader
