import styled from 'styled-components'
import { motion } from 'framer-motion'
import TabNav from './TabNav'
import { theme } from '../../../../styles'
import { WORKFLOW_OPTION } from '../../../../constant'
import Rule from './Rule'
import Button from '../../Button/Button'
import {
  useWorkflow,
  WorkflowActionType
} from '../../../../context/WorkflowActionProvider'
import { useFormContext } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import httpRequest from '../../../../utils/fetcher'
import { nest } from '../../../../utils/axios'
import { auth } from '../../../../config/config'
import { toast } from 'react-toastify'
import { ConditionallyRender } from '../../../../utils/ConditionallyRender'
import { UserRole } from '../../../../types/user'
import { useMemo } from 'react'
import { ATLASSIAN_AUTH_CHECK } from '../../../../pages/workflows/hooks/useCheckAtlassianAuth'

const StyledExpandCollapseFooter = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`

export const StyledRules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const StyledAuthorized = styled.div<{ $isMobile: boolean }>`
  padding: ${({ $isMobile }) => ($isMobile ? '24px' : '32px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: ${theme.colors.actionPrimaryLight};
  border-radius: 8px;

  p {
    font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '20px')};
  }
`

export const StyledExpandCollapseContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`

const WorkFlowExpandCollapseContent = () => {
  const {
    option: selectedOption,
    isJiraAuthorized,
    device,
    onSubmit,
    isLoading,
    jiraProjectOptions: projectOptions,
    userRole,
    setWorkflowTypes,
    workflowTypes,
    onClickTrigger,
    details
  } = useWorkflow()
  const { user } = useSelector((store: RootState) => store.auth)
  const { data } = useSelector((store: RootState) => store.phase)
  const { getValues, watch, reset } = useFormContext<WorkflowActionType>()
  const jiraWorkflowId = watch('jira.id')
  const mailWorkflowId = watch('mail.id')
  const templateId = useParams<{ id: string }>().id
  const { currentTeam } = useSelector((store: RootState) => store.team)

  const teamId = currentTeam?.id

  const handleSubmit = () => {
    const data = getValues()

    if (selectedOption === 'email') {
      onSubmit({
        mail: {
          details: data?.mail?.details || [],
          recipient: data?.mail?.recipient || [],
          templateId: templateId || '',
          teamId: teamId || '',
          when: data?.mail?.when || [],
          then: data?.mail?.then || []
        }
      })
    } else {
      onSubmit({
        jira: {
          details: data?.jira?.details || [],
          recipient: data?.jira?.recipient || [],
          templateId: templateId || '',
          teamId: teamId || '',
          jiraProject: data?.jira?.jiraProject || [],
          syncWithJira: data?.jira?.syncWithJira || [],
          when: data?.jira?.when || [],
          then: data?.jira?.then || []
        }
      })
    }
  }

  const queryClient = useQueryClient()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      const token = await auth.currentUser?.getIdToken()
      return httpRequest({
        url: `/workflows/${teamId}/${selectedOption === 'jira' ? jiraWorkflowId : mailWorkflowId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })(nest)
    },
    onError: (error) => {
      toast.error(error.message || 'An error occurred')
    },
    onSuccess: async () => {
      reset()
      toast.success('Workflow removed successfully')
      await queryClient.refetchQueries({ queryKey: [ATLASSIAN_AUTH_CHECK] })
      setWorkflowTypes(workflowTypes.filter((type) => type !== selectedOption))
      onClickTrigger()
    }
  })

  const handleRemove = async () => {
    try {
      // Check if workflow exists in database
      const isWorkflowInDatabase =
        details?.availableWorkflows?.[
          `has${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}` as
            | 'hasJira'
            | 'hasEmail'
        ]

      if (isWorkflowInDatabase) {
        // Remove from database if it exists
        await mutateAsync()

        // Refetch workflow data
        await queryClient.refetchQueries({ queryKey: ['getWorkflow'] })
        await queryClient.refetchQueries({ queryKey: ['atlassianAuthCheck'] })
      }

      // Update local state
      setWorkflowTypes((prev) => prev.filter((type) => type !== selectedOption))
      onClickTrigger()

      // Show success message
      toast.success(
        isWorkflowInDatabase
          ? 'Workflow removed successfully'
          : 'Workflow configuration removed'
      )
    } catch (error) {
      console.error('Error removing workflow:', error)
      toast.error('Failed to remove workflow')
    }
  }

  const phasesOptions = useMemo(() => {
    return data
      ?.filter((phase) => phase.templateId === templateId)
      .map((phase) => ({
        label: `when ${phase.title} is active`,
        value: phase.id
      }))
  }, [data, templateId])

  return (
    <StyledExpandCollapseContent
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{
        height: 0,
        opacity: 0
      }}
      transition={{
        type: 'tween',
        ease: 'easeOut',
        duration: 0.2
      }}
    >
      {isJiraAuthorized || selectedOption === 'email' ? (
        <>
          <StyledRules>
            <ConditionallyRender
              condition={!details?.workflowExists}
              show={<TabNav />}
            />
            {WORKFLOW_OPTION({ projectOptions, phasesOptions })[
              selectedOption as 'email' | 'jira'
            ].rules.map((rule, i) => {
              return (
                <Rule
                  title={rule.title}
                  type={rule.type}
                  key={i}
                  name={rule.name as any}
                />
              )
            })}
          </StyledRules>

          <ConditionallyRender
            condition={userRole !== UserRole.EDITOR}
            show={
              <StyledExpandCollapseFooter>
                <Button
                  size="xs"
                  type="button"
                  variant="secondary"
                  label="SAVE CHANGES"
                  disabled={
                    (selectedOption === 'jira' && !isJiraAuthorized) ||
                    isLoading
                  }
                  onClick={() => {
                    handleSubmit()
                  }}
                  loading={isLoading}
                />
                <Button
                  size="xs"
                  type="button"
                  variant="danger"
                  label="REMOVE ACTION"
                  onClick={async () => {
                    await handleRemove()
                    reset({
                      jira: {
                        details: ['component_name'],
                        templateId: templateId,
                        teamId: teamId
                      },
                      mail: {
                        details: ['component_name'],
                        templateId: templateId,
                        teamId: teamId
                      }
                    })
                    onClickTrigger()
                  }}
                  loading={isPending}
                  disabled={
                    (selectedOption === 'jira' && !isJiraAuthorized) ||
                    isLoading ||
                    isPending
                  }
                />
              </StyledExpandCollapseFooter>
            }
          />
        </>
      ) : selectedOption === 'jira' ? (
        <StyledAuthorized $isMobile={device === 'mobile'}>
          <p>Begin by authorizing Jira</p>
          <Link
            to={`${import.meta.env.VITE_JIRA_AUTH_URL as string}&state=${currentTeam?.id}::${templateId}`}
            target="_blank"
          >
            <Button
              variant="primary"
              size={device === 'mobile' ? 's' : 'm'}
              fullWidth
              label={`AUTHORIZE Jira`}
            />
          </Link>
        </StyledAuthorized>
      ) : null}
    </StyledExpandCollapseContent>
  )
}

export default WorkFlowExpandCollapseContent
