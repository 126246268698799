import { FeatherIconNames } from "feather-icons";
import React, { forwardRef, ReactNode } from 'react'
import styled, { css } from "styled-components";
import Loader from "../Loader/Loader";
import IconComponent from "../IconComponent/IconComponent";
import { theme } from "../../../styles";

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'utility'
  inverted?: boolean
  size?: 'xs' | 'small' | 'medium' | 's' | 'm'
  fullWidth?: boolean
  label?: string
  icon?: FeatherIconNames
  iconPosition?: 'before' | 'after'
  iconOnly?: boolean
  onClick?: any
  customIcon?: ReactNode
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'utility'
  loading?: boolean
  ref?: any
  noBorder?: boolean
  warn?: boolean
  iconColor?: string
}

const StyledButton = styled.button<IButtonProps>`
  /* Shared styles */
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
  transition: all 200ms ease-in-out;

  /* Variant styles */
  ${({ variant = 'primary', inverted, theme, customIcon, noBorder }) => {
    const variantColor = inverted
      ? theme.colors.button.color[variant]?.inverted
      : theme.colors.button.color[variant]?.nonInverted

    const {
      default: defaultColor,
      hover,
      active,
      disabled
    } = variantColor.background
    const {
      default: defaultBorder,
      hover: borderHover,
      active: borderActive,
      disabled: borderDisabled
    } = variantColor.border
    const {
      default: defaultLabel,
      hover: labelHover,
      active: labelActive,
      disabled: labelDisabled
    } = variantColor.label
    const {
      default: defaultIcon,
      hover: iconHover,
      active: iconActive,
      disabled: iconDisabled
    } = variantColor.icon

    const withBorderVariants = ['danger', 'secondary']

    return css`
      background-color: ${defaultColor};
      color: ${defaultLabel};
      border: ${withBorderVariants.includes(variant) && !noBorder
        ? `1px solid ${defaultBorder}`
        : 'none'};

      &:hover {
        background-color: ${hover};
        color: ${labelHover};
        border-color: ${borderHover};
      }

      &:active {
        background-color: ${active};
        color: ${labelActive};
        border-color: ${borderActive};
      }

      &:disabled {
        background-color: ${disabled};
        color: ${labelDisabled};
        border-color: ${borderDisabled};
        cursor: not-allowed;
      }

      ${!customIcon &&
      css`
        & > span {
          color: ${defaultLabel};
        }

        &:active > span {
          color: ${labelActive};
        }

        &:hover > span {
          color: ${labelHover};
        }

        &:disabled > span {
          color: ${labelDisabled};
        }

        & > svg {
          stroke: ${defaultIcon};
        }

        &:active > svg {
          stroke: ${iconActive};
        }

        &:hover > svg {
          stroke: ${iconHover};
        }

        &:disabled > svg {
          stroke: ${iconDisabled};
        }
      `}
    `
  }}

  /* Size styles */
  ${({ size, theme, iconOnly, loading, icon, iconPosition }) => {
    const { sizes } = theme.font

    const withIconPaddingMap = {
      xs: {
        before: '2px 16px 2px 4px',
        after: '2px 4px 2px 16px'
      },
      small: {
        before: '4px 16px 4px 4px',
        after: '4px 4px 4px 16px'
      },
      s: {
        before: '4px 16px 4px 4px',
        after: '4px 4px 4px 16px'
      },
      medium: {
        before: '8px 24px 8px 8px',
        after: '8px 8px 8px 24px'
      },
      m: {
        before: '8px 24px 8px 8px',
        after: '8px 8px 8px 24px'
      }
    }

    switch (size) {
      case 'xs':
        return css`
          font-size: ${sizes.small};
          padding: ${iconOnly || loading
            ? '4px'
            : icon && size && iconPosition
              ? withIconPaddingMap[size][iconPosition]
              : '4px 16px'};
        `

      case 's':
      case 'small':
        return css`
          font-size: ${sizes.small};
          padding: ${iconOnly || loading
            ? '4px'
            : icon && size && iconPosition
              ? withIconPaddingMap[size][iconPosition]
              : '12px 16px'};
        `

      case 'm':
      case 'medium':
        return css`
          font-size: ${sizes.medium};
          padding: ${iconOnly || loading
            ? '8px'
            : icon && size && iconPosition
              ? withIconPaddingMap[size][iconPosition]
              : '16px 24px'};
        `
    }
  }}

  /* Full width styles */
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

const ButtonLabel = styled.span<{ iconOnly?: boolean; $warn?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 420;
  line-height: ${theme.font.sizes.large};
  color: ${({ $warn }) => ($warn ? theme.colors.signalWarning : 'inherit')};

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      display: none;
    `}
`

const Button: React.FC<IButtonProps> = forwardRef(
  (
    {
      variant = 'primary',
      inverted = false,
      size = 'medium',
      fullWidth = false,
      label,
      icon = undefined,
      iconPosition = 'before',
      iconOnly = false,
      children,
      onClick,
      customIcon = null,
      type = 'button',
      loading = false,
      color = 'primary',
      noBorder,
      warn = false,
      iconColor = theme.colors.actionPrimary,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButton
        variant={variant}
        inverted={inverted}
        size={size}
        fullWidth={fullWidth}
        label={label}
        onClick={onClick}
        iconOnly={iconOnly}
        icon={icon}
        iconPosition={iconPosition}
        loading={loading}
        color={color}
        {...props}
        type={type}
        customIcon={customIcon}
        noBorder={noBorder}
        ref={ref}
      >
        {loading ? (
          <Loader size={size === 'xs' ? 16 : 24} />
        ) : (
          <>
            {(customIcon || icon) &&
              iconPosition === 'before' &&
              (customIcon ? (
                customIcon
              ) : (
                <IconComponent
                color={iconColor}
                  style={{ flexShrink: 0 }}
                  icon={icon || 'circle'}
                  iconSize={size === 'xs' ? 's' : 'm'}
                />
              ))}
            <ButtonLabel
              iconOnly={iconOnly}
              $warn={warn}
              style={{ color: warn ? theme.colors.actionPrimary : 'inherit' }}
            >
              {label || children}
            </ButtonLabel>
            {(customIcon || icon) &&
              iconPosition === 'after' &&
              (customIcon ? (
                customIcon
              ) : (
                <IconComponent
                color={iconColor}
                  style={{ flexShrink: 0 }}
                  icon={icon || 'circle'}
                  iconSize={size === 'xs' ? 's' : 'm'}
                />
              ))}
          </>
        )}
      </StyledButton>
    )
  }
)

export default Button;
