import { HTMLAttributes, useRef } from 'react'
import { css, styled } from 'styled-components'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import IconComponent, { IIconNames } from '../IconComponent/IconComponent'

interface IFeedBackMessageProps {
  feedbackMessage?: boolean
  feedbackMessageType?: 'success' | 'information' | 'warning' | 'error'
  feedbackMessageContent?: string
}

interface IInputField extends HTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean
  label: string
  name?: string
  fullWidth?: boolean
  required?: boolean
  readOnly?: boolean
  type?: 'text' | 'password' | 'number' | 'email' | 'search'
  disabled?: boolean
  value?: string | number
  defaultValue?: string | number
  placeholder?: string
  size?: 's' | 'm'
  onClear?: () => void // as the input is controlled, need to provide state clearing function
  [key: string]: any
}

type IInputFieldProps = IInputField & IFeedBackMessageProps

const StyledInputFieldContainer = styled.div<
  Pick<
    IInputField & { $fullWidth?: boolean },
    '$fullWidth' | 'readOnly' | 'disabled' | 'size'
  >
>`
  gap: 8px;
  outline: 1px solid ${theme.colors.copySubtle};
  padding: ${({ size }) => (size === 'm' ? '18px 16px' : '8px')};
  border-radius: 4px;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
  outline: ${({ readOnly }) =>
    readOnly
      ? '1px solid transparent'
      : `1px solid ${theme.colors.borderDefault}`};
  background-color: ${({ disabled }) =>
    disabled ? `${theme.colors.actionDisabled}` : 'transparent'};

  &:focus-within {
    padding: ${({ size }) => (size === 'm' ? '18px 16px' : '8px 16px')};
    outline: ${({ readOnly }) =>
      readOnly ? 'none' : `1px solid ${theme.colors.actionPrimary}`};
  }
`

const StyledInputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto 0;
  height: 32px; //  to make the input field height consistent with the button

  & > input:not(:active) + #cancel-icon,
  & > input:not(:focus-within) + #cancel-icon {
    display: none;
  }

  & > input:not(:active) + #cancel-icon:focus,
  & > input:not(:focus-within) + #cancel-icon:active {
    display: flex;
  }

  & > input:active + #cancel-icon,
  & > input:focus-within + #cancel-icon {
    display: flex;
  }
`

const StyledInputField = styled.input<Omit<IInputField, 'fullWidth'>>`
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  color: ${theme.colors.copy};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 100%;
  &:focus-within {
    text-overflow: unset;
    overflow: auto;
    white-space: normal;
  }
  &::placeholder {
    color: ${theme.colors.copySubtle};
  }
`

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 32px;
  max-height: 32px;
  position: relative;
`

const StyledIcon = styled(IconComponent)`
  position: absolute;
  left: 8px;
  z-index: 1;
`

export interface IStyledFeedbackMessageProps
  extends HTMLAttributes<HTMLDivElement> {
  messageType?: 'information' | 'success' | 'warning' | 'error'
  messageText?: string
}

const StyledFeedbackMessageBackground = styled.div<
  IStyledFeedbackMessageProps & {
    $messageType?: 'information' | 'success' | 'warning' | 'error'
  }
>`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
  overflow: hidden;
  width: fit-content;
  margin-top: 8px;

  ${({ theme, $messageType }) => {
    const _bg = $messageType && theme.colors.label[$messageType].background
    return css`
      background: ${_bg};
    `
  }}
`

const StyledFeedbackMessageText = styled.p<
  IStyledFeedbackMessageProps & {
    $messageType?: 'information' | 'success' | 'warning' | 'error'
  }
>`
  flex: 1 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 100%;

  ${({ theme, $messageType }) => {
    const _color = $messageType && theme.colors.label[$messageType].labelText
    return css`
      color: ${_color};
    `
  }}
`

export const FeedbackMessage = ({
  messageText,
  messageType = 'success'
}: IStyledFeedbackMessageProps) => {
  return (
    <StyledFeedbackMessageBackground $messageType={messageType}>
      <StyledFeedbackMessageText $messageType={messageType}>
        {messageText}
      </StyledFeedbackMessageText>
    </StyledFeedbackMessageBackground>
  )
}

const getTypeIcon: (type: string) => IIconNames | '' = (type) => {
  switch (type) {
    case 'password':
      return 'key'
    case 'number':
      return 'phone'
    case 'email':
      return 'mail'
    case 'search':
      return 'search'
    default:
      return ''
  }
}

const InputField = ({
  label,
  feedbackMessage = false,
  feedbackMessageContent,
  feedbackMessageType = 'success',
  fullWidth = false,
  required = true,
  readOnly = false,
  type = 'text',
  name,
  value,
  defaultValue,
  autoFocus = false,
  disabled = false,
  onChange, // New prop for the onChange handler
  size = 's',
  onClear = () => {},
  ...otherProps
}: IInputFieldProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const _handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.currentTarget.blur()
    onClear()
    wrapperRef.current?.blur()
  }

  return (
    <>
      <StyledInputFieldContainer
        $fullWidth={fullWidth}
        readOnly={readOnly}
        disabled={disabled}
        size={size}
      >
        <StyledInputFieldWrapper ref={wrapperRef}>
          {['password', 'number', 'email', 'search'].includes(type!) &&
            getTypeIcon(type!) && (
              <StyledIconWrapper>
                <StyledIcon
                  icon={getTypeIcon(type!) as IIconNames}
                  iconSize="s"
                  color="gray"
                  disabled={false}
                />
              </StyledIconWrapper>
            )}
          <StyledInputField
            {...otherProps}
            placeholder={label}
            label={label}
            required={required}
            readOnly={readOnly}
            type={type === 'search' ? 'text' : type}
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            name={name}
            value={value}
            defaultValue={defaultValue || ''}
          />
          {!disabled && (
            <Button
              id="cancel-icon"
              iconOnly
              icon="x"
              size="xs"
              variant={size === 'm' ? 'secondary' : 'tertiary'}
              iconColor={theme.colors.actionPrimary}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                _handleClear(e)
              }
            />
          )}
        </StyledInputFieldWrapper>
      </StyledInputFieldContainer>

      {feedbackMessage && (
        <FeedbackMessage
          messageText={feedbackMessageContent}
          messageType={feedbackMessageType}
        />
      )}
    </>
  )
}

export default InputField
