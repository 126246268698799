// import styled from 'styled-components'
import { theme } from '../../../styles'
import Dropdown from '../DropDown/DropDown'
// import NavItem from '../NavItem/NavItem'
import { useKanbanContext } from '../kanban/providers'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

// interface ITemplateSelectFieldProps {
//   isMobile?: boolean
// }

// const StyledViewOptionsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 8px;
//   width: 100%;
// `

const TemplateSelectField = () => {
  const { selectedView, setSelectedTemplate } = useKanbanContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const templateId = searchParams.get('templateId')

  const { data } = useSelector((state: RootState) => state.template)

  console.log({ data })

  useEffect(() => {
    if (!templateId) {
      setSelectedTemplate({
        id: data[0].id,
        name: data[0].title
      })
    } else {
      const template = data.find((t) => t.id === templateId)
      setSelectedTemplate({
        id: templateId,
        name: template?.title || ''
      })
    }
  }, [templateId, data, setSelectedTemplate])

  const filterOptions = useMemo(() => {
    return data.map((template) => ({
      id: template.id,
      name: template.title
    }))
  }, [data])

  // if (isMobile) {
  //   return (
  //     <StyledViewOptionsContainer>
  //       <NavItem
  //         icon="grid"
  //         navItemTitle="Grid"
  //         isActive={selectedView.id === '1'}
  //         showCounter={false}
  //         onClick={() => setSelectedView({ id: '1', name: 'Grid' })}
  //       />
  //       {!isMobile && (
  //         <NavItem
  //           icon="table"
  //           navItemTitle="Table"
  //           isActive={selectedView.id === '2'}
  //           showCounter={false}
  //           onClick={() => setSelectedView({ id: '2', name: 'table' })}
  //         />
  //       )}
  //       <NavItem
  //         icon="kanban"
  //         navItemTitle="Kanban"
  //         isActive={selectedView.id === '3'}
  //         showCounter={false}
  //         onClick={() => {
  //           setSelectedView({ id: '3', name: 'Table' });
  //           const searchParams = new URLSearchParams()
  //           searchParams.set('templateId', selectedTemplate.id)
  //         }}
  //       />
  //     </StyledViewOptionsContainer>
  //   )
  // }

  return (
    <Dropdown
      items={filterOptions}
      placeholder="Template type"
      value={selectedView.name}
      size="s"
      type="list"
      showAction={false}
      textColor={theme.colors.actionPrimary}
      onSelectedItemChange={(item) => {
        setSelectedTemplate({
          id: item.id,
          name: item.name
        })
        const searchParams = new URLSearchParams()
        searchParams.set('templateId', String(item.id))
        setSearchParams(searchParams)
      }}
    />
  )
}

export default TemplateSelectField
