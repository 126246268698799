import { useFormContext } from "react-hook-form"
import Option from "../../Option/Option"
import {
  useWorkflow,
  WorkflowActionType
} from '../../../../context/WorkflowActionProvider'
import { UserRole } from '../../../../types/user'

interface CheckBoxOptionProps {
  label: string
  value: string

}

const CheckBoxOption: React.FC<CheckBoxOptionProps> = ({ label, value }) => {
  const { userRole, option: workflowType } = useWorkflow()
  const { setValue, watch, getValues } = useFormContext<WorkflowActionType>()
  const fieldPath = workflowType === 'jira' ? 'jira.details' : 'mail.details'

  const currentValues = watch(fieldPath as any) as string[]

  const handleOptionClick = () => {
    try {
      const existingValues = getValues(fieldPath as any) || []

      const newValues = existingValues.includes(value)
        ? existingValues.filter((val: any) => val !== value)
        : [...existingValues, value]

      setValue(fieldPath as any, newValues, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    } catch (error) {
      console.error('Error updating checkbox option:', error)
    }
  }

  const isChecked = currentValues?.includes(value)

  return (
    <Option
      key={label}
      label={label}
      checked={isChecked}
      onClick={handleOptionClick}
      disabled={userRole === UserRole.EDITOR}
    />
  )
}

export default CheckBoxOption