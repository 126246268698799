import { useMutation, useQueryClient } from '@tanstack/react-query'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { auth } from '../../../config/config'
import { toast } from 'react-toastify'
import {
  useWorkflow,
  WorkflowActionType
} from '../../../context/WorkflowActionProvider'
import { atlassianAuthCheckOptions } from './useCheckAtlassianAuth'
import { getWorkflowsOptions } from './useGetWorkflow'

export const ADD_WORKFLOW = 'addWorkflow'

const fetcher = async (props: WorkflowActionType) => {
  const token = await auth.currentUser?.getIdToken()
  return httpRequest({
    url: '/workflows/add',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: props
  })(nest)
}

const useWorkflowAdd = () => {
  const queryClient = useQueryClient()
  const { onClickTrigger } = useWorkflow()
  return useMutation({
    mutationKey: [ADD_WORKFLOW],
    mutationFn: async (props: WorkflowActionType) => fetcher(props),
    onMutate: async (props: WorkflowActionType) => {
      onClickTrigger()
      return props
    },
    onError: (error) => {
      toast.error(error.message || 'An error occurred')
    },
    onSuccess: async (data: any) => {
      toast.success('Workflow added successfully')
      await Promise.all([
        queryClient.refetchQueries(atlassianAuthCheckOptions(data)),
        queryClient.refetchQueries(getWorkflowsOptions(data))
      ])
    }
  })
}

export default useWorkflowAdd
