import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useKanbanColumns } from './hooks/useKanbanColumns'
import { useSearchParams } from 'react-router-dom'
import Spinner from '../Spinner'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { push, ref, update } from 'firebase/database'
import { db } from '../../../config/config'
import { toast } from 'react-toastify'
import {
  StyledKanbanContainer,
  StyledKanbanMainContainer
} from './kanban.styles'
import KanbanColumnComp from './_components/KanbanColumn'

interface KanbanItem {
  id: string
  componentName: string
  phaseType: number
  tasksCompleted: number
  tasksTotal: number
  date: string
}

export interface KanbanColumn {
  id: string
  title: string
  phaseType?: number
  items: KanbanItem[]
}

const ComponentsKanbanView = () => {
  const [params] = useSearchParams()
  const templateId = params.get('templateId')
  const {
    columns,
    setColumns,
    loading: isLoading
  } = useKanbanColumns(templateId || '')

  const { currentTeam } = useSelector((store: RootState) => store.team)
  const teamId = currentTeam?.id

  console.log({ columns })

  const onDragEnd = async (result: typeof DropResult) => {
    if (!result.destination || !columns || !teamId || !templateId) return

    const { source, destination } = result
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]

    if (!sourceColumn || !destColumn) return

    const originalColumns = { ...columns }

    try {
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [movedItem] = sourceItems.splice(source.index, 1)

      const filteredDestItems = destItems.filter(
        (item) => item.id !== movedItem.id
      )

      filteredDestItems.splice(destination.index, 0, movedItem)

      const newColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: filteredDestItems
        }
      }

      setColumns(newColumns)

      const now = new Date().toISOString()
      const updates: Record<string, any> = {}

      if (source.droppableId !== destination.droppableId) {
        updates[`components/${teamId}/${movedItem.id}/activePhase`] =
          destination.droppableId
        updates[`components/${teamId}/${movedItem.id}/updatedAt`] = now
        updates[`components/${teamId}/${movedItem.id}/lastMovedAt`] = now

        const historyKey = push(
          ref(db, `components/${teamId}/${movedItem.id}/history`)
        ).key
        if (historyKey) {
          updates[
            `components/${teamId}/${movedItem.id}/history/${historyKey}`
          ] = {
            from: source.droppableId,
            to: destination.droppableId,
            timestamp: now,
            type: 'phase_change'
          }
        }

        updates[
          `phases/${templateId}/${source.droppableId}/componentRefs/${movedItem.id}`
        ] = null
        updates[
          `phases/${templateId}/${destination.droppableId}/componentRefs/${movedItem.id}`
        ] = {
          addedAt: now,
          order: destination.index
        }
      } else {
        updates[
          `phases/${templateId}/${destination.droppableId}/componentRefs/${movedItem.id}/order`
        ] = destination.index
      }

      filteredDestItems.forEach((item, index) => {
        if (index !== destination.index) {
          updates[
            `phases/${templateId}/${destination.droppableId}/componentRefs/${item.id}/order`
          ] = index
        }
      })

      await update(ref(db), updates)
      toast.success('Component moved successfully')
    } catch (error) {
      console.error('Error updating component positions:', error)
      setColumns(originalColumns)
      toast.error('Failed to update component positions')
    }
  }

  if (isLoading) {
    return <Spinner type="spinner" />
  }

  return (
    <>
      <StyledKanbanMainContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <StyledKanbanContainer>
            {columns &&
              Object.entries(columns).map(([columnId, column]) => (
                <KanbanColumnComp
                  key={columnId}
                  columnId={columnId}
                  column={column as KanbanColumn}
                />
              ))}
          </StyledKanbanContainer>
        </DragDropContext>
      </StyledKanbanMainContainer>
    </>
  )
}

export default ComponentsKanbanView
