import styled from 'styled-components'
import { theme } from '../../../styles'

export const StyledKanbanMainContainer = styled.section`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  padding: 0 16px;
  scroll-behavior: unset !important;

  @media screen and (max-width: 768px) {
     padding-right: 0px;
     min-height: 100vh;
     margin-bottom: 60px;
  }
`

export const StyledKanbanContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 16px;
  padding-right: 80px;
  width: 100%;
  border-radius: 8px;
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.colors.borderDefault};
  background: ${({ theme }) => theme.colors.pink};

  @media screen and (max-width: 640px) {
    border-top: 1px solid ${({ theme }) => theme.colors.borderDefault};
    border-radius: 0px;
    width: 100%;
    flex-direction: column;
    gap: 0px;
    margin: 1px;
    padding: 0px;
    height: auto;
    max-width: 100%;
  }
`

export const StyledKanbanColumn = styled.div<{ isDraggingOver?: boolean }>`
  display: flex;
  width: 320px;
  min-width: 320px;
  max-height: 100%;
  overflow-y: scroll;

  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  background-color: ${({ isDraggingOver, theme }) =>
    isDraggingOver ? theme.colors.red : 'white'};

  @media screen and (max-width: 768px) {
    flex-shrink: 0;
    width: 100%;
    gap: 8px;
    margin: 0px;
    padding: 0px;
    height: auto;
  }
`

export const StyledKanbanColumnContent = styled.div<{
  isDraggingOver?: boolean
}>`
  width: 100%;
  height: 100%;
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ isDraggingOver, theme }) =>
    isDraggingOver ? theme.colors.surfaceNormalHover : 'white'};
  position: relative;

  @media screen and (max-width: 768px) {
    gap: 8px;
    height: fit-content;
    overflow-y: scroll;
    padding: 0 8px 8px 8px;
  }
`

export const StyledMobileKanbanCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0px 16px;
`

export const StyledKanbanColumnHeader = styled.div<{ $isCollapsed?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 16px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDefault}; 
  background-color: white;

  @media screen and (max-width: 768px) {
    margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '0px' : '16px')};
    background-color: white;
    border-bottom: 2px solid ${theme.colors.borderDefault};
    padding: 16px;
    position: sticky;
    top: 0px;
  }
`
