import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ref, remove, update } from 'firebase/database'
import { db } from '../../config/config'
import { TemplateColorObj } from '../../store/reducers/templateReducerSlice'
import { PhaseObj, setPhasesData } from '../../store/reducers/phaseReducerSlice'
import { getTimeStamp } from '../../utils/helpers'
import useIsMobileDevice from '../../hooks/useIsMobileDevice'
import useGetCurrentPhases from '../../hooks/useGetCurrentPhases'
import {
  StyledPhaseDetailsMainContainer,
  StyledPhaseDetailsBody,
  StyledPhaseDetailsFormContainer,
  StyledPhaseDetailsFormDataContainer,
  StyledPhaseDetailsActionButtonContainer,
  StyledDangerZoneContainer,
  StyledDangerZoneHeader,
  StyledDangerZoneTitle,
  StyledDangerZoneDescription
} from './StyledPhaseDetails'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import {
  StyledPickColor,
  StyledPickColorTitle
} from '../../components/ui/AddItem/AddItem'
import SubNav from '../../components/ui/SubNav/SubNav'
import ColorPicker from '../../components/ui/ColorPicker/ColorPicker'
import Modal from '../../components/ui/Modal/Modal'
import { PhaseDeleteContent } from '../../components/ui/Modal/ModalSlotContents'
import { useUpdatePhasesJira } from '../../hooks/api'

interface ComponentProps {
  newPage?: string | undefined
}

const PhaseDetail: React.FC<ComponentProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useIsMobileDevice()
  const { id, phaseId } = useParams()
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { isLoading } = useSelector((store: RootState) => store.template)
  const { data: allPhases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )
  const [formData, setFormData] = useState<PhaseObj | any>({
    title: '',
    description: '',
    applicationType: 'figma',
    visible: true,
    mandatory: true,
    isActive: true
  })
  const [initialData, setInitialData] = useState<PhaseObj>()
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const phaseRef = ref(db, `phases/${id}/${phaseId}`)

  const { phases } = useGetCurrentPhases(allPhases, id)

  const { user } = useSelector((store: RootState) => store.auth) 

  // fetch phase data and set form data
  useEffect(() => {
    if (!id || !phaseId || isLoading || isLoadingPhases) return

    const currentPhase = phases?.find((phase) => phase.id === phaseId)
    if (currentPhase) {
      setFormData({ ...currentPhase })
      setInitialData({ ...currentPhase })
    }
  }, [id, isLoading, isLoadingPhases, phaseId, phases])

  // check if the provided color is already in used by any phase
  const findUsedPhase = (obj: TemplateColorObj): PhaseObj | undefined => {
    return phases?.find((v) => v?.colorId === obj?.id)
  }

  // select a color
  const handleColorSelect = (colorObj: TemplateColorObj) => {
    const inUsedPhase = findUsedPhase(colorObj)
    if (inUsedPhase) {
      toast.info(
        `Sorry mate, this color is already in use in ${inUsedPhase?.title}.`
      )
      return
    }
    setFormData({
      ...formData,
      colorId: colorObj?.id
    })
  }

  const { mutateAsync, isPending } = useUpdatePhasesJira({
    currentTeam,
    templateId: id
  })

  // save changes
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (saving || !id) return

    if (!formData?.title?.trim()) {
      toast.info('Please provide phase title.')
      return
    }

    if (!formData?.colorId) {
      toast.info('Please select a color.')
      return
    }
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team id not found.')
      return
    }

    setSaving(true)
    const timestamp = getTimeStamp()

    try {
      await update(phaseRef, {
        ...formData,
        updatedAt: timestamp
      })

      if (user?.jira?.access_token) await mutateAsync()

      dispatch(
        setPhasesData({
          data: allPhases?.map((phase) => {
            if (phase.id === phaseId) {
              return {
                ...phase,
                ...formData,
                updatedAt: timestamp
              }
            }
            return phase
          })
        })
      )
      toast.success('All changes are saved.')
      navigate(`/dashboard/templates/${id}`)
    } catch (error) {
      toast.error('Something went wrong.')
    } finally {
      setSaving(false)
    }
  }

  // Delete phase
  const deletePhase = async () => {
    if (!id || !phaseId || saving || isLoading || deleting || isLoadingPhases)
      return

    const unArchivedPhases = phases?.filter((phase) => !phase?.archived)
    //check if the there are more than 1 phases
    if (unArchivedPhases?.length === 1) {
      toast.error('Sorry, you can not delete the last phase in the template.')
      return
    }

    setDeleting(true)
    try {
      await remove(phaseRef)

      dispatch(
        setPhasesData({
          data: allPhases?.filter((phase) => phase.id !== phaseId)
        })
      )

      toast.success('The phase has been removed.')
      navigate(`/dashboard/templates/${id}`)
    } catch (error) {
      toast.error('Something went wrong.')
      console.log('Error while deleting a phase', error)
    } finally {
      setDeleting(false)
    }
  }

  // const handleArchivePhase = async () => {
  //   if (!id || !phaseId || saving || isLoading || isLoadingPhases) return

  //   const unArchivedPhases = phases?.filter((phase) => !phase?.archived)
  //   //check if the there are more than 1 phases
  //   // only apply this check for phase with default (un-archived) state
  //   if (unArchivedPhases?.length === 1) {
  //     toast.error('Sorry, you can not archive the last phase in the template.')
  //     return
  //   }

  //   setSaving(true)
  //   const timestamp = getTimeStamp()
  //   try {
  //     await update(phaseRef, {
  //       archived: true,
  //       updatedAt: timestamp
  //     })

  //     dispatch(setPhasesData({
  //       data: allPhases?.map((phase) => {
  //         if (phase.id === phaseId) {
  //           return {
  //             ...phase,
  //             archived: true,
  //             updatedAt: timestamp
  //           }
  //         }
  //         return phase
  //       })
  //     }))
  //     toast.success('Phase archived successfully.')
  //     navigate(`/dashboard/templates/${id}`)
  //   } catch (error) {
  //     console.log('Error archiving the phase', error)
  //     toast.error('Something went wrong.')
  //   } finally {
  //     setSaving(false)
  //   }
  // }

  // JSX
  return (
    <StyledPhaseDetailsMainContainer>
      <SubNav
        title={`Phase Details`}
        onBtnClick={() => navigate(`/dashboard/templates/${id}`)}
      />
      <StyledPhaseDetailsBody>
        <form onSubmit={handleSave} style={{ width: '100%' }}>
          <StyledPhaseDetailsFormContainer>
            <StyledPhaseDetailsFormDataContainer>
              <InputField
                disabled={saving || isLoading || isLoadingPhases}
                autoFocus
                required
                fullWidth
                label="Phase title"
                placeholder="maximum of 120 characters"
                name="title"
                type="text"
                value={formData.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    title:
                      e.target?.value?.length < 120
                        ? e.target?.value
                        : formData?.title
                  })
                }
              />

              <StyledPickColor>
                <StyledPickColorTitle>Pick a color </StyledPickColorTitle>
                <ColorPicker
                  formData={formData}
                  templateId={id}
                  handleColorSelect={handleColorSelect}
                />
              </StyledPickColor>
              {/* <StyledButtonContainer>
                <Button
                  type="button"
                  // disabled={
                  //   saving ||
                  //   isLoading ||
                  //   isLoadingPhases
                  // }
                  disabled
                  label="ARCHIVE PHASE"
                  variant="utility"
                  size={'xs'}
                  style={{ marginLeft: 'auto' }}
                  onClick={handleArchivePhase}
                />
              </StyledButtonContainer> */}
              {/* <StyledInputLabelContainer style={{ width: '100%' }}>
                <StyledInputLabel>Phase description</StyledInputLabel>
                <TextArea
                  textLabel="Phase description"
                  width="100%"
                  value={formData.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData({
                      ...formData,
                      description: e.target.value
                    })
                  }
                />
              </StyledInputLabelContainer> */}
              {/* <StyledInputLabelContainer style={{ width: '100%' }}>
                <StyledInputLabel>
                  Application (For all or nearly all tasks)
                </StyledInputLabel>
                <SelectField
                  fullWidth
                  options={[
                    { label: 'Figma', value: 'figma' },
                    { value: 'other', label: 'Other' }
                  ]}
                  value={formData.applicationType}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFormData({
                      ...formData,
                      applicationType: e.target.value
                    })
                  }
                />
              </StyledInputLabelContainer> */}
            </StyledPhaseDetailsFormDataContainer>

            <StyledPhaseDetailsActionButtonContainer>
              <Button
                type="submit"
                disabled={saving || isLoading || isLoadingPhases}
                label={saving || isPending ? 'Saving...' : 'SAVE CHANGES'}
                variant="primary"
                size={isMobile ? 'small' : 'medium'}
              />
              <Button
                type="button"
                disabled={saving || isLoading || isLoadingPhases}
                label="DISCARD CHANGES"
                variant="secondary"
                size={isMobile ? 'small' : 'medium'}
                onClick={() => setFormData({ ...initialData })}
              />
            </StyledPhaseDetailsActionButtonContainer>
          </StyledPhaseDetailsFormContainer>
        </form>
        <StyledDangerZoneContainer>
          <StyledDangerZoneHeader>
            <StyledDangerZoneTitle>Danger zone</StyledDangerZoneTitle>
            <StyledDangerZoneDescription>
              Are you sure you want to delete this phase? This action is
              irreversible and will permanently erase all your data. If you're
              sure, tap 'Remove Phase' below.
            </StyledDangerZoneDescription>
          </StyledDangerZoneHeader>
          <Button
            type="button"
            size="small"
            disabled={saving || isLoading || isLoadingPhases || deleting}
            label={deleting ? 'Removing...' : 'REMOVE PHASE'}
            variant="danger"
            onClick={() => setShowModal(true)}
            fullWidth={false}
          />
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              signal="alert-triangle"
              signalIconType="warning"
              title="Remove Phase"
              slotContent={<PhaseDeleteContent />}
              buttonLabel1="REMOVE PHASE"
              buttonLabel2="CANCEL"
              buttonVariant1="danger"
              buttonVariant2="tertiary"
              buttonOnClick1={deletePhase}
            />
          )}
        </StyledDangerZoneContainer>
      </StyledPhaseDetailsBody>
    </StyledPhaseDetailsMainContainer>
  )
}

export default PhaseDetail
