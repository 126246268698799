import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'
import { ComponentObj } from '../../../../store/reducers/componentsReducerSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useSearchParams } from 'react-router-dom'
import { IDropdownItem } from '../../DropDown/DropDown'

export const KanbanContext = createContext<{
  components: ComponentObj[]
  setComponents: (components: ComponentObj[]) => void
  isLoading: boolean
  selectedView: IDropdownItem
  setSelectedTemplate: (template: IDropdownItem) => void
  selectedTemplate: IDropdownItem
  setSelectedView: (view: IDropdownItem) => void
  selectedPhase: IDropdownItem[]
  setSelectedPhase: (phase: IDropdownItem[]) => void
  selectedUser: IDropdownItem[]
  setSelectedUser: (user: IDropdownItem[]) => void
  teamId?: string
  templateId?: string
}>({
  components: [],
  setComponents: () => {},
  isLoading: false,
  selectedView: { id: '1', name: 'Grid' },
  setSelectedView: () => {},
  selectedTemplate: { id: '', name: '' },
  setSelectedTemplate: () => {},
  selectedPhase: [{ id: '', name: '' }],
  setSelectedPhase: () => {},
  selectedUser: [{ id: '', name: '' }],
  setSelectedUser: () => {}
})

export const useKanbanContext = () => {
  const context = useContext(KanbanContext)
  if (!context) {
    throw new Error('useKanbanContext must be used within a KanbanProvider')
  }
  return context
}

export type IKanbanProviderProps = React.FC<PropsWithChildren>

const KanbanProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: storeComponents, isLoading } = useSelector(
    (state: RootState) => state.component
  )
    const { data: templates } = useSelector(
      (state: RootState) => state.template
    )
    const { currentTeam } = useSelector((state: RootState) => state.team)
    const [searchParams, setSearchParams] = useSearchParams()

    const [components, setComponents] = useState<ComponentObj[]>(
      storeComponents || []
    )
    const [selectedView, setSelectedView] = useState<IDropdownItem>({
      id: '1',
      name: 'Grid'
    })
    const [selectedTemplate, setSelectedTemplate] = useState<IDropdownItem>({
      id: '',
      name: ''
    })
    const [selectedPhase, setSelectedPhase] = useState<IDropdownItem[]>([])
    const [selectedUser, setSelectedUser] = useState<IDropdownItem[]>([])

    const templateId = searchParams.get('templateId') || ''
    const view = searchParams.get('view') || 'Grid'

    // Sync with Redux store
    useEffect(() => {
      if (storeComponents) {
        setComponents(storeComponents)
      }
    }, [storeComponents])

    // Handle view updates
    useEffect(() => {
      if (view) {
        setSelectedView({ id: '1', name: view })
        const params = new URLSearchParams(searchParams)
        params.set('view', view.toLowerCase())
        setSearchParams(params)
        if (view === 'kanban') {
          setSelectedTemplate({ id: templates[0].id, name: templates[0].title })
          const params = new URLSearchParams(searchParams)
          params.set('templateId', templates[0].id)
          setSearchParams(params)
        }
      }
    }, [view, setSearchParams, searchParams, templates])

  const contextValue = {
    components,
    setComponents,
    isLoading,
    teamId: currentTeam?.id,
    templateId,
    selectedView,
    setSelectedView,
    selectedTemplate,
    setSelectedTemplate,
    selectedPhase,
    setSelectedPhase,
    selectedUser,
    setSelectedUser
  }

  return (
    <KanbanContext.Provider value={contextValue}>
      {children}
    </KanbanContext.Provider>
  )
}

export default KanbanProvider
