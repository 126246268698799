import React, { createContext, useEffect, useState } from 'react'
import {
  User,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth'
import { auth } from '../../../config/config'

interface AuthContextType {
  currentUser: User | null
  loading: boolean
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Set session persistence to browser session
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserSessionPersistence)
      } catch (error) {
        console.error('Failed to set auth persistence:', error)
      }
    }

    initializeAuth()

    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
