import styled from "styled-components"
import IconComponent from "../../IconComponent/IconComponent"
import Label from "../../Label/Label"
import SignalIcon from "../../SignalIcon/SignalIcon"
import Button from "../../Button/Button"
import { theme } from "../../../../styles"
import { useWorkflow } from "../../../../context/WorkflowActionProvider"

const StyledExpandCollapseTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.actionPrimary};

  h4 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 420;
    line-height: 140%;
  }
`

const StyledExpandCollapseTrigger = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  width: 100%;
  padding-bottom: 16px;
`

const StyledExpandTriggerButton = styled.div<{ $collapse: boolean }>`
  transition: all 0.3s;
  rotate: ${({ $collapse }) => ($collapse ? '0' : '-180deg')};
`

const ExpandCollapseTrigger = () => {
  const { option, collapse, onClickTrigger, showSignalIcon } = useWorkflow()
  return (
    <StyledExpandCollapseTrigger onClick={onClickTrigger}>
      <StyledExpandCollapseTitle>
        <IconComponent
          icon="activity"
          iconSize="m"
          color={theme.colors.actionPrimary}
        />
        <h4>Workflow Action</h4>

        {showSignalIcon ? <SignalIcon signal="warning" background /> : false}

        <Label
          signal={showSignalIcon ? 'warning' : 'callout'}
          size="s"
          text={option === 'email' ? 'Email' : 'Jira'}
        />
      </StyledExpandCollapseTitle>

      <StyledExpandTriggerButton $collapse={collapse}>
        <Button
          size="xs"
          iconOnly
          variant="utility"
          iconPosition="after"
          icon="chevron-down"
          type="button"
        />
      </StyledExpandTriggerButton>
    </StyledExpandCollapseTrigger>
  )
}

export default ExpandCollapseTrigger