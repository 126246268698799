import { queryOptions, useQuery } from '@tanstack/react-query'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { auth } from '../../../config/config'
import { isEmpty } from 'lodash'

export const GET_WORKFLOWS = 'getWorkflows'

type TGetWorkflows = {
  teamId: string
  templateId: string
}

const fetcher = async (props: TGetWorkflows) => {
  const { teamId, templateId } = props
  const token = await auth.currentUser?.getIdToken()
  return httpRequest({
    url: `/workflows/${teamId}/${templateId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })<any>(nest)
}

export const getWorkflowsOptions = ({ teamId, templateId }: TGetWorkflows) =>
  queryOptions({
    queryKey: [GET_WORKFLOWS, teamId, templateId],
    queryFn: async () => fetcher({ teamId, templateId }),
    enabled: !isEmpty(teamId) && !isEmpty(templateId),
    refetchOnWindowFocus: true,
    refetchOnMount: true
  })

const useGetWorkflows = (props: TGetWorkflows) => {
  return useQuery(getWorkflowsOptions(props))
}

export default useGetWorkflows
