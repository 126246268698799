import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { theme } from '../../../../styles'
import ExpandCollapseTrigger from './WorkFlowExpandTrigger'
import ExpandCollapseContent from './WorkFlowExpandCollapseContent'
import { useWorkflow } from '../../../../context/WorkflowActionProvider'
import { UserRole } from '../../../../types/user'

const StyledExpandCollapseContainer = styled.div<{ $addExtraPadding: string }>`
  padding: ${({ $addExtraPadding }) => $addExtraPadding};
  border-radius: 4px;
  border: 1px solid ${theme.colors.actionPrimary};
  width: 100%;
`

const WorkflowActionExpandCollapse = () => {
  const { collapse, userRole } = useWorkflow()
  const addExtraPadding =
    userRole === UserRole.EDITOR ? '16px' : '16px 16px 16px 16px'
  return (
    <StyledExpandCollapseContainer $addExtraPadding={addExtraPadding}>
      <ExpandCollapseTrigger />
      <AnimatePresence>
        {!collapse ? <ExpandCollapseContent /> : null}
      </AnimatePresence>
    </StyledExpandCollapseContainer>
  )
}

export default WorkflowActionExpandCollapse
