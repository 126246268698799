import { ReactNode, useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const FadeWrapper = styled.div<{ $show: boolean; $isFadingOut: boolean }>`
  ${({ $show, $isFadingOut }) => css`
    animation: ${$show ? fadeInAnimation : fadeOutAnimation} 100ms ease-in-out;
    opacity: ${$show || $isFadingOut ? 1 : 0};
    pointer-events: ${$show || $isFadingOut ? "auto" : "none"};
  `}
`;

const FadeInFadeOut = ({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) => {
  const [isVisible, setIsVisible] = useState(show);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setIsFadingOut(false);
    } else if (isVisible) {
      setIsFadingOut(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
        setIsFadingOut(false);
      }, 100); 
      return () => clearTimeout(timeout); 
    }
  }, [show, isVisible]);

  if (!isVisible && !isFadingOut) return null;

  return (
    <FadeWrapper $show={show} $isFadingOut={isFadingOut}>
      {children}
    </FadeWrapper>
  );
};

export default FadeInFadeOut;
