import styled, { css } from "styled-components"
import { theme } from "../../styles/theme"

export const StyledTemplatesMainContainer = styled.section`
    display: flex;
    padding: 40px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    margin-inline: auto;
    width: 100%;
    max-width: 832px;
    gap:40px;
    overflow-y: auto;
    background: ${theme.colors.white};

    @media screen and (max-width: 430px) {
        gap:16px;
        padding: 24px 16px;
  }
`

export const StyledTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`


export const StyledListItemContainer = styled.div<{
  disabled?: boolean
  isActionable?: boolean
  active?: boolean
}>`
  display: flex;
  width: 100%;
  max-width: 320px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid ${theme.colors.borderDefault};
  cursor: pointer;
  @media screen and (min-width: 430px) {
    max-width: 480px;
    padding: 16px;
    gap: 16px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ active }) =>
    active &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.actionPrimaryLight};
    `}

  ${({ disabled, isActionable, active }) =>
    !disabled &&
    css`
      cursor: ${isActionable ? 'pointer' : 'default'};
      border-color: ${active
        ? theme.colors.actionPrimaryActive
        : theme.colors.borderDefault};
      &:hover {
        background-color: ${active ? theme.colors.actionPrimaryLight : theme.colors.actionPrimaryLightHover};
        border-color: ${active ? theme.colors.actionPrimaryActive :  theme.colors.actionPrimaryLightHover};
      }

      &:active {
        background-color: ${theme.colors.actionPrimaryLightActive};
        border-color: ${theme.colors.actionPrimaryLightActive};
      }
    `}
`

export const StyledItemContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  user-select: none;
`

export const StyledItemTitle = styled.h4`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 420;
  }
`

export const StyledItemDescription = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copySubtle};
  font-family: 'rooney-sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 380;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  background-color: #fdfcfb;
  border-radius: 8px;
  border: 1px solid #f2ece6;
  width: 100%;
  font-family: "rooney-sans";
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 420;
  color: rgba(36, 28, 21, 0.65);
  text-transform: uppercase;
  font-family: "rooney-sans";
`;

export const ExpandableContent = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px
`;

export const TemplatesContainer = styled.div`
  min-width: 100%;
  display: flex;
  overflow: auto;
  gap: 8px;
  scroll-behavior: smooth;
  -webkit-scroll-snap-type: x;
`;

export const OptionCard = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  width: 230px;
  min-width: 230px;
  max-width: 230px;
  cursor: pointer;
`;

export const OptionTitle = styled.h5`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 520;
`;

export const OptionDescription = styled.p`
  color: rgba(36, 28, 21, 0.65);
  font-weight: 340;
`;

export const OptionButton = styled.button<{ textColor?: string; pending?: boolean }>`
  background-color: transparent;
  border: none;
  width: max-content;
  max-width: 100%;
  color: ${(props) => props.textColor};
  opacity: ${(props) => (props.pending ? "0.3" : "1")};
  margin-top: auto;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  padding: 8px 0 0 0;
  font-size: 14px;
  font-weight: 420;
`;

export const ScrollButton = styled.div<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 48px;
  border-radius: 4px;
  background-color: #fff;
  color: ${(props) => theme.colors.actionPrimary};
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  ${(props) => (props.left ? "left: 8px;" : "right: 8px;")}
`;