import React, { useEffect, useState } from 'react'
import {
  Container,
  ExpandableContent,
  Header,
  OptionButton,
  OptionCard,
  OptionDescription,
  OptionTitle,
  ScrollButton,
  TemplatesContainer,
  Title
} from './StyledTemplates'
import Button from '../../components/ui/Button/Button'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getTimeStamp } from '../../utils/helpers'
import { push, ref, set } from 'firebase/database'
import { db } from '../../config/config'
import { RootState } from '../../store'
import { PhaseObj, setPhasesData } from '../../store/reducers/phaseReducerSlice'
import { setTemplatesData } from '../../store/reducers/templateReducerSlice'
import FeatherIcon from 'feather-icons-react'
import { theme } from '../../styles'
import Modal from '../../components/ui/Modal/Modal'

const phasesData = [
  {
    title: 'Prepare Design',
    description:
      'Start by working out the component concept. This includes considering the fundamental design principles and their alignment with your overall design system.',
    tasks: [
      {
        title: 'Demonstrate your case',
        description:
          'Show why you need a new component and how existing components fail your use case. It demonstrates you understand how to apply components and why they fall short.'
      },
      {
        title: 'Apply design fundamentals',
        description:
          'Even in the early stages of a new contribution, it matters to apply the proper styling, follow established patterns, and inherit components correctly from the system. Ensure the component follows core design principles and adopts fundamentals such as color schemes, typography, and spacing consistent with your design system.'
      },
      {
        title: 'Support all related use cases',
        description:
          'Make the component both tailored and versatile, to support your use case and others too.'
      }
    ]
  },
  {
    title: 'Consolidate the Design',
    description:
      'Consolidation is key, it keeps you from implementing multiple solutions that serve the same purpose.',
    tasks: [
      {
        title: 'Gather use cases',
        description:
          'Collect different scenarios where the component will be used. This helps in understanding how the component will function in various contexts.'
      },
      {
        title: 'Write a rationale',
        description:
          'Document the reasoning behind design choices, including why certain decisions were made and how they benefit the overall design system.'
      },
      {
        title: 'Conduct a consolidation meeting',
        description:
          'Bring together the team to review your proposal. Discuss any potential issues and gather feedback to refine the design further.'
      },
      {
        title: 'Final draft of your component',
        description:
          'Deliver a final draft by addressing the feedback: Get a green light from your fellow designers. And have it prepared for the next phase: the API Alignment.'
      },
      {
        title: 'Cover devices, modes, A11Y, and more',
        description:
          "When creating a component, you've covered the main aspects, but there are a few additional considerations to ensure a comprehensive approach. By addressing these additional aspects, you'll create a more robust and versatile component that can handle a wider range of scenarios and user needs."
      }
    ]
  },
  {
    title: 'Align Design and Development',
    description:
      'Property Alignment is the ultimate bridge between design and development.',
    tasks: [
      {
        title: 'Request Component Properties',
        description: ''
      },
      {
        title: 'Review the Component Properties Together',
        description:
          "During this meeting, you'll collaborate to define the component's anatomy, structure, and properties. The goal is to establish consistent and intuitive naming that makes the component clear and usable for both designers and developers."
      },
      {
        title: 'Create Definition of Ready',
        description:
          "With all the details ironed out in the API Alignment, it's time to apply the agreed-upon naming conventions to your Figma component and documentation. Finalize the design, properties, and alignment to ensure the component is fully prepared for development."
      }
    ]
  },
  {
    title: 'Docs and testing',
    description:
      'Govern and give guidance: Learn how to create powerful documentation that not only guides users effectively but also prevents misuse.',
    tasks: [
      {
        title: 'Finalize the rationale',
        description:
          'A component might be visually similar, but its purpose might be vastly different. When you base the component on its purpose, instead of its utility or visual similarities, you can create much better guidance and clarity on its usage.'
      },
      {
        title: 'Write Out Usage Guidelines',
        description:
          'The art of writing design system guidelines that actually get followed: Document detailed usage instructions, including “do” and “don’t” examples, to guide future designers and developers.'
      },
      {
        title: 'Create Live Copy Examples',
        description:
          'Include real-world examples of how the component should be used within the design system, demonstrating its versatility and application.'
      },
      {
        title: 'Accessibility',
        description: ''
      },
      {
        title: 'Development and Design Guidelines',
        description: ''
      },
      {
        title: 'Publish on Figma',
        description: ''
      },
      {
        title: 'Publish on Storybook and test',
        description: ''
      },
      {
        title: 'Announce the release',
        description: ''
      }
    ]
  }
]

const TemplateOptionsSlider = ({}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [templateTypeModal, setTemplateTypeModal] = useState<boolean>(false)
  const { data: templatesList, isLoading: isLoadingTemplates } = useSelector(
    (store: RootState) => store.template
  )
  const { user } = useSelector((store: RootState) => store.auth)
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { data: phases } = useSelector((store: RootState) => store.phase)
  const [isExpanded, setIsExpanded] = useLocalStorage(
    'is_template_options_expanded',
    true
  )
  const [isTemplateModal, setIsTemplateModal] = useState<boolean>(false)
  const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false)
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false)
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false)

  // Create a new template for user which is suggested by compfeed
  const createCompfeedSuggestedTemplate = async () => {
    if (isCreatingTemplate || isLoadingTemplates) return

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team ID not found.')
      return
    }

    // Limit templates to 3
    if (templatesList?.length >= 3) {
      toast.info("You've reached the limit of 3 templates per team.")
      return
    }

    setIsCreatingTemplate(true)
    const currentTime = getTimeStamp()

    try {
      const newTemplateId = push(ref(db, `/templates/${teamId}`)).key
      const templateRef = ref(db, `/templates/${teamId}/${newTemplateId}`)

      const newTemplateObject = {
        id: newTemplateId,
        title: 'Example template',
        description: 'Example template by Compfeed',
        teamId: teamId,
        visible: true,
        colors: {
          1: {
            bg: "#A7F8BB",
            defaultColor: true,
            id: "1",
            text: "#19CE46",
            type: "primary"
          },
          2: {
            bg: "#FFE5C0",
            defaultColor: true,
            id: "2",
            text: "#FF9508",
            type: "primary"
          },
          3: {
            bg: "#CEECFF",
            defaultColor: true,
            id: "3",
            text: "#1FB8FF",
            type: "primary"
          },
          4: {
            bg: "#EFD9F8",
            defaultColor: true,
            id: "4",
            text: "#A054DF",
            type: "primary"
          },
          type: "primary"
        },
        order: templatesList.length,
        createdBy: user?.id,
        type: 'component',
        createdAt: currentTime,
        updatedAt: currentTime
      }

      await set(templateRef, newTemplateObject)

      // Iterate over the provided data to create phases and tasks
      const phaseRef = ref(db, `/phases/${newTemplateId}`)
      const newPhaseObjects: Record<string, PhaseObj> = {}

      phasesData.forEach((phaseData, phaseIndex) => {
        const newPhaseId = push(phaseRef).key || ''
        const taskObjects: any = {}

        phaseData.tasks.forEach((taskData, taskIndex) => {
          const newTaskId =
            push(ref(db, `/phases/${newPhaseId}/tasks`)).key || ''
          taskObjects[newTaskId] = {
            id: newTaskId,
            title: taskData.title || 'Untitled Task',
            description: taskData.description || '',
            createdBy: user?.id || '',
            phaseId: newPhaseId,
            templateId: newTemplateId || '',
            order: taskIndex,
            createdAt: currentTime,
            updatedAt: currentTime,
            mandatory: true,
            visible: true,
            archived: false
          }
        })

        newPhaseObjects[newPhaseId] = {
          id: newPhaseId,
          title: phaseData.title || 'Untitled Phase',
          description: phaseData.description || '',
          colorId: (phaseIndex + 1).toString(),
          order: phaseIndex,
          tasks: taskObjects,
          isActive: true,
          mandatory: true,
          teamId,
          templateId: newTemplateId || '',
          createdBy: user?.id || '',
          createdAt: currentTime,
          updatedAt: currentTime,
          visible: true,
          archived: false
        }
      })

      // Save all phases and their tasks
      await set(phaseRef, newPhaseObjects)

      // Update Redux state
      const updatedPhasesData = Object.values(newPhaseObjects).map((phase) => ({
        ...phase,
        tasks: Object.values(phase.tasks || {}) // Provide a default value for phase.tasks
      }))

      dispatch(
        setTemplatesData({ data: [...templatesList, newTemplateObject] })
      )

      dispatch(setPhasesData({ data: [...phases, ...updatedPhasesData] }))

      toast.success('Template created successfully.')
      setIsCreatingTemplate(false)
      navigate(`/dashboard/templates/${newTemplateId}`)
    } catch (error) {
      setIsCreatingTemplate(false)
      toast.error('Something went wrong.')
      console.error('Error creating template:', error)
    }
  }

  // close template modal
  const closeTemplateModal = () => {
    if (isCreatingTemplate) return
    setIsTemplateModal(false)
  }

  const updateScrollButtonsVisibility = () => {
    const container = document.getElementById('templates-container')
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0)
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      )
    }
  }

  useEffect(() => {
    const container = document.getElementById('templates-container')

    if (container) {
      updateScrollButtonsVisibility()

      // Add a scroll listener to update button visibility on scroll
      container.addEventListener('scroll', updateScrollButtonsVisibility)

      // Clean up the event listener
      return () => {
        container.removeEventListener('scroll', updateScrollButtonsVisibility)
      }
    }
  }, [])

  // list of options for templates creation
  const OPTIONS = [
    {
      title: 'AI Template Wizard',
      description: 'Create your bespoke process with AI',
      pending: true,
      icon: (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01758 1.48486C8.01758 4.48486 10.4551 6.92236 13.4551 6.92236V8.04736C10.4588 8.04736 8.01758 10.4849 8.01758 13.4849H6.89258C6.89258 10.4849 4.45508 8.04736 1.45508 8.04736V6.92236C4.45508 6.92236 6.89258 4.48486 6.89258 1.48486H8.01758ZM4.10258 7.48486C5.59508 8.14486 6.79508 9.34486 7.45508 10.8374C8.11508 9.34486 9.31508 8.14486 10.8076 7.48486C9.31508 6.82486 8.11508 5.62486 7.45508 4.13236C6.79508 5.62486 5.59508 6.82486 4.10258 7.48486ZM12.9376 10.4999H14.0626C14.0626 11.8424 15.1576 12.9374 16.5001 12.9374V14.0624C15.1576 14.0624 14.0626 15.1574 14.0626 16.4999H12.9376C12.9376 15.1574 11.8426 14.0624 10.5001 14.0624V12.9374C11.8426 12.9374 12.9376 11.8424 12.9376 10.4999ZM12.4126 13.4999C12.8514 13.7811 13.2226 14.1524 13.5001 14.5874C13.7814 14.1486 14.1526 13.7774 14.5876 13.4999C14.1489 13.2186 13.7776 12.8474 13.5001 12.4124C13.2189 12.8511 12.8476 13.2224 12.4126 13.4999Z"
            fill="#9747FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01758 1.48486C8.01758 4.48486 10.4551 6.92236 13.4551 6.92236V8.04736C10.4588 8.04736 8.01758 10.4849 8.01758 13.4849H6.89258C6.89258 10.4849 4.45508 8.04736 1.45508 8.04736V6.92236C4.45508 6.92236 6.89258 4.48486 6.89258 1.48486H8.01758ZM4.10258 7.48486C5.59508 8.14486 6.79508 9.34486 7.45508 10.8374C8.11508 9.34486 9.31508 8.14486 10.8076 7.48486C9.31508 6.82486 8.11508 5.62486 7.45508 4.13236C6.79508 5.62486 5.59508 6.82486 4.10258 7.48486ZM12.9376 10.4999H14.0626C14.0626 11.8424 15.1576 12.9374 16.5001 12.9374V14.0624C15.1576 14.0624 14.0626 15.1574 14.0626 16.4999H12.9376C12.9376 15.1574 11.8426 14.0624 10.5001 14.0624V12.9374C11.8426 12.9374 12.9376 11.8424 12.9376 10.4999ZM12.4126 13.4999C12.8514 13.7811 13.2226 14.1524 13.5001 14.5874C13.7814 14.1486 14.1526 13.7774 14.5876 13.4999C14.1489 13.2186 13.7776 12.8474 13.5001 12.4124C13.2189 12.8511 12.8476 13.2224 12.4126 13.4999Z"
            fill="black"
            fillOpacity="0.2"
          />
        </svg>
      ),
      onClick: () => {},
      btnText: 'Coming soon',
      bgColor: '#F5EDFF',
      textColor: '#9747FF'
    },
    {
      title: 'Example template',
      description: 'Get started using our ready-made example',
      pending: false,
      icon: (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.17 0.5H0V15.5H12V5.33L7.17 0.5ZM7.5 2.42L10.08 5H7.5V2.42ZM1.125 14.375V1.625H6.375V5.1875C6.375 5.705 6.795 6.125 7.3125 6.125H10.875V14.375H1.125ZM5.25 11V12.125H9.375V11H5.25ZM2.625 11H4.125V12.125H2.625V11ZM5.25 8.375V9.5H9.375V8.375H5.25ZM2.625 8.375H4.125V9.5H2.625V8.375Z"
            fill="#1F6BFF"
          />
        </svg>
      ),
      onClick: () => setIsTemplateModal(true),
      btnText: 'Use template',
      bgColor: '#E4E9FF',
      textColor: '#1F6BFF'
    },
    {
      title: 'Tips and tricks',
      description:
        'Explore detailed documentation to guide you through every step',
      pending: false,
      icon: (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.0625 1.625C1.54473 1.625 1.125 2.04473 1.125 2.5625V11.5999C1.40628 11.4561 1.72492 11.375 2.0625 11.375H10.875V1.625H2.0625ZM10.875 12.5H2.0625C1.54473 12.5 1.125 12.9197 1.125 13.4375C1.125 13.9553 1.54473 14.375 2.0625 14.375H10.875V12.5ZM0 13.4375V2.5625C0 1.42341 0.923413 0.5 2.0625 0.5H12V15.5H2.0625C0.923413 15.5 0 14.5766 0 13.4375Z"
            fill="#C68100"
          />
        </svg>
      ),
      onClick: () => {
        window.open('https://process.compfeed.com/', '_blank')
      },
      btnText: 'Learn more',
      bgColor: '#FFF0E4',
      textColor: '#C68100'
    },
    {
      title: 'Create from scratch',
      description: 'Create a new template from scratch',
      pending: false,
      icon: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.375 4.125V0.75H7.4375V4.125H6.375Z"
            fill="#2AB514"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.708252 6.75H3.89575V7.875H0.708252V6.75Z"
            fill="#2AB514"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.886 2.83529L9.40682 5.46029L8.65552 4.66479L11.1347 2.03979L11.886 2.83529Z"
            fill="#2AB514"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.67781 2.03979L5.15698 4.66479L4.40568 5.46029L1.92651 2.83529L2.67781 2.03979Z"
            fill="#2AB514"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.92651 11.7898L4.40568 9.16479L5.15698 9.96029L2.67781 12.5853L1.92651 11.7898Z"
            fill="#2AB514"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.68025 7.23935C5.37836 6.46769 6.10833 5.69479 6.83711 6.01444L15.7532 9.92509L12.7545 11.2093L15.4493 14.0626L13.2813 16.3581L10.5865 13.5048L9.37364 16.6799L5.68025 7.23935ZM6.82548 7.22704L9.3619 13.7103L10.2032 11.5079L13.2813 14.7671L13.9467 14.0626L10.8686 10.8034L12.9486 9.91266L6.82548 7.22704Z"
            fill="#2AB514"
          />
        </svg>
      ),
      onClick: () => {
        navigate('/dashboard/templates/create?type=component')
      },
      btnText: 'Create new',
      bgColor: '#E5F2E4',
      textColor: '#2AB514'
    }
  ]

  return (
    <Container>
      <Header>
        <Title>Need a Head Start?</Title>
        <Button
          onClick={() => setIsExpanded((prev) => !prev)}
          style={{ marginLeft: 'auto' }}
          variant="tertiary"
          iconOnly
          icon={isExpanded ? 'minus' : 'plus'}
          size="m"
        />
      </Header>
      {isExpanded && (
        <ExpandableContent>
          <TemplatesContainer id="templates-container">
            {OPTIONS?.map((opt, i) => (
              <OptionCard
                key={i}
                bgColor={opt.bgColor}
                onClick={(e) =>
                  e.currentTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                  })
                }
              >
                <div className="flex items-center" style={{ gap: '8px' }}>
                  {opt?.icon}
                  <OptionTitle>{opt.title}</OptionTitle>
                </div>
                <OptionDescription>{opt.description}</OptionDescription>
                <OptionButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    opt.onClick()
                  }}
                  disabled={opt.pending}
                  textColor={opt.textColor}
                  pending={opt?.pending}
                >
                  {opt.btnText}
                  <FeatherIcon icon="arrow-right" size={'18px'} />
                </OptionButton>
              </OptionCard>
            ))}
          </TemplatesContainer>
          {canScrollLeft && (
            <ScrollButton
              left
              onClick={() => {
                const container = document.getElementById('templates-container')
                if (container) {
                  container.scrollLeft -= 260
                  updateScrollButtonsVisibility()
                }
              }}
            >
              <FeatherIcon icon="chevron-left" size={'18px'} />
            </ScrollButton>
          )}
          {canScrollRight && (
            <ScrollButton
              onClick={() => {
                const container = document.getElementById('templates-container')
                if (container) {
                  container.scrollLeft += 260
                  updateScrollButtonsVisibility()
                }
              }}
            >
              <FeatherIcon icon="chevron-right" size={'18px'} />
            </ScrollButton>
          )}
        </ExpandableContent>
      )}
      <Modal
        showModal={isTemplateModal}
        setShowModal={setIsTemplateModal}
        title="Example template"
        signal="file-text"
        buttonVariant1="primary"
        buttonLabel1="PROCEED"
        buttonVariant2="tertiary"
        buttonLabel2="CANCEL"
        buttonOnClick1={() => createCompfeedSuggestedTemplate()}
        buttonOnClick2={closeTemplateModal}
        buttonDisabled1={
          isCreatingTemplate || isLoadingTemplates || templatesList?.length >= 3
        }
        onclose={closeTemplateModal}
        overflow="visible"
        slotContent={
          <>
            Are you sure you want to continue with Example template?
            {templatesList?.length >= 3 && (
              <>
                <br />
                <span
                  style={{
                    color: theme.colors.signalError
                  }}
                >
                  Note: You've reached the limit of 3 templates per team
                </span>
              </>
            )}
          </>
        }
      />
    </Container>
  )
}

export default TemplateOptionsSlider
