import styled from 'styled-components'
import { theme } from '../../../styles'
import Dropdown from '../DropDown/DropDown'
import NavItem from '../NavItem/NavItem'
import { useKanbanContext } from '../kanban/providers'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

interface IViewOptionsProps {
  isMobile?: boolean
}

const StyledViewOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const ViewOptions = ({ isMobile = false }: IViewOptionsProps) => {
  const { selectedView, setSelectedView } = useKanbanContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const view = searchParams.get('view') || 'grid'

  useEffect(() => {
    if (view) {
      switch (view) {
        case 'grid':
          setSelectedView({ id: '1', name: view })
          break
        case 'table':
          setSelectedView({ id: '2', name: view })
          break
        case 'kanban':
          setSelectedView({ id: '3', name: view })
          break
        default:
          setSelectedView({ id: '1', name: view })
          break
      }
    }
  }, [view, setSelectedView])

  if (isMobile) {
    return (
      <StyledViewOptionsContainer>
        <NavItem
          icon="grid"
          navItemTitle="Grid"
          isActive={selectedView.id === '1'}
          showCounter={false}
          onClick={() => setSelectedView({ id: '1', name: 'Grid' })}
        />
        {!isMobile && (
          <NavItem
            icon="table"
            navItemTitle="Table"
            isActive={selectedView.id === '2'}
            showCounter={false}
            onClick={() => setSelectedView({ id: '2', name: 'table' })}
          />
        )}
        <NavItem
          icon="kanban"
          navItemTitle="Kanban"
          isActive={selectedView.id === '3'}
          showCounter={false}
          onClick={() => setSelectedView({ id: '3', name: 'Table' })}
        />
      </StyledViewOptionsContainer>
    )
  }

  return (
    <Dropdown
      items={[
        {
          id: '1',
          name: 'Grid'
        },
        {
          id: '2',
          name: 'Table'
        },
        {
          id: '3',
          name: 'Kanban'
        }
      ]}
      placeholder="View options"
      value={selectedView.name}
      size="s"
      type="list"
      showAction={false}
      textColor={theme.colors.actionPrimary}
      onSelectedItemChange={(item) => {
        setSelectedView({ id: item.id, name: item.name.toLowerCase() })
        const searchParams = new URLSearchParams()
        searchParams.set('view', item.name.toLowerCase())
        setSearchParams(searchParams)
      }}
    />
  )
}

export default ViewOptions
